<template>

    <div
        v-if="show"
        class="popup-container"
        :class="[{ 'blocking': blocking }, customClass, $mq, mood ]"
        @click.self="cancel"
    >

        <div
            class="popup"
            v-bind:class="type"
        >
            <!-- bind class popup.clases AND TYPE -->
            <div
                class="pop-alex"
                :class="[{'error':showingError}, mood]"
                v-if="type != 'media' && type != 'library' && type != 'resource'"
            ></div>

            <!-- <div class="pop-header" v-if="type != 'media' && type != 'library'" >
              <p v-if="title" class="title-message">{{title}}</p>
          </div> -->

            <div class="pop-body">

                <!-- media -->
                <div
                    class="kind-of preview hide-scrollbar"
                    v-if="type == 'media'"
                >
                    <div v-if="image">
                        <img
                            v-if="typeof image === 'string'"
                            :src="image"
                            style="object-fit: contain; width: 100%; height: 100%;"
                        >
                        <img
                            v-else
                            v-for="(img, key) in image"
                            :src="img"
                            :key="key"
                            style="object-fit: contain; width: 100%; height: 100%;"
                        >
                    </div>
                    <p
                        v-else
                        class="empty"
                    ></p>
                </div>

                <!-- Select, -->
                <div
                    class="kind-of"
                    v-else-if="type == 'select'"
                >
                    <p
                        v-show="showingError"
                        class="message-error"
                        v-html="messageError"
                    ></p>

                    <ul
                        v-if="Object.keys(list).length > 0"
                        class="list select-list hide-scrollbar"
                    >
                        <li
                            v-for="item in list"
                            v-if="item.default"
                            class="item default"
                            :class="[{ selected : selectedEl == item.id }]"
                            :id="item.id"
                            @click="selectMe( item.id )"
                        >
                            <span class="name">{{item.name}}</span>
                        </li>
                        <li
                            v-for="item in list"
                            v-if="!item.default"
                            class="item"
                            :class="[{ selected : selectedEl == item.id }]"
                            :id="item.id"
                            @click="selectMe( item.id )"
                        >
                            <span class="name">{{item.name}}</span>
                        </li>
                    </ul>
                    <p
                        v-else
                        class="empty"
                        v-html="title"
                    ></p>
                </div>

                <!-- Select double -->
                <div
                    class="kind-of"
                    v-else-if="type == 'selectDouble'"
                >
                    <p
                        v-show="showingError"
                        class="message-error"
                        v-html="messageError"
                    ></p>

                    <div class="left hide-scrollbar">
                        <ul
                            v-if="Object.keys(list).length > 0"
                            class="list users-list hide-scrollbar"
                        >
                            <li
                                v-for="item in list"
                                class="item user"
                                :class="{ 'selected' : selectedEl == item.id }"
                                @click="selectMe( item.id )"
                            >
                                <span class="user-color"><img
                                        class="main-img"
                                        v-bind:src="item.avatar"
                                    />
                                    "></span>
                                <span class="user-name">{{item.name}} {{item.surname}}</span>
                            </li>
                        </ul>
                        <p
                            v-if="Object.keys(list).length == 0"
                            class="empty"
                            v-html="listEmpty"
                        ></p>
                    </div>

                    <div
                        class="right hide-scrollbar"
                        :class="{ disabled : selectedEl == undefined }"
                    >
                        <ul
                            v-if="Object.keys(listSec).length > 0"
                            class="list select-list hide-scrollbar"
                            :data-msg="disabledMessage"
                        >
                            <li
                                v-for="item in listSec"
                                v-if="item.default"
                                class="item default"
                                :class="[{ 'selected' : selectedSecEl == item.id }]"
                                :id="item.id"
                                @click="selectMeSec( item.id )"
                            >
                                <span class="name">{{item.name}}</span>
                            </li>
                            <li
                                v-for="item in listSec"
                                v-if="!item.default"
                                class="item"
                                :class="[{ 'selected' : selectedSecEl == item.id }]"
                                :id="item.id"
                                @click="selectMeSec( item.id )"
                            >
                                <span class="name">{{item.name}}</span>
                            </li>
                        </ul>
                        <p
                            v-if="Object.keys(listSec).length == 0"
                            class="empty"
                            v-html="listEmpty"
                        ></p>
                    </div>

                </div>

                <!-- numeric -->
                <Numpad
                    class="kind-of"
                    v-else-if="type == 'numeric'"
                    @sendValue="changeValue"
                    @saveAndClose="save"
                    :givenValue="inputVal"
                    :decimal='decimal'
                    :hasNegative='false'
                    :pinType="'numeric'"
                ></Numpad>

                <!-- temperature -->
                <PopupTemperature
                    class="hide-scrollbar"
                    v-else-if="type == 'temperature'"
                    @sendValue="changeValue"
                    @saveAndClose="save"
                    :givenValue="inputVal"
                    :stepNum='tempOptions.stepNum'
                    :numDec='tempOptions.numDec'
                    :stepDec='tempOptions.stepDec'
                    :mesure='tempOptions.mesure'
                    :hasNegative='true'
                ></PopupTemperature>

                <!-- Ticket -->
                <PopupTicket
                    class="kind-of"
                    v-else-if="type == 'ticket'"
                    @sendValue="changeValue"
                    @saveAndClose="save"
                    ref="popupTicket"
                ></PopupTicket>

                <!-- DateTime -->
                <PopupDateTime
                    class="kind-of"
                    v-else-if="type == 'dateTime'"
                    :date="date"
                    :time="time"
                    @sendValue="changeValue"
                    @saveAndClose="save"
                    ref="dateTime"
                >
                </PopupDateTime>

                <!-- Geolocation -->
                <PopupGeolocation
                    v-else-if="type == 'geolocation'"
                    class="geolocation"
                    ref="popupGeolocation"
                >
                </PopupGeolocation>

                <!-- Resource -->
                <PopupResource
                    v-else-if="type == 'resource'"
                    class="resource"
                    :resource="resource"
                    ref="popupResource"
                    @close="cancel('cross')"
                >
                </PopupResource>

                <!-- quantity -->
                <div
                    class="kind-of"
                    v-else-if="type == 'quantity'"
                >

                    <div class="left hide-scrollbar">
                        <p class="message"> {{message}}
                        <h6>{{productName}}</h6>
                        </p>

                        <!-- <button v-if="inputVal > 0" class="act rem-amount" @click="removeAll">
                      {{ getString('popup.quantity.remove_all') }}
                    </button> -->
                    </div>

                    <div class="right hide-scrollbar">
                        <Numpad
                            @sendValue="changeValue"
                            :decimal='decimal'
                            :givenValue="inputVal"
                            :max='100'
                        ></Numpad>
                    </div>

                </div>

                <!-- timeClock -->
                <div
                    class="kind-of"
                    v-else-if="type == 'timeclock'"
                >

                    <div class="left hide-scrollbar">
                        <div class="place">
                            <div
                                class="item"
                                :class="selectedTab == 'all' ? 'selected' : ''"
                                @click="selectTab('all')"
                                data-val="all"
                            >Todos</div>
                            <div
                                class="item"
                                :class="selectedTab == 'in' ? 'selected' : ''"
                                @click="selectTab('in')"
                                data-val="in"
                            >En Local</div>
                            <div
                                class="item"
                                :class="selectedTab == 'out' ? 'selected' : ''"
                                @click="selectTab('out')"
                                data-val="out"
                            >Fuera</div>
                        </div>
                        <p
                            v-show="showingError"
                            class="message-error"
                            v-html="messageError"
                        ></p>

                        <ul
                            v-if="Object.keys(list).length > 0"
                            class="list users-list hide-scrollbar"
                        >
                            <li
                                v-for="item in list"
                                class="item user"
                                :class="[item.clockClass ? item.clockClass : '', selectedEl == item.id ? 'selected' : '' ]"
                                v-bind:data-id="item.id"
                                v-if=" selectedTab == 'all' || (item.clockClass == 'clocked-in' && selectedTab == 'in' ) || (item.clockClass == 'clocked-out' && selectedTab == 'out' ) "
                                @click="selectMe( item.id )"
                            >
                                <span class="user-color"><img
                                        class="main-img"
                                        v-bind:src="item.avatar"
                                    /></span>
                                <span class="user-name">{{item.name}} {{list.surname}}</span>
                            </li>
                        </ul>
                        <p
                            v-if="Object.keys(list).length == 0"
                            class="empty"
                            v-html="listEmpty"
                        ></p>

                    </div>

                    <div
                        class="right hide-scrollbar"
                        :class="{ disabled : selectedEl == undefined }"
                    >
                        <Numpad
                            @sendValue="changeValue"
                            :pinType="'password'"
                            :givenValue="inputVal"
                            :disabled="disNumpad"
                        ></Numpad>
                    </div>

                </div>

                <!-- login, access -->
                <div
                    class="kind-of"
                    v-else-if="type == 'login' || type == 'access' "
                >

                    <template v-if="Object.keys(list).length > 0">
                        <div class="left hide-scrollbar">
                            <p
                                v-show="message"
                                class="message-info"
                                v-html="message"
                            ></p>
                            <p
                                v-show="showingError"
                                class="message-error"
                                v-html="messageError"
                            ></p>

                            <ul
                                v-if="Object.keys(list).length > 0"
                                class="list users-list hide-scrollbar"
                            >
                                <li
                                    v-for="item in list"
                                    class="item user"
                                    :class="{ selected : selectedEl == item.id }"
                                    v-bind:data-id="item.id"
                                    @click="selectMe( item.id )"
                                >
                                    <span class="user-color"><img
                                            class="main-img"
                                            v-bind:src="item.avatar"
                                        /></span>
                                    <span class="user-name">{{item.name}} {{item.surname}}</span>
                                </li>
                            </ul>
                            <p
                                v-if="Object.keys(list).length == 0"
                                class="empty"
                                v-html="listEmpty"
                            ></p>
                        </div>

                        <div
                            class="right hide-scrollbar"
                            :class="{ disabled : selectedEl == undefined }"
                        >
                            <Numpad
                                @sendValue="changeValue"
                                v-bind:pinType="'password'"
                                v-bind:givenValue="inputVal"
                                v-bind:disabled="disNumpad"
                            ></Numpad>
                        </div>
                    </template>

                    <template v-else>
                        <p class="message">{{ getString('tooltip.error_logout') }}</p>
                    </template>

                </div>

                <!-- Detalle de libreria -->
                <LibraryDetail
                    class="kind-of"
                    v-else-if="type == 'library'"
                    :file='file'
                ></LibraryDetail>

                <!-- Seleccionable fecha -->
                <div
                    class="kind-of"
                    v-else-if="type == 'date'"
                >
                    <!-- :allowed-dates="allowedDates" -->
                    <v-date-picker
                        v-model="inputVal"
                        :locale="aLocale"
                        landscape
                        reactive
                        :min="dateStart"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker>
                </div>

                <!-- Seleccionable fecha RANGO -->
                <div
                    class="kind-of hide-scrollbar"
                    v-else-if="type == 'dateRange'"
                >
                    <!-- :allowed-dates="allowedDates" -->
                    <v-date-picker
                        v-model="dateRange[0]"
                        :locale="aLocale"
                        reactive
                        :min="dateStart"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker>

                    <v-date-picker
                        v-model="dateRange[1]"
                        :locale="aLocale"
                        reactive
                        :min="dateRange[0]"
                        :max="dateEnd"
                        first-day-of-week="1"
                    ></v-date-picker>

                    <!-- <div class="fcalendar-container">
                  <functional-calendar
                    v-model="dateRange[0]"
                    @input="setRangeResult(dateRange[0])"
                    :is-multiple="true"
                    :is-date-range="true"
                    :calendars-count="2"
                    :isDatePicker="true"
                    :change-month-function="true"
                    :change-year-function="true"
                    :markedDates="[{date: dateEnd, class: 'expiration'},{date: dateRange[0], class: 'vfc-marked'}]"
                    :limits="{min: dateStart, max: ''}"
                  ></functional-calendar>
                </div> -->

                </div>

                <!-- Input -->
                <div
                    class="kind-of"
                    v-else-if="type == 'input'"
                >
                    <p
                        v-show="showingError"
                        class="message-error"
                        v-html="messageError"
                    ></p>
                    <input
                        type="text"
                        v-model="inputVal"
                        required
                    >
                </div>

                <!-- Signature -->
                <div
                    class="kind-of"
                    v-else-if="type == 'sign'"
                >
                    <div class="left hide-scrollbar">
                        <p
                            v-show="message"
                            class="message-info"
                            v-html="message"
                        ></p>
                        <p
                            v-show="showingError"
                            class="message-error"
                            v-html="messageError"
                        ></p>

                        <ul
                            v-if="Object.keys(list).length > 0"
                            class="list users-list hide-scrollbar"
                        >
                            <!-- TODO REVIEW EL IF -->
                            <li
                                v-for="item in list"
                                v-if="item.conf.sign"
                                class="item user"
                                :class="{ selected : selectedEl == item.id }"
                                v-bind:data-id="item.id"
                                @click="selectMe( item.id )"
                            >
                                <span class="user-color"><img
                                        class="main-img"
                                        v-bind:src="item.avatar"
                                    /></span>
                                <span class="user-name">{{item.name}} {{item.surname}}</span>
                            </li>
                        </ul>
                        <p
                            v-if="Object.keys(list).length == 0"
                            class="empty"
                            v-html="listEmpty"
                        ></p>
                    </div>
                    <div
                        class="right hide-scrollbar"
                        :class="{ disabled : selectedEl == undefined }"
                    >
                        <div class="toolbar">
                            <v-btn
                                flat
                                class="btn undo"
                                @click="signUndo"
                            >
                                <v-icon>undo</v-icon> {{ getString('popup.sign.text_undo') }}
                            </v-btn>
                            <v-btn
                                flat
                                class="btn clear"
                                @click="signClear"
                            >
                                <v-icon>clear</v-icon> {{ getString('popup.sign.text_clear') }}
                            </v-btn>
                        </div>
                        <vueSignature
                            class="signature"
                            ref="ref_sign"
                            :sigOption="signOptions"
                            :data-msg="disabledMessage"
                            :w="['portrait'].includes($mq) ? '300px' : '600px'"
                            :h="'300px'"
                        ></vueSignature>
                    </div>
                </div>

                <!-- Free Signature -->
                <div
                    class="kind-of"
                    v-else-if="type == 'freeSign'"
                >
                    <div class="toolbar">
                        <v-btn
                            flat
                            class="btn undo"
                            @click="signUndo"
                        >
                            <v-icon>undo</v-icon> {{ getString('popup.sign.text_undo') }}
                        </v-btn>
                        <v-btn
                            flat
                            class="btn clear"
                            @click="signClear"
                        >
                            <v-icon>clear</v-icon> {{ getString('popup.sign.text_clear') }}
                        </v-btn>
                    </div>
                    <vueSignature
                        class="signature"
                        ref="ref_sign"
                        :sigOption="signOptions"
                        :data-msg="disabledMessage"
                        :w="['portrait'].includes($mq) ? '300px' : '600px'"
                        :h="'300px'"
                    ></vueSignature>
                </div>

                <!-- Alerta de Incidencia (en tareas) -->
                <div
                    class="kind-of"
                    v-else-if="type == 'issueAlert'"
                >
                    <!-- <p class="alert-title">{{ getString('popup.issue_alert.alert_message') }}</p> -->
                    <div
                        class="message"
                        v-html="message"
                    ></div>
                    <div
                        class="suggest"
                        v-if="selectedEl && !selected"
                    >
                        <p class="issue-title">{{ getString('popup.issue_alert.suggested_issue') }}</p>
                        <select
                            class="issues"
                            v-model.lazy="selectedEl"
                            disabled
                        >
                            <option
                                v-for="(value, index) in list"
                                :value="value.id"
                                :class="{'selected': value.id == selectedEl}"
                            >{{value.name}}</option>
                        </select>
                    </div>
                    <div
                        class="suggest"
                        v-else-if="!selectedEl && !selected"
                    >
                        <p class="issue-title">{{ getString('popup.issue_alert.suggested_new') }}</p>
                    </div>
                </div>

                <!-- Proceso -->
                <div
                    class="kind-of"
                    v-else-if="type == 'process'"
                >
                    <div class="product-info">
                        <span class="product-title">{{productName}}</span>
                    </div>

                    <div class="resume hide-scrollbar">
                        <div
                            v-for="(pType, i) in processTypes"
                            @click="changeStep(i)"
                            class="resume-step"
                            :class="[data[i].result ? 'done': 'undone', {'current': currentStep == i}]"
                        >
                            <h6 class="title">{{data[i].title}}</h6>
                            <span class="subtitle">{{ data[i].result ? data[i].result : '' }}</span>
                        </div>
                    </div>

                    <div class="content">
                        <div
                            v-for="(pType, i) in processTypes"
                            class="step hide-scrollbar"
                            :class="[data[i].result ? 'done': 'undone', {'current': currentStep == i}]"
                        >

                            <label
                                v-if="(typeof data[i].message != 'undefined')"
                                class="step-message"
                                v-html="data[i].message"
                            ></label>

                            <div
                                v-if="pType == 'state'"
                                class="tags"
                                :class="'p-'+pType"
                            >
                                <span
                                    v-for="tag in data[i].tags"
                                    class="tag"
                                    :key="tag.id"
                                    :class="{'selected':data[i].result == tag.name}"
                                    @click="setProcessResult(pType, i, tag)"
                                >
                                    {{tag.name}}
                                </span>
                            </div>

                            <div
                                v-else-if="pType == 'date'"
                                class="fcalendar-container"
                                :class="'p-'+pType"
                            >
                                <functional-calendar
                                    v-model="data[i].input"
                                    @input="setProcessResult(pType, i, data[i].input)"
                                    :is-multiple="true"
                                    :calendars-count="2"
                                    :isDatePicker="true"
                                    :change-month-function="true"
                                    :change-year-function="true"
                                    :markedDates="[{date: data[i].maxDate, class: 'expiration'},{date: data[i].result, class: 'vfc-marked'}]"
                                    :limits="{min: data[i].minDate, max: ''}"
                                ></functional-calendar>
                            </div>

                            <div
                                v-else-if="pType == 'datetime'"
                                class="fcalendar-container"
                                :class="'p-'+pType"
                            >
                                <div class="calendar">
                                    <functional-calendar
                                        v-model="data[i].date"
                                        @input="setProcessResult(pType, i, data[i].date)"
                                        :is-multiple="true"
                                        :calendars-count="1"
                                        :isDatePicker="true"
                                        :change-month-function="true"
                                        :change-year-function="true"
                                        :markedDates="[{date: data[i].maxDate, class: 'expiration'},{date: data[i].result, class: 'vfc-marked'}]"
                                        :limits="{min: data[i].minDate, max: ''}"
                                    ></functional-calendar>
                                </div>
                                <div class="time">
                                    <span class="message">{{ getString('label.popup.process.pick_time') }}</span>
                                    <input
                                        type="time"
                                        v-model="data[i].time"
                                        @input="setProcessResult(pType, i, data[i].time)"
                                    />
                                </div>
                            </div>

                            <div
                                v-else-if="pType == 'string'"
                                class="input-text"
                                :class="'p-'+pType"
                            >
                                <input
                                    type="text"
                                    v-model="data[i].result"
                                    v-focus="currentStep == i"
                                    required
                                    @change="setProcessResult(pType, i, data[i].result)"
                                >
                            </div>

                            <div
                                v-else-if="pType == 'number'"
                                class="input-number"
                                :class="'p-'+pType"
                            >
                                <Numpad
                                    @sendValue="setProcessValue(pType, i, $event)"
                                    :givenValue="data[i].result"
                                    :max='data[i].max ? data[i].max : null'
                                ></Numpad>
                            </div>

                            <div
                                v-else-if="pType == 'quantity'"
                                class="input-number"
                                :class="'p-'+pType"
                            >
                                <Numpad
                                    @sendValue="setProcessValue(pType, i, $event)"
                                    :givenValue="data[i].result"
                                    :max='data[i].max ? data[i].max : null'
                                ></Numpad>
                            </div>

                        </div>
                    </div>

                </div>

                <!-- Postponer (en tareas) -->
                <div
                    class="kind-of"
                    v-else-if="type == 'postpone'"
                >

                    <div
                        v-if="!showingError"
                        class="postpone-info"
                    >

                        <p class="title-date">
                            {{ data.selectedDate == postponeTomorrow ? getString('answer.postpone.pretext_tomorrow') : getStringVariable('answer.postpone.pretext', momentFormat(data.selectedDate) ) }}
                        </p>

                        <div class="step">
                            <v-date-picker
                                class="datepicker"
                                v-model="data.selectedDate"
                                :locale="aLocale"
                                landscape
                                reactive
                                :min="dateStart"
                                :max="dateEnd"
                                first-day-of-week="1"
                            ></v-date-picker>
                        </div>

                        <div class="step">

                            <p class="title">
                                <span
                                    v-if="data.showErrorReason"
                                    class="error"
                                >{{data.errorReason}}</span>
                                {{ getString('answer.postpone.what_reason') }}
                            </p>
                            <ul class="reasons">
                                <li
                                    class="item"
                                    :class="{'selected':selected === item.id}"
                                    v-for="item in list"
                                    @click="selected = item.id; data.showReason=(item.id == '0' ? true: false)"
                                >{{item.name}}</li>
                            </ul>
                            <p
                                v-if="data.showReason"
                                class="title"
                            >{{ getString('answer.postpone.explain_reason') }}</p>
                            <textarea
                                v-if="data.showReason"
                                class="reason_message"
                                v-model="message"
                            ></textarea>
                        </div>

                    </div>
                    <div
                        v-else
                        class="postpone-error empty"
                    >{{messageError}}</div>

                </div>

                <!-- Justificar (en tareas) -->
                <div
                    class="kind-of"
                    v-else-if="type == 'justify'"
                >

                    <div
                        v-if="!showingError"
                        class="justify-info"
                    >

                        <div class="step">
                            <p class="title">
                                {{ getString('answer.justify.what_reason') }}
                            </p>
                            <ul class="reasons">
                                <li
                                    class="item"
                                    :class="{'selected':selected === item.id}"
                                    v-for="item in list"
                                    @click="selected = item.id"
                                >{{item.name}}</li>
                            </ul>
                        </div>

                        <div class="step">
                            <p class="title">{{ getString('answer.justify.explain_reason') }}</p>
                            <textarea
                                class="reason_message"
                                v-model="message"
                            ></textarea>
                        </div>

                        <span
                            v-if="data.showErrorReason"
                            class="error"
                        >{{data.errorReason}}</span>

                    </div>
                    <div
                        v-else
                        class="justify-error empty"
                    >{{messageError}}</div>

                </div>

                <!-- Release -->
                <div
                    class="kind-of"
                    v-else-if="type == 'release'"
                >
                    <PopupRelease :data="data"></PopupRelease>
                </div>

                <!-- LIST AUDITS -->
                <div
                    class="kind-of"
                    v-else-if="type == 'list_audits' "
                >

                    <template v-if="Object.keys(list).length > 0">
                        <div class="full hide-scrollbar">
                            <p
                                v-show="message"
                                class="message-info"
                                v-html="message"
                            ></p>
                            <p
                                v-show="showingError"
                                class="message-error"
                                v-html="messageError"
                            ></p>

                            <ul
                                v-if="Object.keys(list).length > 0"
                                class="list audit-list hide-scrollbar"
                            >
                                <li
                                    v-for="(item,key) in list"
                                    class="item audit"
                                    :class="{ selected : selectedEl == item.id, supervisor: typeof item.config.level === 'undefined' || item.config.level > level }"
                                    v-bind:data-id="item.id"
                                    @click="selectMe( item.id,key )"
                                >
                                    <span class="audit">{{item.name}}</span>
                                </li>
                            </ul>
                            <p
                                v-if="Object.keys(list).length == 0"
                                class="empty"
                                v-html="listEmpty"
                            ></p>
                        </div>

                    </template>

                    <template v-else>
                        <p class="message">{{ getString('tooltip.error_logout') }}</p>
                    </template>

                </div>

                <!-- basico, confirm, confirmUpload, alert, delete... -->
                <div
                    class="kind-of"
                    v-else
                >
                    <p
                        class="message"
                        v-html="message"
                    ></p>
                </div>

            </div>

            <div
                class="pop-footer"
                v-if="type != 'resource'"
            >
                <div
                    v-if="type =='process'"
                    class="buttons"
                >
                    <button
                        @click="changeStep(currentStep - 1)"
                        v-if="currentStep > 0"
                        class="act prev"
                        :class="classPrev"
                        v-html="textPrev"
                    ></button>
                    <button
                        @click="cancel('button')"
                        class="act cancel logout"
                        :class="classCancel"
                        v-html="textCancel"
                    ></button>
                    <button
                        @click="save"
                        v-if="currentStep == (data.length - 1)"
                        class="act save"
                        :class="classSave"
                        v-html="textSave"
                    ></button>
                    <button
                        @click="changeStep(currentStep + 1)"
                        v-if="currentStep < (data.length - 1)"
                        class="act next"
                        :class="classNext"
                        v-html="textNext"
                    ></button>
                </div>
                <div
                    v-else-if="type =='issueAlert'"
                    class="buttons"
                >
                    <button
                        @click="cancel('button')"
                        class="act cancel"
                        :class="classCancel"
                        v-html="textCancel"
                    ></button>
                    <button
                        @click="save"
                        class="act"
                        :class="classSave"
                        v-html="textSave"
                    ></button>
                    <button
                        @click="saveStart"
                        class="act save start"
                        :class="[classSaveStart, {'full': !selected && showSaveStart}]"
                        v-html="textSaveStart"
                        v-if="!selected && showSaveStart"
                    ></button>
                </div>
                <div
                    v-else-if="(type =='login' && Object.keys(list).length == 0) "
                    class="buttons"
                >
                    <button
                        @click="logoutStore"
                        class="act save"
                    >{{ getString('popup.login.text_logout') }}</button>
                </div>
                <div
                    v-else-if="type =='list_audits'"
                    class="buttons"
                >
                    <button
                        @click="cancel('button')"
                        class="act cancel"
                        :class="classCancel"
                        v-html="textCancel"
                        v-if="type != 'alert'"
                    ></button>
                    <button
                        @click="save"
                        class="act save"
                        :class="classSave"
                        v-html="(selectedEl != undefined && ( typeof list[selectedPos].config.level === 'undefined' || list[selectedPos].config.level > level )? textSaveSupervisor : textSave)"
                    ></button>
                </div>
                <div
                    v-else-if="type =='disconnect'"
                    class="buttons"
                >
                    <button
                        @click="cancel('button')"
                        class="act cancel"
                        :class="classCancel"
                        v-html="textCancel"
                        v-if="type != 'alert'"
                    ></button>
                    <button
                        @click="save"
                        class="act save"
                        :class="classSave"
                        v-html="textSave"
                        v-if="type != 'media' && type != 'library' && !(type == 'select' && cType == 4)"
                    ></button>

                </div>
                <div
                    v-else
                    class="buttons"
                >
                    <button
                        @click="cancel('button')"
                        class="act cancel"
                        :class="[classCancel, {'full': type == 'confirmUpload'}]"
                        v-html="textCancel"
                        v-if="type != 'alert'"
                    ></button>
                    <button
                        @click="saveStart"
                        class="act save start"
                        :class="classSaveStart"
                        v-html="textSaveStart"
                        v-if="type == 'select' || type == 'selectDouble' || type == 'issueAlert' || type == 'confirmUpload'
                "
                    ></button>
                    <button
                        @click="save"
                        class="act save"
                        :class="classSave"
                        v-html="textSave"
                        v-if="type != 'media' && type != 'library' && !(type == 'select' && cType == 4)"
                    ></button>
                    <button
                        @click="logout"
                        class="act logout"
                        :class="classLogout"
                        v-html="textLogout"
                        v-if="type == 'login'"
                    ></button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Vue from 'vue'
import Numpad from '@/components/inputs/Numpad'
import PopupTemperature from '@/plugins/popup/PopupTemperature'
import PopupTicket from '@/plugins/popup/PopupTicket'
import PopupDateTime from '@/plugins/popup/PopupDateTime'
import PopupRelease from '@/plugins/popup/PopupRelease'
import PopupGeolocation from '@/plugins/popup/PopupGeolocation'
import PopupResource from '@/plugins/popup/PopupResource'

import i18n from '@/i18n'
import store from '@/store'
import router from '@/router'
import LibraryDetail from '@/components/domain/library/detail'

import vueSignature from "vue-signature"
Vue.use(vueSignature)
export default {
    components: { PopupTemperature, PopupTicket, LibraryDetail, Numpad, PopupDateTime, PopupRelease, PopupGeolocation, PopupResource },
    data () {
        return {
            mood: 'normal',
            decimal: false,
            show: false,
            closable: true,
            blocking: true,
            type: '',
            title: '',
            message: '',
            messageError: '',
            showingError: false,
            list: {},
            level: 3,
            listSec: {},
            listEmpty: '',
            textLogout: '',
            textCancel: '',
            textSave: '',
            textSaveSupervisor: '',
            textSaveStart: '',
            textNext: '',
            textPrev: '',
            classLogout: '',
            classCancel: '',
            classSave: '',
            classSaveStart: '',
            classNext: '',
            classPrev: '',
            inputVal: '',
            productName: '',
            image: false,
            file: false,
            selected: false,
            selectedSec: false,
            selectedEl: undefined,
            selectedPos: undefined,
            selectedSecEl: undefined,
            selectedTab: '',
            selectedSecTab: '',
            disabledMessage: '',
            disNumpad: false,
            dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            dateStart: '',
            dateEnd: '',
            processData: {},
            processTypes: [],
            currentStep: 0,
            data: [],
            cType: false,
            customClass: '',
            date: '',
            time: '',
            resource: false,
            oldDate: '',
            oldTime: '',
            signOptions: {
                penColor: '',
                backgroundColor: '',
                format: '',
                watermark: ''
            },
            tempOptions: {
                maxRange: null,
                minRange: null,
                maxNum: 260,
                minNum: -40,
                stepNum: 1,
                numDec: 1,
                stepDec: 1,
                mesure: 'ºC'
            },
            routePath: false,
            showSaveStart: true,
            callLogout: function () { },
            callCancel: function () { },
            callSave: function () { },
            callSaveStart: function () { },
            callRemoveAll: function () { },
            callAfterAccess: function () { }
        }
    },
    computed: {
        aLocale () {
            var language = store.getters['getLanguage']

            parseLang(language)
            return language
            // return store.getters['getLocale']
        },
        postponeTomorrow () {
            return moment(this.dateStart).format('YYYY-MM-DD')
        }
    },
    methods: {
        redirectPath (firstResult) {
            var visible = []
            var tools = store.getters['getTools']
            var result = firstResult
            const config = store.getters['login/getConfig']

            if (config.tools) {
                visible = config.tools
            }
            if (visible.length == 1) {
                result = tools[visible[0]].href
            }

            return result
        },

        momentFormat (date) {
            return moment(date).format('dddd D')
        },

        selectMe (id, key) {
            this.selected = id
            this.changeValue('')
            if (this.selectedEl == id) {
                this.disNumpad = true
                this.selectedEl = undefined
            } else {
                this.disNumpad = false
                this.selectedEl = id
            }
            if (typeof key !== 'undefined') this.selectedPos = key
        },
        selectMeSec (id) {
            this.selectedSec = id
            this.changeValue('')
            if (this.selectedSecEl == id) {
                this.disNumpad = true
                this.selectedSecEl = undefined
            } else {
                this.disNumpad = false
                this.selectedSecEl = id
            }
        },
        selectTab (id) {
            this.selectedTab = id
            this.selected = undefined
            this.selectedEl = undefined
            this.disNumpad = true
            this.changeValue('')
            this.showingError = false
        },

        changeValue (newVal) {
            this.inputVal = newVal
        },

        getString (str) {
            return i18n.t(str)
        },
        getStringObject (str, obj) {
            return i18n.t(str, obj)
        },
        getStringPlural (str, pos) {
            return i18n.tc(str, pos)
        },
        getStringVariable (str, pos) {
            return i18n.t(str, { s: pos })
        },
        getStringPluralVariable (str, pos, text) {
            return i18n.tc(str, pos, { s: text })
        },

        // Para limitar fechas (ver vuetify)
        // allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,

        cancel (item) {

            // check if is closable or if is legal_conditions, click in cancel button
            if ((this.closable) || (item == 'button' && this.customClass == 'popup-small')) {
                item = item || false
                this.callCancel(this, store, item)
            } else if (item == 'cross') {
                console.log("hola")
                this.callCancel(this, store, item)
            }

        },
        save () {

            if (this.type == 'sign' || this.type == 'freeSign') {
                this.signSave()
            } else if (this.type == 'process') {
                var finish = []
                for (var k in this.data) {
                    var d = this.data[k]
                    if (d.optional || (d.result && d.result != null && d.result != '')) { finish.push('true') } else { finish.push('false') }
                }
                if (finish.indexOf('false') == -1) {
                    this.callSave(this, store)
                }
            } else if (this.type == 'ticket') {

                let formData = this.$refs['popupTicket'].validateForm();
                let self = this

                if (formData) {

                    // show loader
                    self.$overlay.loading({
                        blocking: true
                    })

                    // prepare data
                    let params = {
                        location_id: formData.location_id,
                        employee_id: '',
                        tool: formData.tool,
                        description: formData.description,
                        email: formData.email,
                        data: {
                            location: formData.location,
                            name: formData.employee_id,
                        }
                    }

                    // store add ticket
                    store.dispatch('ticket/addTicket', params)
                        .then(function (response) {
                            // close loader
                            self.$overlay.hide()

                            // return event result 
                            self.callSave(this, store)

                        })

                }

            } else if (this.type == 'dateTime') {

                const data = this.$refs['dateTime'].getData()
                this.date = data.date
                this.time = data.time
                this.oldDate = data.oldDate
                this.oldTime = data.oldTime
                this.callSave(this, store)

            } else {

                this.callSave(this, store)

            }
        },
        saveStart () {
            this.callSaveStart(this, store)
        },
        logout () {
            this.callLogout(this, store)
        },
        logoutStore () {
            router.push('/login')
            this.callCancel(this, store)
        },

        // Funciones propias de SIGN
        signSave () {
            if (!this.$refs.ref_sign.isEmpty()) {
                if (typeof this.list[this.selectedEl] !== 'undefined') {
                    this.signAddWaterMark()
                }

                if (this.signOptions.format == 'jpg') {
                    this.inputVal = this.$refs.ref_sign.save('image/jpeg')
                } else if (this.signOptions.format == 'svg') {
                    this.inputVal = this.$refs.ref_sign.save('image/svg+xml')
                } else if (this.signOptions.format == 'png') {
                    this.inputVal = this.$refs.ref_sign.save()
                } else {
                    // Guarda en PNG
                    this.inputVal = this.$refs.ref_sign.save()
                }
            } else {
                this.inputVal = ''
            }
            this.callSave(this, store)
        },
        signClear () {
            this.$refs.ref_sign.clear()
        },
        signUndo () {
            this.$refs.ref_sign.undo()
        },
        signAddWaterMark () {
            this.$refs.ref_sign.addWaterMark({
                text: i18n.t('popup.sign.watermark') + this.list[this.selectedEl].name,
                font: '16px Arial',
                x: 10,
                y: 180
                // -- All properties
                // text:"mark text",          // watermark text, > default ''
                // font:"20px Arial",         // mark font, > default '20px sans-serif'
                // style:'all',               // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
                // fillStyle:"#333333",           // fillcolor, > default '#333'
                // strokeStyle:"blue",	       // strokecolor, > default '#333'
                // x:100,                     // fill positionX, > default 20
                // y:200,                     // fill positionY, > default 20
                // sx:100,                    // stroke positionX, > default 40
                // sy:200,                     // stroke positionY, > default 40
            })
        },
        signFromDataURL (url) {
            this.$refs.ref_sign.fromDataURL('data:image/png;base64,iVBORw0K...')
        },

        // Funciones propias de process
        generateProcess () {
            var self = this

            var label = self.processData ? self.processData.label : false
            var selectedState =
                self.processData && Object.keys(self.processData.selectedState).length > 0
                    ? self.processData.selectedState
                    : { id: label.states[Object.keys(label.states)[0]].id, name: label.states[Object.keys(label.states)[0]].name }
            var format = store.getters['printer/getFormat'](label.states[selectedState.id].format)

            // console.log("format");
            // console.log(format);

            self.data = []
            self.processTypes = []
            self.currentStep = 0

            self.processTypes.push('state')
            self.data.push({
                tags: label.states,
                title: i18n.t('label.popup.process.state'),
                message: i18n.t('label.popup.process.state_msg'),
                result: selectedState ? selectedState.name : '',
                selected: selectedState ? selectedState.id : '',
                keyname: 'state'
            })

            if (format && format.fields) {
                for (var i in format.fields) {
                    var step = format.fields[i]
                    self.processTypes.push(step.type)
                    if (step.type == 'date') {
                        var maxDate = undefined

                        // if(self.data[0].selected != '' && self.processTypes[0] == 'state'){
                        //   maxDate = moment().add(label.states[self.data[0].selected].time, 'minutes').format('D/M/YYYY');
                        // }
                        self.data.push({
                            title: step.name,
                            result: '',
                            input: {},
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: moment().format('DD/MM/YYYY'),
                            maxDate: maxDate
                        })
                    } else if (step.type == 'datetime') {
                        var maxDate = undefined

                        self.data.push({
                            title: step.name,
                            result: '',
                            date: {},
                            time: '',
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional,
                            minDate: moment().format('DD/MM/YYYY'),
                            maxDate: maxDate
                        })
                    } else {
                        self.data.push({
                            title: step.name,
                            result: '',
                            keyname: step.keyname,
                            type: step.type,
                            optional: step.optional
                        })
                    }
                }
            }
            self.processTypes.push('quantity')
            self.data.push({
                title: i18n.t('label.popup.process.quantity'),
                result: '',
                max: 100,
                message: i18n.t('label.popup.process.quantity_msg'),
                keyname: 'quantity'
            })

            if (Object.keys(label.states).length <= 1) {
                self.currentStep++
            }
            // for (var o in self.data) {
            //   var dt = self.data[o];
            //
            //   if (dt.result != "" && o == 0) {
            //     self.currentStep++;
            //   } else if (dt.result != "" && self.data[o - 1].result != "") {
            //     self.currentStep++;
            //   }
            // }

            self.processData.selectedState = selectedState
        },

        setProcessResult (pType, i, result) {
            this.processBeforeChanges(pType, i, result)

            if (pType == 'state') {
                this.processData.selectedState.name = result.name
                this.processData.selectedState.id = result.id
                // this.data[i].result = result.name;
                // this.data[i].selected = result.id;

                this.generateProcess()
                // Calculo de maxDate en funcion de estado
                // var datePos = this.processTypes.indexOf('date');
                // this.data[datePos].maxDate = moment().add(this.data[i].tags[result.id].time, 'minutes').format('D/M/YYYY');
            } else if (pType == 'date') {
                this.data[i].result = result.selectedDate
            } else if (pType == 'datetime') {
                let date = Object.keys(this.data[i].date).length > 0 ? this.data[i].date.selectedDate : ''
                let time = this.data[i].time ? this.data[i].time : ''

                this.data[i].result = date + ' ' + time
            } else {
                this.data[i].result = result
            }

            this.processAfterChanges(pType, i, result)
        },
        setProcessValue (pType, i, result) {
            this.processBeforeChanges(pType, i, result)

            this.data[i].result = result

            this.processAfterChanges(pType, i, result)
        },

        processBeforeChanges (pType, i, result) {
            var hasResult = this.data[i].optional || (this.data[i].result && this.data[i].result != null && this.data[i].result != '')

            if (hasResult && this.data[i].result != result) {
                for (var k = i; k < this.data.length; k++) {
                    var datos = this.data[k]
                    datos.result = ''
                    if (this.processTypes[k] == 'date') {
                        // datos.input = {};
                    } else if (this.processTypes[k] == 'datetime') {
                        // datos.input = {};
                    }
                }
            }
        },
        processAfterChanges (pType, i, result) {
            if (pType != 'date' && pType != 'datetime' && pType != 'number' && pType != 'state') {
                if ((this.currentStep + 1) < this.data.length) {
                    this.currentStep++
                }
            }
        },

        changeStep (goTo) {
            let item = this.data[this.currentStep]
            let result = item.result
            let validResult = item.optional || (result && result != null && result != '')
            let isNext = (this.currentStep + 1) == goTo

            if (item.type == 'datetime') {
                validResult = item.optional || (
                    result && result != null && result != '' &&
                    item.date && Object.keys(item.date).length > 0 &&
                    item.time && item.time != null && item.time != ''
                )
            }

            if (this.currentStep != goTo && goTo >= 0 && (
                (this.currentStep < goTo && validResult && isNext) ||
                (this.currentStep > goTo)
            )) {
                this.currentStep = goTo
            }
        },

        // Funciones propias date range
        setRangeResult (result) {
            // this.inputVal = result.selectedDate;
        }

    },
    created () {
        // KEYBOARD ACTIONS HANDLER
        const escapeHandler = e => {
            if (e.key === 'Escape' && this.show) {
                this.cancel()
            }
        }
        const enterHandler = e => {
            if (e.key === 'Enter' && this.show) {
                this.save()
            }
        }

        document.addEventListener('keydown', escapeHandler)
        document.addEventListener('keydown', enterHandler)

        this.$once('hook:destroyed', () => {
            document.removeEventListener('keydown', escapeHandler)
            document.removeEventListener('keydown', enterHandler)
        })
    },
    mounted () {
        if (this.type == 'process') {
            this.generateProcess()
        }

        if (this.type == 'postpone' && Object.values(this.list).length == 1) {
            this.selected = '0'
            this.data.showReason = true
        }

        if (this.type == 'select' && this.list && Object.keys(this.list).length > 0) {
            this.selectMe(this.list[Object.keys(this.list)[0]].id)
        }
    },
    directives: {
        focus: {
            bind: function (el, binding) {
                el.focus()
            },
            componentUpdated: function (el, binding) {
                if (binding.value) {
                    el.focus()
                }
            }
        }
    }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.popup-container {
    @include display-flex();
    @include align-items();
    @include justify-content();
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $z-popups-unblocking;
    max-width: calc(100%);
    max-height: calc(100%);
    width: calc(100%);
    height: calc(100%);
    // top: $menu-top-height;
    // z-index: $z-popups-default;
    // max-width: calc(100% - #{$menu-tools-min});
    // max-height: calc(100% - #{$menu-top-height});
    // width: calc(100% - #{$menu-tools-min});
    // height: calc(100% - #{$menu-top-height});

    &.blocking {
        z-index: $z-overlay-blocking + 2;
    }
    &:not(.blocking) {
        // top: $menu-top-height;
        // max-height: calc(100% - #{$menu-top-height});
    }

    &.popup-small {
        .popup {
            max-width: 550px;

            .pop-body {
                min-width: 450px;
                padding: 10px;

                .title {
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .paragraph {
                    margin: 10px 0;
                }
            }
        }
    }
    &.popup-label {
        position: absolute;
        width: calc(100% - #{$menu-tools-min});
        max-width: calc(100%);
        height: calc(100% - #{$menu-top-height} - (#{$alex-app-padding} * 2));
        top: $menu-top-height + $alex-app-padding;
        left: $menu-tools-min - $alex-app-padding;
        border-radius: $default-border-radius;
        .pop-footer {
            display: none;
        }
    }
    &.popup-label-admin {
        position: absolute;
        width: calc(100% - #{$menu-tools-min});
        max-width: calc(100%);
        height: calc(100% - #{$menu-top-height} - (#{$alex-app-padding} * 2));
        top: $menu-top-height + $alex-app-padding;
        left: $menu-tools-min - $alex-app-padding;
        border-radius: $default-border-radius;
    }

    .popup {
        @include display-flex();
        @include align-items();
        @include justify-content();
        @include flex-direction($dir: column);
        @include border-radius(4px);
        position: relative;
        background-color: #fff;
        max-width: 600px;
        max-height: 90%;
        width: calc(100% - 20px);
        height: auto;
        bottom: -25px;
        padding: 15px;
        padding-bottom: 10px;

        .pop-alex {
            @include background($size: rem(60px), $image: img('alexhead_06.svg'), $color: #fff);
            @include border-radius(100%);
            border: solid 3px $main-t80;
            width: rem(80px);
            height: rem(80px);
            display: block;
            position: absolute;
            top: rem(-55px);
            left: rem(15px);
            z-index: $z-popups-default + 1;

            &.normal {
                background-image: img('alex_carita_07.svg');
            }
            &.geolocation {
                background-image: img('alex_carita_10.svg');
                width: 80px;
                height: 80px;
            }
        }

        .pop-close {
            @include background($size: 20px, $image: img('close_primary.svg'), $color: $main-t30);
            width: rem(40px);
            height: rem(40px);
            display: inline-block;
            float: right;
        }

        .pop-header,
        .pop-body,
        .pop-footer {
            @include display-flex();
            @include justify-content();
            width: 100%;
            height: auto;
            overflow: auto;
        }

        .pop-header {
            @include align-items(flex-start);
            min-height: rem(40px);
            padding-left: rem(70px);
            padding-right: rem(30px);
            margin-top: -10px;

            .title-message {
                font-family: $text;
                color: $main;
                padding: 0;
                background-color: $main-t90;
                border-color: $main-t90;
                padding: 8px 16px;
                border-radius: 3px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 0;
                    height: 0;
                    border: 6px solid transparent;
                    border-right-color: inherit;
                    border-left: 0;
                    // border-bottom: 0;
                    margin-top: -6px;
                    margin-left: -6px;
                }
            }
        }

        .pop-body {
            // @include flex-wrap(wrap);
            @include width-min-content();
            max-height: calc(100% - #{rem(80px)});
            height: auto;
            min-width: unset;
            // min-width: 100%;
            width: 100%;
            max-width: unset;
            overflow: auto;
            padding: 0px;
            padding-top: 23px;

            .kind-of {
                @include display-flex();
                @include flex-direction(row);
                @include justify-content(center);
                @include align-items(flex-start);
                max-height: calc(100% - 125px);
                height: max-content;
                min-width: 150px;
                // min-width: 100%;
                width: 100%;
                // overflow: auto;
            }

            .left,
            .right {
                @include display-inline-flex();
                @include flex-direction($dir: column);
                @include justify-content($val: flex-start);
                @include align-items();
                width: 50%;
                // height: auto;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 10px;
            }
            .full {
                @include display-inline-flex();
                @include flex-direction($dir: column);
                @include justify-content($val: flex-start);
                @include align-items();
                width: 100%;
                // height: auto;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 10px;
            }

            .disabled {
                cursor: not-allowed;
                position: relative;

                > div,
                > ul {
                    &:before,
                    &:after {
                        width: calc(100%);
                        height: calc(100%);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    &:before {
                        @include font-size(xl);
                        line-height: 125%;
                        font-family: $conden-bold;
                        color: $main-s30;
                        content: attr(data-msg);
                        text-align: center;
                        z-index: 2;
                        padding: 45% 24px;
                    }
                    &:after {
                        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 15%, rgba(255, 0, 0, 0) 50%), linear-gradient(to top, rgba(255, 255, 255, 1) 15%, rgba(255, 0, 0, 0) 50%), linear-gradient(to left, rgba(255, 255, 255, 1), rgba($main-t90, 0.8), rgba(255, 255, 255, 1));
                        content: '';
                    }
                }
            }

            .message {
                @include font-size(m);
                padding: 10px 14px;
                font-family: $text-light;
                background-color: $default-bg-color;
                position: relative;
                min-width: 100%;
                line-height: 25px;
                width: 100%;

                // &:after {
                //     content: '';
                //     display: block; /* reduce the damage in FF3.0 */
                //     position: absolute;
                //     top: -12px;
                //     left: 15px;
                //     width: 0;
                //     border-width: 15px 15px 0;
                //     border-style: solid;
                //     border-color: $default-bg-color transparent;
                //     transform: rotate(180deg);
                // }
            }

            .message-info {
                @include font-size(s);
                margin: 6px 0 8px 0;
                color: $main-s20;
                border-bottom: 1px solid $main-s10;
                text-align: left;
                width: 100%;
            }

            .message-error {
                @extend .message-info;
                color: $error;
                border-color: $error;
            }

            .place {
                width: 100%;
                border-bottom: 1px solid $main-t70;

                .item {
                    @include border-top-radius(3px);
                    font-family: $text;
                    display: inline;
                    float: left;
                    width: 33%;
                    padding: 10px 10px;
                    text-align: center;
                    color: $main-s50;
                }

                .item.selected {
                    background-color: $main-t90;
                }
            }

            .list {
                min-width: 150px;
                width: 100%;
                height: 100%;

                .item {
                    @include border-radius(3px);
                    @include display-flex();
                    @include align-items();
                    height: rem(40px);
                    width: 100%;
                    border-bottom: 1px solid #eee;

                    &.default {
                        // background-color: #fbfbfb;
                        border-bottom-color: $main-t30;
                    }
                    &.selected {
                        background-color: $main-t90;
                        border-bottom: 2px solid $main;

                        &.supervisor {
                            @include background($image: img('key_primary.svg'), $position: right 5px center, $size: 25px);
                        }
                    }
                    &.supervisor {
                        @include background($image: img('key_light.svg'), $position: right 5px center, $size: 25px);
                    }

                    .user-color {
                        @include dftUserColor();
                        @include text-ellipsis();
                        width: 35px;
                        height: 35px;
                        display: inline;
                        margin: 0 5px;

                        &:after {
                            padding: 12px 0;
                        }
                    }

                    .user-name {
                        @include text-ellipsis();
                        font-family: $text;
                    }
                }

                &.select-list {
                    .item {
                        padding-left: 12px;
                    }
                }
            }

            .toolbar {
                width: 100%;
                height: rem(40px);
                display: block;

                .btn {
                    @include font-size(s);
                    text-transform: none;
                    color: #777;
                    background-color: transparent;
                    margin: 0 6px;
                    padding: 0 6px;

                    .v-icon {
                        @include font-size(m);
                        margin-right: 3px;
                    }
                }
            }
        }

        .pop-footer {
            @include border-radius(0 0 3px 3px);
            min-height: 125px;
            background-color: #fff;

            .buttons {
                @include display-flex();
                @include align-items(flex-end);
                @include flex-direction(row);
                @include flex-wrap(wrap);
                @include justify-content(space-between);
                width: 100%;
                height: auto;
                text-align: right;
                margin-top: 20px;

                button {
                    @include border-radius(4px);
                    @include font-size(m);
                    border: 1px solid rgba(131, 131, 131, 0.03);
                    height: auto;
                    margin: 6px 0px;
                    padding: 0 20px;
                    color: $main;
                    font-family: $text-light;
                    background-color: $main-t90;
                    // float: right;
                    width: 49%;
                    min-height: 50px;
                    overflow: hidden;
                    cursor: pointer;

                    &.full {
                        width: 100%;
                    }

                    &:first-child {
                        // margin-right: 0;
                    }

                    &:only-child {
                        width: 100%;
                    }

                    &.save {
                        @include font-size(m);
                        color: #fff;
                        background-color: $main;
                        font-family: $text-bold;
                        border: none;
                    }
                    &.logout {
                        // @include default-box-shadow();
                        // font-weight: bold;
                        float: left;
                    }
                }
            }
        }

        &.quantity {
            .left {
                @include justify-content();
            }

            .message {
                margin-top: 20px;
            }

            .pin {
                @include font-size(xl);
            }

            .rem-amount {
                @include font-size(s);
                color: $error;
                margin-top: 40px;
                font-family: $text;
            }
        }

        &.media {
            @include justify-content($val: flex-end);
            max-width: 95%;
            max-height: 90%;

            .pop-body {
                height: auto;
                // height: 100%;
                min-width: unset;
                width: -webkit-fill-available;
                overflow-y: auto;
                text-align: center;
            }

            .preview {
                margin: 10px;
                width: inherit;
                overflow: auto;
                text-align: center;
            }
        }

        &.select {
            .pop-body {
                padding: 10px;
            }

            .list {
                .item {
                    padding: 0 8px;
                }
            }
        }

        &.select-double {
            .pop-body {
                padding: 10px;
            }

            .list {
                .item {
                    padding: 0 8px;
                }
            }
        }

        &.numeric {
            .pop-body {
                @include flex-wrap(wrap);
                min-width: auto;
                // position: relative;
                overflow: visible;

                .kind-of {
                    @include flex-direction(column);
                    @include justify-content(flex-start);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding-bottom: 10px;
                }
            }

            // .rem-amount {
            //     @include font-size(s);
            //     color: $error;
            //     font-family: $text;
            //     margin: 12px 0;
            //     width: 100%;
            // }
        }

        &.temperature {
            .pop-body {
                @include flex-wrap(wrap);
                min-width: auto;
                overflow: visible;

                .kind-of {
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    @include justify-content(center);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding: 0;
                }
            }
        }

        &.ticket {
            width: 90%;
            max-width: 1200px;

            .pop-body {
                @include flex-wrap(wrap);
                min-width: auto;
                overflow: auto;
                width: 100%;
                height: auto;

                .kind-of {
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    @include justify-content(center);
                    @include align-items();
                    min-width: 250px;
                    text-align: center;
                    padding: 0;
                }
            }
        }

        &.library {
            .pop-body {
                width: auto;

                .kind-of {
                    @include flex-direction(column);
                    @include align-items();
                    overflow: auto;

                    .container-preview {
                        height: 70%;
                    }
                }
            }
        }

        &.date,
        &.dateRange {
            .kind-of {
                padding: 0px;
            }
            .v-picker {
                box-shadow: none;

                .v-picker__title {
                    padding: 9px 16px;
                }

                .v-picker__title__btn {
                    color: #fff !important;
                    padding: 0;
                    margin: 0;

                    > * {
                        color: inherit;
                    }
                }
            }

            .primary {
                background-color: $main !important;
                border-color: $main !important;
            }
        }

        &.input {
            .pop-body {
                margin: 12px 0;
                padding: 0 12px;

                .kind-of {
                    @include flex-direction(column);

                    input {
                        width: 100%;
                    }

                    .message-error {
                        @include width-fit-content();
                    }
                }
            }
        }

        &.sign {
            .pop-body {
                // min-width: 700px;
                // width: auto;

                .right {
                    //   width: calc(60% + 50px);
                    padding: 0;

                    .toolbar {
                        @include display-flex();
                        @include justify-content(space-around);

                        button {
                            max-width: 50% !important;
                            width: fit-content;

                            .v-btn__content {
                                @include text-ellipsis();
                            }
                        }
                    }
                }
                .left {
                    //   width: calc(40% - 50px);
                    max-height: 50vh;

                    .users-list {
                        height: 100%;
                        overflow: auto;
                    }
                }

                .signature {
                    border-top: 2px dashed #ddd;
                    border-bottom: 2px dashed #ddd;
                    padding: 3px 0;
                }
            }
        }

        &.freeSign {
            .pop-body {
                min-width: 300px;

                .kind-of {
                    @include flex-direction(column);
                    width: auto;
                    // width: 300px;
                }
                .signature {
                    border-top: 2px dashed #ddd;
                    border-bottom: 2px dashed #ddd;
                    padding: 3px 0;
                }
            }
        }

        &.issueAlert {
            .pop-body {
                @include flex-wrap(wrap);

                .kind-of {
                    @include flex-direction(column);
                    @include justify-content(flex-start);
                    @include align-items();
                    padding: 12px;

                    .message {
                        width: 100%;
                        background-color: $main-t90;
                        max-height: 200px;
                        overflow: auto;
                    }
                    .suggest {
                        width: 100%;
                        text-align: center;
                        height: 40px;
                        margin-top: 12px;
                    }

                    .issue-title,
                    .issues,
                    .alert-title {
                        display: inline-block;
                    }
                    .alert-title {
                        @include font-size(ml);
                        color: $main-s60;
                        font-family: $conden;
                        width: 100%;
                    }
                    .issue-title {
                        @include font-size(ml);
                        color: $main-s60;
                        font-family: $conden;
                        margin-right: 12px;
                    }
                    .issues {
                        min-width: 100px;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }

        &.alert {
            .pop-body {
                padding: 20px 0;
            }

            .message {
                ol,
                ul {
                    padding-left: 30px;
                    list-style: disc;
                }
            }
        }

        &.process {
            height: 100%;
            min-width: 60%;

            .pop-header {
                margin-top: -15px;
            }

            .pop-body {
                height: 100%;
                width: 100%;

                .kind-of {
                    @include flex-wrap(wrap);
                    height: 100%;
                    width: 100%;
                    max-height: unset;
                }
            }

            .pop-footer {
                .buttons {
                    button {
                        margin: 4px 6px 0px 6px;

                        &.inactive {
                            // background-color: red;
                            opacity: 0.3;
                        }
                    }
                }
            }

            .product-info {
                width: 100%;
                height: 25px;
                position: relative;

                .product-title {
                    @include font-size(ml);
                    font-family: $text;
                    // color: shade($accent, 50%);
                    padding-left: calc(200px - 24px);
                    display: inline-block;
                    position: absolute;
                    width: 100%;
                    background-color: $accent-dark;
                }
            }
            .resume {
                width: 200px;
                height: calc(100% - 25px);
                overflow: auto;
                padding-right: 24px;

                .resume-step {
                    height: 25%;
                    padding: 0px 12px 24px 12px;
                    border-right: 2px solid #ccc;
                    &:first-of-type {
                        margin-top: 24px;
                    }
                    &:last-of-type {
                        padding: 0px 12px 0 12px;
                        border-color: transparent !important;
                        height: calc(25% - 24px);
                    }

                    .title {
                        @include font-size(m);
                        font-family: $text;
                        text-align: right;
                        color: #777;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        transition-duration: 0.1s;

                        &:after {
                            @include border-radius(50%);
                            @include background($color: #ccc);
                            content: '';
                            width: 18px;
                            height: 18px;
                            border: 2px solid #fff;
                            position: absolute;
                            top: 0;
                            right: -22px;
                            transition-duration: 0.1s;
                        }
                    }
                    .subtitle {
                        display: block;
                        width: 100%;
                        color: #777;
                        text-align: right;
                    }

                    &.current {
                        // border-color: $accent-dark;
                        .title {
                            @include font-size(ml);

                            &:after {
                                border: none;
                                background-color: $accent-dark;
                            }
                        }
                    }
                    &.done {
                        border-color: $main;
                        .title {
                            color: $main-text;
                            &:after {
                                background-color: $main;
                            }
                        }
                    }
                }
            }
            .content {
                width: calc(100% - 200px);
                height: calc(100% - 25px);
                overflow: hidden;
                padding: 24px 24px 24px 0px;

                .step {
                    display: none;
                    overflow: auto;
                    width: 100%;
                    height: 100%;
                    min-height: 300px;
                }
                .step.current {
                    display: block;
                }

                .step-message {
                    @include font-size(sm);
                    color: #666;
                    text-align: center;
                    margin-top: 0;
                }
            }

            .tags {
                @include display-flex();
                @include flex-direction(column);
                @include flex-wrap(wrap);
                height: 100%;
                width: 100%;
                padding-left: 24px;

                .tag {
                    @include border-radius(3px);
                    @include text-ellipsis();
                    @include width-fit-content();
                    @include font-size(ml);
                    text-align: center;
                    height: 50px;
                    margin: 12px 6px;
                    padding: 15px;
                    display: inline-block;
                    background-color: #eee;
                    cursor: pointer;
                    transition-duration: 0.2s;

                    &.selected {
                        background-color: $accent-t40;
                    }
                }
            }

            .fcalendar-container {
            }

            .fcalendar-container.p-datetime {
                .calendar,
                .time {
                    display: inline-block;
                    width: auto;
                }
                .calendar {
                    display: inline-block;
                    min-width: 300px;

                    .vfc-main-container {
                        max-width: 300px;
                    }
                }
                .time {
                    display: inline-block;
                    min-width: 150px;
                    text-align: center;

                    .message {
                        @include font-size(sm);
                        color: $main;
                        font-family: $text-bold;
                        text-align: center;
                        display: block;
                        width: 100%;
                    }

                    input {
                        @include border-radius($default-border-radius);
                        @include font-size(m);
                        color: $main;
                        font-family: $text-bold;
                        text-align: center;
                        height: 40px;
                        padding: 0 6px 0 12px;
                        background-color: $main-t80;
                        border: none;
                        display: inline-block;
                    }
                }
            }

            .input-number {
                @include display-flex();
                @include flex-direction(column);
                @include align-items();
            }

            .input-text {
                @include background($image: img('keyboard_aaaaaa.svg'), $size: 24px, $position: left 6px center);
                padding: 12px 42px;
                width: 80%;
                margin: 0 auto;

                input {
                    @include border-radius(3px);
                    @include font-size(m);
                    font-family: $text;
                    text-align: center;
                    border: 2px solid $accent-dark;
                    background-color: $accent-t90;
                    width: 100%;
                    height: 50px;
                }
            }
        }

        &.postpone {
            .kind-of {
                padding: 0;

                .postpone-info {
                    @include display-flex();
                    @include justify-content();
                    @include flex-wrap(wrap);
                    // width: calc(600px + (10px * 4));
                    width: 100%;

                    .title-date {
                        @include font-size(ml);
                        font-family: $text;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 12px;
                    }

                    .step {
                        width: 300px;
                        margin: 0 10px;
                        display: inline-block;
                    }

                    .title {
                        @include font-size($size: sm, $important: true);
                        margin: 12px 0 6px 0;
                    }
                    .error {
                        @include font-size($size: sm, $important: true);
                        font-family: $text;
                        color: $error;
                    }

                    .datepicker {
                        width: 200px;
                        border: none;
                        box-shadow: none;

                        .v-picker__title {
                            display: none;
                        }

                        .v-picker__body {
                            margin: 0;
                        }
                    }

                    .reasons {
                        @include display-flex();
                        @include flex-wrap(wrap);
                        @include flex-direction(column-reverse);

                        .item {
                            @include border-radius($default-border-radius);
                            font-family: $text;
                            min-height: 40px;
                            width: 100%;
                            background-color: #f3f3f3;
                            margin: 6px;
                            padding: 12px;

                            &.selected {
                                background-color: $accent-t50;
                            }
                        }
                    }

                    .reason_message {
                        width: 100%;
                    }
                }

                .postpone-error {
                    width: 100%;
                    color: #222;
                    text-align: center;
                }
            }
        }

        &.justify {
            .kind-of {
                padding: 6px 12px;

                .justify-info {
                    @include display-flex();
                    @include flex-wrap(wrap);
                    @include justify-content();
                    width: calc(600px + (10px * 4));

                    .step {
                        width: 300px;
                        margin: 0 10px;
                        display: inline-block;
                    }

                    .title {
                        @include font-size($size: sm, $important: true);
                        margin: 12px 0 6px 0;
                    }
                    .error {
                        @include font-size($size: sm, $important: true);
                        font-family: $text;
                        color: $error;
                        width: 100%;
                        text-align: center;
                    }

                    .reasons {
                        @include display-flex();
                        @include flex-wrap(wrap);
                        @include flex-direction(column-reverse);

                        .item {
                            @include border-radius($default-border-radius);
                            font-family: $text;
                            min-height: 40px;
                            width: 100%;
                            background-color: #f3f3f3;
                            margin: 6px;
                            padding: 12px;

                            &.selected {
                                background-color: $accent-t50;
                            }
                        }
                    }

                    .reason_message {
                        width: 100%;
                        min-height: 160px;
                    }
                }

                .justify-error {
                    width: 100%;
                    color: #222;
                    text-align: center;
                }
            }
        }

        &.dateTime {
            .pop-body {
                min-width: 300px;
            }
        }

        &.disconnect {
            .pop-header {
                .title-message {
                    background-color: $error-t80;
                    color: $inactive-s90;

                    &::after {
                        border-right-color: $error-t80;
                    }
                }
            }

            .pop-body {
                @include flex-direction(column);
                margin-top: 15px;
                margin-bottom: 9px;

                &::before {
                    @include background($image: img('andy_local_logout.svg'));
                    content: '';
                    display: block;
                    width: 100%;
                    min-height: 150px;
                    margin-bottom: 12px;
                }

                .message {
                    @include font-size(m);
                }
            }

            .pop-footer {
                .act {
                    background-color: $error-t80;
                    color: $inactive-s90;
                    font-family: $text-bold;

                    &.save {
                        background-color: $error;
                        font-family: $text;
                    }
                }
            }
        }

        &.delete {
            .pop-header {
                .title-message {
                    background-color: $error-t80;
                    color: $inactive-s90;

                    &::after {
                        border-right-color: $error-t80;
                    }
                }
            }

            .pop-body {
                @include flex-direction(column);
                margin-bottom: 9px;

                .message {
                    @include font-size(m);
                }
            }

            .pop-footer {
                .act {
                    background-color: $error-t80;
                    color: $inactive-s90;
                    font-family: $text-bold;

                    &.save {
                        background-color: $error;
                        font-family: $text;
                    }
                }
            }
        }

        &.geolocation {
            @include border-radius(8px);

            .pop-header {
                .title-message {
                    @include border-radius(8px);
                    @include align-self(center);
                    @include justify-content(center);
                    width: 400px;
                    margin-left: 25px;

                    &:after {
                        content: '';
                        @include border-radius(8px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 0;
                        height: 0;
                        border: 6px solid transparent;
                        border-right-color: inherit;
                        border-left: 0;
                        // border-bottom: 0;
                        margin-top: 0px;
                        margin-left: 0px;
                    }
                }
            }

            .pop-footer {
                .buttons {
                    height: 40px;
                    margin-right: 20px;
                    margin-bottom: 20px;

                    .act {
                        @include border-radius(8px);
                        height: 40px;
                    }
                }
            }
        }

        &.resource {
            @include border-radius(8px);
            width: fit-content;
            max-width: 80% !important;

            .pop-body {
                padding: 0px !important;
                margin: 0px !important;

                .header {
                    margin-bottom: 10px;
                }
            }

            .pop-footer {
                .buttons {
                    height: 40px;
                    margin-right: 20px;
                    margin-bottom: 20px;

                    .act {
                        @include border-radius(8px);
                        height: 40px;
                    }
                }
            }
        }
    }

    &.calm {
        .pop-alex {
            background-image: img('alex_carita_02_right.svg');
        }
    }
    &.smiling {
        .pop-alex {
            background-image: img('alex_carita_03_right.svg');
        }
    }
    &.happy {
        .pop-alex {
            background-image: img('alex_carita_12.svg');
        }
    }
    &.error {
        .pop-alex {
            background-image: img('alex_carita_04.svg');
            background-color: $error-t60;
            border: solid 3px $error;
        }

        .pop-body {
            .kind-of {
                .message {
                    background-color: $error-t60 !important;
                }
            }
        }
    }
    &.warning {
        .pop-alex {
            background-image: img('alex_carita_04.svg');
            background-color: $warning-t60;
            border: solid 3px $warning;
        }

        .pop-body {
            .kind-of {
                .message {
                    background-color: $warning-t60 !important;
                }
            }
        }
    }
    &.surprised,
    &.warning {
        .pop-alex {
            background-image: img('alex_carita_05.svg');
        }
    }
    &.talking,
    &.info {
        .pop-alex {
            background-image: img('alex_carita_10.svg');
        }
    }
    &.looking_up {
        .pop-alex {
            background-image: img('alex_carita_01_right.svg');
        }
    }
    &.looking_down {
        .pop-alex {
            background-image: img('alex_carita_08_right.svg');
        }
    }
}
</style>


// PORTRAIT STYLES
<style lang="scss">
.popup-container.portrait {
    @include align-items(flex-end);
    width: 100% !important;
    left: 0 !important;

    .popup {
        height: auto;
        max-width: unset;
        bottom: 10px;

        .pop-alex {
            @include background($size: rem(40px));
            width: rem(60px);
            height: rem(60px);
            top: rem(-35px);
        }

        .pop-header {
            @include font-size(s);
            position: absolute;
            top: -0.3rem;
            left: 0.1rem;
        }

        .pop-body {
            width: 100%;
            overflow: auto;
            min-width: unset;
            background: none;
            height: 100%;
            padding: 0;
            margin-top: 20px;
            padding-top: 12px;

            &::before {
                //@include background( $image: img('andy_local_logout.svg'), $size: 100px );
                background: none;
                width: 100% !important;
                overflow: hidden;
                //min-height: 150px;
                min-height: 0;
                min-width: 0;
            }

            .kind-of {
                height: auto;
                // overflow: auto;

                .message {
                    @include font-size(s);

                    &:after {
                        left: 25px;
                    }
                }
            }
        }

        .pop-footer {
            text-align: center;
            min-height: 165px;

            .act {
                @include font-size(s);
                max-width: calc(100%);
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .buttons {
                margin-top: 40px;

                button {
                    margin: 6px 0;
                    min-height: 50px;
                }

                .act {
                    &.save {
                        // background-color: $main-dark;
                    }
                }
            }
        }

        &.date {
            .v-picker.v-card.v-picker--date.v-picker--landscape {
                background-color: red;

                .v-picker__title.v-picker__title--landscape.primary {
                    position: static;
                    width: 100%;

                    .v-date-picker-title {
                        @include flex-direction(row);

                        * {
                            @include font-size(sm);
                        }

                        br {
                            display: none;
                        }
                    }
                }

                .v-picker__body {
                    position: static;
                    margin-left: 0;
                }
            }
        }

        &.dateRange {
            .kind-of {
                @include flex-direction(column);
                @include align-items();
                padding: 0px;
                height: fit-content;
            }
            .v-picker {
                box-shadow: none;

                .v-picker__title {
                    padding: 9px 16px;
                }

                .v-picker__title__btn {
                    color: #fff !important;
                    padding: 0;
                    margin: 0;

                    > * {
                        color: inherit;
                    }
                }
            }

            .primary {
                background-color: $main !important;
                border-color: $main !important;
            }
        }

        &.sign {
            .pop-body {
                .kind-of {
                    @include flex-direction(column-reverse);
                }
                .right,
                .left {
                    width: 100%;
                }
            }
        }

        &.resource {
            @include border-radius(0);
            bottom: 0 !important;
            min-width: 100%;
            height: fit-content;
            max-height: calc(100% - 60px);
            overflow: auto;

            .pop-body {
                width: 100%;
                height: 100%;
                min-height: 100%;
            }
        }
    }
}
</style>
