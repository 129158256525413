import { Api } from '@/core/api.js'
import store from '@/store'
import i18n from '@/i18n'
import router from '@/router'
import localforage from 'localforage'
import Vue from 'vue'

const api = new Api()

const db_issues = localforage.createInstance({ name: 'alexdb', storeName: 'issues' })
const db_issues_templates = localforage.createInstance({ name: 'alexdb', storeName: 'issues_templates' })
const db_tasks = localforage.createInstance({ name: 'alexdb', storeName: 'tasks' })
const db_templates = localforage.createInstance({ name: 'alexdb', storeName: 'templates' })
const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })
const db_updates = localforage.createInstance({ name: 'alexdb', storeName: 'updates' })
const db_employees = localforage.createInstance({ name: 'alexdb', storeName: 'employee' })
const db_roles = localforage.createInstance({ name: 'alexdb', storeName: 'roles' })
const db_categories = localforage.createInstance({ name: 'alexdb', storeName: 'product_categories' })
const db_products = localforage.createInstance({ name: 'alexdb', storeName: 'products' })
const db_collections = localforage.createInstance({ name: 'alexdb', storeName: 'product_collections' })
const db_printlogs = localforage.createInstance({ name: 'alexdb', storeName: 'product_printlogs' })
const db_error_logs = localforage.createInstance({ name: 'alexdb', storeName: 'product_print_error_logs' })
const db_library_categories = localforage.createInstance({ name: 'alexdb', storeName: 'library_categories' })
const db_sections = localforage.createInstance({ name: 'alexdb', storeName: 'library_sections' })
const db_files = localforage.createInstance({ name: 'alexdb', storeName: 'library_files' })
const db_highlight_files = localforage.createInstance({ name: 'alexdb', storeName: 'library_highlight_files' })
const db_logbook = localforage.createInstance({ name: 'alexdb', storeName: 'logbook' })
const db_legal_conditions = localforage.createInstance({ name: 'alexdb', storeName: 'legal_conditions' })
const dbEmployeeLoginLogs = localforage.createInstance({ name: 'alexdb', storeName: 'employee_login_logs' })
const db_printlogs_android = localforage.createInstance({ name: 'alexdb', storeName: 'product_printlogs_android' })
const db_languages = localforage.createInstance({ name: 'alexdb', storeName: 'languages' })
const db_locations = localforage.createInstance({ name: 'alexdb', storeName: 'locations' })

// initial state
const state = {
    location: {},
    account: -1,
    config: {},
    printer: {},
    hasLogin: false,
    conditionsVersion: -1,
    app_android: '',
    app_icon: '',
    token: '',
    roles: {},
    loadLocalForage: false,
    releases: {},
    allReleases: {},
    blackListData: {
        warning: false,
        blocked: false
    },
    trial_end_date: null,
    showTrialEndScreen: false,
    trial_days_left: 0,
    removeRolesLevel: false,
}

// getters
const getters = {
    getHasLogin: (state, getters, rootState) => {
        return state.hasLogin
    },
    getConfig: (state, getters, rootState) => {
        return state.config
    },
    getConditionsVersion: async (state, getters, rootState) => {
        return state.conditionsVersion != -1 ? state.conditionsVersion : await db_login.getItem('conditions_version')
    },
    getToken: (state, getters, rootState) => {
        return state.token
    },
    getAppAndroid: (state, getters, rootState) => {
        return state.app_android
    },
    getRoles: (state, getters, rootState) => {
        return state.roles
    },
    getLocation: (state, getters, rootState) => {
        return state.location
    },
    getAccount: (state, getters, rootState) => {
        return state.account
    },
    getPrinter: (state, getters, rootState) => {
        return state.printer
    },
    getConfig: (state, getters, rootState) => {
        return state.config
    },
    isLoad: (state, getters, rootState) => {
        return state.loadLocalForage
    },
    getCPUrl: (state, getters, rootState) => {
        return process.env.VUE_APP_CP_URL
    },
    getAPIurl: (state, getters, rootState) => {
        return process.env.VUE_APP_API_URL
    },
    getReleases: (state, getters, rootState) => id => {
        if (id) {
            return state.releases[id]
        } else {
            const items = state.releases
            const sortedItems = Object.values(items).sort((a, b) => {
                const date1 = moment(a.created_date * 1000)
                const date2 = moment(b.created_date * 1000)
                if (date1 > date2) return 1
                if (date1 < date2) return -1
                return 0
            })
            return sortedItems
        }
    },
    getAllReleases: (state, getters, rootState) => id => {
        if (id) {
            return state.allReleases[id]
        } else {
            const items = state.allReleases
            if (items) {
                const sortedItems = Object.values(items).sort((a, b) => {
                    const date1 = moment(a.created_date * 1000)
                    const date2 = moment(b.created_date * 1000)
                    if (date1 > date2) return 1
                    if (date1 < date2) return -1
                    return 0
                })
                return sortedItems
            }

            return []
        }
    },
    getBlackListData: (state, getters, rootState) => {
        return state.blackListData
    },
    getTrialEndDate: (state, getters, rootState) => {
        return state.trial_end_date
    },
    getShowTrialEndScreen: (state, getters, rootState) => {
        return state.showTrialEndScreen
    },
    getTrialDaysLeft: (state, getters, rootState) => {
        return state.trial_days_left
    },
    getRemoveRolesLevel: (state, getters, rootState) => {
        return state.removeRolesLevel
    }
}

// actions
const actions = {
    doLogin (context, params) {
        // // var overlay = this._vm.$overlay
        // overlay.loading()
        context.commit('logout')

        const appVersion = process.env.VUE_APP_VERSION
        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2

        return api
            .login('login', { username: params.username, password: params.password, version: appVersion, platform: platform })
            .then(function (response) {
                // store.dispatch( 'checkChangesPrint', response.data.printer );

                // clear offline database
                cleanLocalForage()

                if (response.status) {
                    db_locations.setItem(params.username, {
                        account_name: response.data.account_name,
                        account_logo: response.data.avatar,
                        location_name: response.data.name,
                        password: window.btoa(params.password)
                    })

                    if (typeof response.data.current_time !== 'undefined' && response.data.current_time) {
                        const currentTime = moment(moment(response.data.current_time).format('YYYY-MM-DD HH:mm:ss'))
                        const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
                        const difference = currentTime.diff(now, 'minutes')
                        context.commit('setServerTimeDifference', difference, { root: true })
                    }

                    context.commit('setTimeZoneName', response.data.timezone_name, { root: true })

                    context.commit('setVersionCurrent', response.data.version_current, { root: true })
                    context.commit('setVersionMin', response.data.version_min, { root: true })
                    context.commit('setVersionRecommended', response.data.version_recommended, { root: true })

                    //   store.dispatch("checkChangesPrint", response.data.printer);
                    context.dispatch('loadData', response.data)
                    context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
                    context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
                    context.dispatch('pusher/init', {}, { root: true })
                    var printer = Object.assign(response.data.printer)
                    log('SETTING PRINTER', printer)
                    // context.commit('printer/setPrinterData', printer, { root: true })
                    context.dispatch('printer/savePrinterData', printer, { root: true })

                    if (response.data.config && response.data.config.home) {
                        context.commit('setCustomHome', response.data.config.home, { root: true })
                    } else {
                        context.commit('setCustomHome', false, { root: true })
                    }

                    // free labelling
                    const freeLabelling = response.data.config && typeof response.data.config.freeLabelling !== 'undefined' && response.data.config.freeLabelling ? 1 : 0
                    context.commit('setFreeLabelling', freeLabelling, { root: true })

                    const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
                    context.commit('setRelatedLabels', relatedLabels, { root: true })

                    const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
                    context.commit('setDateLimitation', dateLimitation, { root: true })

                    context.commit(
                        'loginUser/setLocation',
                        {
                            account_name: response.data.account_name,
                            id: response.data.id,
                            name: response.data.name,
                            avatar: response.data.avatar,
                            identification: response.data.identification,
                            username: response.data.username,
                            timezone: response.data.timezone,
                            email: response.data.email,
                            token: response.data.token,
                            city: response.data.city,
                            state: response.data.state,
                            address: response.data.address,
                            cp: response.data.cp
                        },
                        { root: true }
                    )

                    context.commit('setReleases', response.data.releases)

                    context.commit('login', response)

                    context.dispatch('loadTimeZonesAndLanguages', {})

                    context.commit('setTrialEndDate', response.data.trial_end_date)
                    context.dispatch('calculateTrialDaysLeft', {})

                    context.commit('setisLoaded', true)

                    //   context.commit('employee/setRoles',response.data.roles,{root: true})
                    if (process.env.VUE_APP_IS_APP === 'TRUE') {
                        log('SETTING USER INFO Analytics & Crashlytics')
                        FirebasePlugin.setUserId(response.data.id)
                        FirebasePlugin.setUserProperty('account', response.data.account_id)
                        FirebasePlugin.setUserProperty('location_name', response.data.location_name)
                        FirebasePlugin.setCrashlyticsUserId(response.data.id)
                    }
                } else {
                    if (response.blackListWarning) {
                        context.commit('setBlacklistData', { warning: true, blocked: false })
                    }
                    if (response.blackListBlocked) {
                        context.commit('setBlacklistData', { warning: false, blocked: true })
                    }
                    return false
                }
            })
            .then(function () {
                if (context.state.hasLogin) {
                    return true
                } else {
                    return false
                }
            })
    },

    async doLoadLocalForage () {
        log('doLoadLocalForage')
        await initLocalForage()
    },

    async initLocation (context) {
        // this._vm.$overlay.loading()

        await initLocalForage()
        let last_action = context.rootGetters.getLastEmployeeAction
        let employee_id = store.getters['loginUser/getLocalEmployee']
        console.log('LAST ACTION EMPLOYEE DETECTED', last_action, moment().valueOf() - idle_time * 1000)
        if (last_action && last_action.time < moment().valueOf() - idle_time * 1000 && employee_id) {
            context
                .dispatch(
                    'loginUser/doLogout',
                    {
                        employee: employee_id
                    },
                    { root: true }
                )
                .then(function (response) {
                    // router.push(mixin.redirectPath('/home')).catch(err => {
                    //     if (err.name == 'NavigationDuplicated') {
                    //         router.go(router.history.current)
                    //     }
                    // })
                })
        }

        if (fnCheckConnection()) {
            return api
                .getSession({ mq: store.getters['getMediaQuery'] })
                .then(function (response) {
                    // log('LOGIN DATA')
                    // log(response)

                    if (response && (!response.status || response.status == 'false')) {
                        if (process.env.VUE_APP_URL_TYPE == 'HASH' && process.env.VUE_APP_IS_APP !== 'TRUE') {
                            window.location.href = process.env.VUE_APP_IS_APP === 'TRUE' ? cordova.file.applicationDirectory + 'www/index.html#/login' : window.window.location.origin + (process.env.VUE_APP_URL_TYPE == 'HASH' ? '/#' : '') + '/login'
                        } else {
                            router.push({ name: 'Login' })
                        }
                    }
                    if (response && response.data) {
                        if (typeof response.data.current_time !== 'undefined' && response.data.current_time) {
                            const currentTime = moment(moment(response.data.current_time).format('YYYY-MM-DD HH:mm'))
                            const now = moment(moment().format('YYYY-MM-DD HH:mm'))
                            const difference = currentTime.diff(now, 'minutes')
                            context.commit('setServerTimeDifference', difference, { root: true })
                        }

                        context.commit('setVersionCurrent', response.data.version_current, { root: true })
                        context.commit('setVersionMin', response.data.version_min, { root: true })
                        context.commit('setVersionRecommended', response.data.version_recommended, { root: true })
                        context.commit('setChatActive', response.data.chat, { root: true })

                        context.commit('setTimeZoneName', response.data.timezone_name, { root: true })

                        context.commit('setTrialEndDate', response.data.trial_end_date)
                        context.dispatch('calculateTrialDaysLeft', {})

                        context.commit('setisLoaded', true)
                        context.dispatch('loadData', response.data)
                        context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
                        context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
                        context.dispatch('pusher/init', {}, { root: true })
                        //   context.commit('employee/setRoles',response.data.roles,{root: true})
                        var printer = Object.assign({}, response.data.printer)
                        // context.commit('printer/setPrinterData', printer, { root: true })
                        context.dispatch('printer/savePrinterData', printer, { root: true }).then(() => {
                            log('FINISH SET PRINTER INFO')
                        })

                        if (response.data.config && response.data.config.home) {
                            context.commit('setCustomHome', response.data.config.home, { root: true })
                        } else {
                            context.commit('setCustomHome', false, { root: true })
                        }

                        // update free labelling
                        if (response.data.config && response.data.config.freeLabelling) {
                            context.commit('setFreeLabelling', response.data.config.freeLabelling, { root: true })
                        } else {
                            context.commit('setFreeLabelling', 0, { root: true })
                        }

                        const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
                        context.commit('setDateLimitation', dateLimitation, { root: true })

                        const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
                        context.commit('setRelatedLabels', relatedLabels, { root: true })

                        context.commit(
                            'loginUser/setLocation',
                            {
                                id: response.data.id,
                                name: response.data.name,
                                account_name: response.data.account_name,
                                avatar: response.data.avatar,
                                identification: response.data.identification,
                                username: response.data.username,
                                timezone: response.data.timezone,
                                email: response.data.email,
                                token: response.data.token,
                                city: response.data.city,
                                state: response.data.state,
                                address: response.data.address,
                                cp: response.data.cp
                            },
                            { root: true }
                        )
                        context.commit('conditionsVersion', response.data.conditions_version)

                        context.commit('setReleases', response.data.releases)

                        context.dispatch('loadTimeZonesAndLanguages', {})

                        return context.commit('login', response)
                    }

                    return context.commit('setLogin')
                })
                .catch(err => {
                    console.error('403')
                    return context.commit('setLogin')
                })
        } else {
            return context.commit('setLogin')
        }
    },

    loadData (context, data) {
        log('loadData', data)
        // Set reasons

        var lang = 'es'
        switch (data.lang) {
            case 'es_ES':
                lang = 'es'
                break
            case 'en_US':
                lang = 'en'
                break
            case 'pt_PT':
                lang = 'pt'
                break
            case 'de_DE':
                lang = 'de'
                break
        }
        store.commit('changeLanguage', lang)

        if (data.reason_postpone) {
            let rsPostpone = data.reason_postpone
            rsPostpone['0'] = {
                id: '0',
                name: i18n.t('answer.postpone.default_reason')
            }
            store.commit('setReasonPostpone', rsPostpone)
        }
        if (data.reason_justify) {
            let rsJustify = data.reason_justify
            rsJustify['0'] = {
                id: '0',
                name: i18n.t('answer.justify.default_reason')
            }
            store.commit('setReasonJustify', rsJustify)
        }

        // Set states of issues
        if (data.issue_states) {
            let stIssues = data.issue_states
            store.commit('setIssueStates', stIssues)
        }
    },

    async logout (context, data) {
        const account = await db_login.getItem('account')

        cleanLocalForage()

        if (fnCheckConnection()) {
            return api
                .login('logout', { location_id: account })
                .then(function (response) {
                    if (response.status) {
                        context.dispatch('loadData', response.data)
                        return context.commit('logout')
                    } else {
                        return false
                    }
                })
                .then(function () {
                    return true
                })
        } else {
            return context.commit('logout')
        }
    },

    async loadDashboardAccess (context, params) {
        const appVersion = process.env.VUE_APP_VERSION
        const platform = process.env.VUE_APP_IS_APP == 'TRUE' ? 1 : 2

        Vue.set(state, 'loadLocalForage', false)

        cleanLocalForage()

        return api
            .post('initdbpreview/' + params.location + '/' + params.id, { token: params.token, version: appVersion, platform: platform })
            .then(function (response) {
                if (response && !response.status) {
                    router.push('/login')
                } else if (response.status) {
                    if (typeof response.data.current_time !== 'undefined' && response.data.current_time) {
                        const currentTime = moment(moment(response.data.current_time).format('YYYY-MM-DD HH:mm:ss'))
                        const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
                        const difference = currentTime.diff(now, 'minutes')
                        context.commit('setServerTimeDifference', difference, { root: true })
                    }

                    context.commit('setTimeZoneName', response.data.timezone_name, { root: true })

                    context.commit('setVersionCurrent', response.data.version_current, { root: true })
                    context.commit('setVersionMin', response.data.version_min, { root: true })
                    context.commit('setVersionRecommended', response.data.version_recommended, { root: true })

                    context.dispatch('loadData', response.data)
                    context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
                    context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
                    //   context.commit('employee/setRoles',response.data.roles,{root: true})
                    var printer = Object.assign(response.data.printer)
                    // context.commit('printer/setPrinterData', printer, { root: true })
                    context.dispatch('printer/savePrinterData', printer, { root: true })

                    if (response.data.config && response.data.config.home) {
                        context.commit('setCustomHome', response.data.config.home, { root: true })
                    } else {
                        context.commit('setCustomHome', false, { root: true })
                    }

                    // free labelling
                    const freeLabelling = response.data.config && typeof response.data.config.freeLabelling !== 'undefined' && response.data.config.freeLabelling ? 1 : 0
                    context.commit('setFreeLabelling', freeLabelling, { root: true })

                    const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
                    context.commit('setRelatedLabels', relatedLabels, { root: true })

                    const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
                    context.commit('setDateLimitation', dateLimitation, { root: true })

                    context.commit(
                        'loginUser/setLocation',
                        {
                            id: response.data.id,
                            name: response.data.name,
                            account_name: response.data.account_name,
                            avatar: response.data.avatar,
                            identification: response.data.identification,
                            username: response.data.username,
                            timezone: response.data.timezone,
                            email: response.data.email,
                            token: response.data.token,
                            city: response.data.city,
                            state: response.data.state,
                            address: response.data.address,
                            cp: response.data.cp
                        },
                        { root: true }
                    )
                    context.commit('conditionsVersion', response.data.conditions_version)
                    context.commit('login', response)

                    var loginUser = {}
                    loginUser.status = true
                    loginUser.emp_id = response.data.employee.id
                    loginUser.token = response.data.token
                    loginUser.user = response.data.employee

                    loginUser.user.avatar = loginUser.user.avatar ? css_image_url + 'avatar/' + loginUser.user.avatar : ''
                    loginUser.user.image = 'url(' + loginUser.user.avatar + ')'

                    context.commit('setToken', response.data.token)
                    context.dispatch('loadTimeZonesAndLanguages', {})

                    context.commit('setTrialEndDate', response.data.trial_end_date)
                    context.dispatch('calculateTrialDaysLeft', {})

                    debug = 1
                    localStorage.setItem('debug', 1)

                    return context.dispatch('employee/loadEmployees', {}, { root: true }).then(function () {
                        return context.dispatch('employee/loadRoles', {}, { root: true }).then(function () {
                            context.commit('employee/addEmployee', loginUser.user, { root: true })
                            context.commit('loginUser/login', loginUser, { root: true })
                            return context.commit('setisLoaded', true)
                        })
                    })
                }
                return true
            })
            .catch(error => {
                logError(error)
            })
    },
    getStoredSites (context, params) {
        let storedLocations = []
        return db_locations.iterate((value, key, iterationNumber) => {
            value.username = key
            storedLocations.push(value)
        }).then(() => {
            return storedLocations
        })
    },
    removeStoredSite (context, params) {
        return db_locations.removeItem(params.username).then(() => {
            return true
        })
    },

    // RECOVER PASSWORD PROCCESS
    sendForgotMail (context, params) {
        return api.post('location/password/recover', { identification: params.identification }).then(function (response) {
            if (response) {
                return response
            }
        })
    },
    sendCode (context, params) {
        return api.post('location/password/recover/validate/code', { location_id: params.location_id, code: params.code }).then(function (response) {
            if (response) {
                return response
            }
        })
    },
    resendCode (context, params) {
        return api.post('location/code/resend', { identification: params.identification }).then(function (response) {
            if (response) {
                return response
            }
        })
    },
    resetPassword (context, params) {
        return api.post('location/password/new', { location_id: params.location_id, password: params.password, hashPassword: params.hashPassword }).then(function (response) {
            if (response.status) {
                //   store.dispatch("checkChangesPrint", response.data.printer);
                context.dispatch('loadData', response.data)
                context.dispatch('loadAppIcon', { path: response.data.app_icon, lastUpdate: response.data.app_icon_last_update * 1000 }, { root: true })
                context.dispatch('loadExternalTools', response.data.menu_items, { root: true })
                log('PASO POR AQUI')
                var printer = Object.assign(response.data.printer)
                log('SETTING PRINTER', printer)
                // context.commit('printer/setPrinterData', printer, { root: true })
                context.dispatch('printer/savePrinterData', printer, { root: true })

                if (response.data.config && response.data.config.home) {
                    context.commit('setCustomHome', response.data.config.home, { root: true })
                } else {
                    context.commit('setCustomHome', false, { root: true })
                }

                // free labelling
                const freeLabelling = response.data.config && typeof response.data.config.freeLabelling !== 'undefined' && response.data.config.freeLabelling ? 1 : 0
                context.commit('setFreeLabelling', freeLabelling, { root: true })

                const relatedLabels = response.data.config && typeof response.data.config.relatedLabels !== 'undefined' && response.data.config.relatedLabels ? response.data.config.relatedLabels : 0
                context.commit('setRelatedLabels', relatedLabels, { root: true })

                const dateLimitation = response.data.config && typeof response.data.config.dateLimitation !== 'undefined' && response.data.config.dateLimitation ? response.data.config.dateLimitation : 0
                context.commit('setDateLimitation', dateLimitation, { root: true })

                context.commit(
                    'loginUser/setLocation',
                    {
                        account_name: response.data.account_name,
                        id: response.data.id,
                        name: response.data.name,
                        avatar: response.data.avatar,
                        identification: response.data.identification,
                        username: response.data.username,
                        timezone: response.data.timezone,
                        email: response.data.email,
                        token: response.data.token,
                        city: response.data.city,
                        state: response.data.state,
                        address: response.data.address,
                        cp: response.data.cp
                    },
                    { root: true }
                )
                context.commit('login', response)

                //   context.commit('employee/setRoles',response.data.roles,{root: true})
                if (process.env.VUE_APP_IS_APP === 'TRUE') {
                    log('SETTING USER INFO Analytics & Crashlytics')
                    FirebasePlugin.setUserId(response.data.id)
                    FirebasePlugin.setUserProperty('account', response.data.account_id)
                    FirebasePlugin.setUserProperty('location_name', response.data.location_name)
                    FirebasePlugin.setCrashlyticsUserId(response.data.id)
                }
            } else {
                //   overlay.hide()
                return false
            }
        })
    },

    loadReleases (context, params) {
        const language = localStorage.getItem('language')
        let lang = ''
        switch (language) {
            case 'es':
                lang = 'es_ES'
                break
            case 'en':
                lang = 'en_US'
                break
            case 'pt':
                lang = 'pt_PT'
                break
            case 'de':
                lang = 'de_DE'
                break
        }
        return api.post('releases', { lang: lang }).then(response => {
            context.commit('setAllReleases', response.data)
            return response
        })
    },

    async loadTimeZonesAndLanguages (context, params) {
        const languages = await db_languages.length()

        if (languages > 0) {
            return false
        }

        const response = await api.get('account/information')

        if (!response.status) {
            return false
        }

        for (let id in response.data.languages) {
            const language = response.data.languages[id]
            db_languages.setItem(id, language)
        }
    },

    cleanOfflineDatabase () {
        cleanLocalForage()
    },

    calculateTrialDaysLeft (context) {
        let trial_end_date = context.getters['getTrialEndDate']
        const serverTimeDifference = context.rootGetters['loginUser/getServerTimeDifference']
        const timezone = context.rootGetters['getTimeZoneName']
        let now = moment()

        if (trial_end_date === true) {
            context.commit('showTrialEndScreen', true)
        }

        if (typeof trial_end_date === 'string' && trial_end_date !== null) {
            try {
                const options = {
                    timeZone: timezone,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }
                const copy = moment(now)

                const dateTimeFormat = new Intl.DateTimeFormat([], options).formatToParts()
                const objectDate = {}
                for (const item of dateTimeFormat) {
                    objectDate[item.type] = item.value
                }
                const dateString = `${objectDate['day'].padStart(2, '0')}/${objectDate['month'].padStart(2, '0')}/${objectDate['year']} ${objectDate['hour'].padStart(2, '0')}:${objectDate['minute'].padStart(2, '0')}`
                now = moment(dateString, 'DD/MM/YYYY HH:mm')

                if (now.diff(copy, 'minutes') != serverTimeDifference) {
                    const difference = serverTimeDifference - now.diff(copy, 'minutes')
                    now = now.add(difference, 'minutes')
                }

                now = moment(now, 'DD/MM/YYYY')
            } catch (error) {
                logError(error)
            }

            trial_end_date = moment(trial_end_date)
            let differenceDays = trial_end_date.diff(now, 'days') + 1
            let trial_days_left = now.isAfter(trial_end_date) ? 0 : differenceDays

            context.commit('setTrialDaysLeft', trial_days_left)

            if (trial_end_date.isSameOrBefore(now)) {
                context.commit('showTrialEndScreen', true)
                router.push('home')
            }
        }
    }
}

// mutations
const mutations = {
    login (state, response) {
        if (response.status) {
            state.hasLogin = true

            var dt = response.data

            const location = { id: dt.id, name: dt.name, avatar: dt.avatar, identification: dt.identification, username: dt.username, timezone: dt.timezone, email: dt.email, token: dt.token }

            state.location = location
            state.removeRolesLevel = dt.removeRolesLevel
            state.account = dt.account_id
            state.config = dt.config
            state.printer = dt.printer
            state.token = dt.token
            state.config = dt.config
            state.app_android = dt.app_android
            state.conditions_version = dt.conditions_version
            state.roles = dt.roles

            db_login.setItem('token', dt.token)
            db_login.setItem('location', location)
            db_login.setItem('removeRolesLevel', dt.removeRolesLevel)
            db_login.setItem('account', dt.account_id)
            db_login.setItem('config', dt.config)
            // db_login.setItem('printer', dt.printer)
            db_login.setItem('app_android', dt.app_android)
            db_login.setItem('app_icon', dt.app_icon)
            db_login.setItem('alex_info_url', dt.alex_info_url)
            db_login.setItem('roles', dt.roles)
            db_login.setItem('conditions_version', dt.conditions_version)
        } else {
            state.hasLogin = false
        }

        // this._vm.$overlay.hide()
    },
    setLogin (state) {
        // const token = await db_login.getItem('token')
        // if (typeof token !== 'undefined') {
        //     state.hasLogin = true
        // }
        log('setLogin', state.token)
        if (state.token != null && state.token != '') {
            state.hasLogin = true
        }
    },

    logout (state) {
        log('DO LOGOUT')
        var language = localStorage.language
        localStorage.clear()
        db_login.clear()
        localStorage.setItem('language', language)

        // reset LOCAL
        state.hasLogin = false

        // this._vm.$overlay.hide()
    },
    conditionsVersion (state, version) {
        state.conditionsVersion = version
        db_login.setItem('conditions_version', version)
    },

    setisLoaded (state, loaded) {
        state.loadLocalForage = loaded
    },

    setReleases (state, data) {
        state.releases = data
        Vue.set(state, 'releases', data)
    },

    setAllReleases (state, data) {
        state.allReleases = data
        Vue.set(state, 'allReleases', data)
    },

    setBlacklistData (state, data) {
        const blackListData = {
            warning: data.warning,
            blocked: data.blocked
        }
        state.blackListData = blackListData
        Vue.set(state, 'blacklistData', blackListData)
    },
    setToken (state, data) {
        state.token = data
        Vue.set(state, 'token', data)
    },
    setTrialEndDate (state, data) {
        state.trial_end_date = data
        db_login.setItem('trial_end_date', data)
        Vue.set(state, 'trial_end_date', data)
    },
    showTrialEndScreen (state, data) {
        state.showTrialEndScreen = data
        Vue.set(state, 'showTrialEndScreen', data)
    },
    setTrialDaysLeft (state, data) {
        state.trial_days_left = data
        Vue.set(state, 'trial_days_left', data)
    }
}

async function initLocalForage () {
    log('init local forage')

    await localStorageToLocalForage()

    state.location = await db_login.getItem('location')
    state.removeRolesLevel = await db_login.getItem('removeRolesLevel')
    state.conditionsVersion = await db_login.getItem('conditions_version')
    state.token = await db_login.getItem('token')
    state.roles = await db_login.getItem('roles')
    state.app_android = await db_login.getItem('app_android')
    state.account = await db_login.getItem('account')
    state.printer = await db_login.getItem('printer')
    state.config = await db_login.getItem('config')
    state.loadLocalForage = true
    state.trial_end_date = await db_login.getItem('trial_end_date')
}

async function localStorageToLocalForage () {
    const token = localStorage.token
    if (typeof token !== 'undefined') {
        await db_login.setItem('token', token)
        localStorage.clear()
    }
}

function cleanLocalForage () {
    db_issues.clear()
    db_issues_templates.clear()
    db_tasks.clear()
    db_templates.clear()
    db_login.clear()
    db_updates.clear()
    db_employees.clear()
    db_roles.clear()
    db_categories.clear()
    db_products.clear()
    db_collections.clear()
    db_printlogs.clear()
    db_error_logs.clear()
    db_library_categories.clear()
    db_sections.clear()
    db_files.clear()
    db_highlight_files.clear()
    db_logbook.clear()
    db_legal_conditions.clear()
    dbEmployeeLoginLogs.clear()
    db_printlogs_android.clear()
    db_languages.clear()
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
