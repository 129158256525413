<template>
    <div class="temperature-container" :class="$mq">
        <!-- <div v-if="hasNegative" v-on:click="keyPolarity(false)" class="key positive" :class="classPositive"></div>
        <div v-if="hasNegative" v-on:click="keyPolarity(true)" class="key negative" :class="classNegative"></div> -->

        <!-- <input class="pin" disabled v-model="value" ref="refInputVal" /> -->

        <div class="number-box">
            <div v-if="hasNegative" v-on:click="keyPolarity()" class="key negative">{{ isNegative ? '-' : '+' }}</div>
            <input class="pin" type="text" disabled :value="value ? value + ' ' + mesure : ''" ref="refInputVal" />
            <!-- <div class="units">{{ mesure }}</div> -->
        </div>
        <div class="num-pad">
            <div v-on:click="keyPress(7)" class="key numberkey">7</div>
            <div v-on:click="keyPress(8)" class="key numberkey">8</div>
            <div v-on:click="keyPress(9)" class="key numberkey">9</div>
            <div v-on:click="keyPress(4)" class="key numberkey">4</div>
            <div v-on:click="keyPress(5)" class="key numberkey">5</div>
            <div v-on:click="keyPress(6)" class="key numberkey">6</div>
            <div v-on:click="keyPress(1)" class="key numberkey">1</div>
            <div v-on:click="keyPress(2)" class="key numberkey">2</div>
            <div v-on:click="keyPress(3)" class="key numberkey">3</div>
            <div v-on:click="keyBack" class="key keyback"></div>
            <div v-on:click="keyPress(0)" class="key numberkey">0</div>
            <div v-on:click="keyPressDecimal('.')" class="key decimal">.</div>
            <!-- <div v-on:click="sendError" class="key disabled"></div> -->
        </div>

        <span class="no-value" @click="saveAndClose">{{ getString('popup.default.no_value') }}</span>
    </div>
</template>

<script>
import Vue from 'vue'
import i18n from '@/i18n'

export default {
    props: {
        // numDec: { type: Number, default: 1 },
        // stepDec: { type: Number, default: 1 },
        givenValue: { type: String, default: '0,0' },
        mesure: { type: String, default: 'ºC' },
        hasNegative: { type: Boolean, default: true }
    },
    data() {
        return {
            selNum: this.givenValue.split(',')[0],
            selDec: this.givenValue.split(',')[1],
            showPickerNum: false,
            showPickerDec: false,
            pickerNumData: [
                {
                    currentIndex: 0,
                    list: [],
                    className: 'number'
                }
            ],
            pickerDecData: [
                {
                    currentIndex: 0,
                    list: [],
                    className: 'decimal'
                }
            ],
            value: this.givenValue,
            isNegative: this.checkNegative()
        }
    },
    computed: {},
    methods: {
        keyPress(val) {
            if ((!this.value.includes('.') && (this.value.match(/\d/g) || []).length > 2) || (this.value.includes('.') && this.value.split('.')[1].length > 1)) {
            } else if (!this.isDisabled) {
                if ((this.value == '0' && this.pinType != 'password') || (this.value == this.startNumber && this.startNumber != -1 && !this.modified) || (this.givenValue == '' && this.startNumber != -1)) {
                    this.value = this.value + val
                } else {
                    this.value = this.value + val
                }

                if (!this.modified) this.modified = true
                this.value = parseFloat(this.value)
                this.value = String(this.value)
                // this.sendValue()
            }

            this.checkNegative()
            this.sendValue()
        },

        keyBack() {
            if (!this.isDisabled) {
                this.value = String(this.value).slice(0, -1)
                this.sendValue()
                this.checkNegative()
            }
        },

        keyPressDecimal(val) {
            if (!this.isDisabled) {
                if (this.value.indexOf(val) == -1) {
                    this.value = this.value + val
                }
                this.sendValue()
            }
        },

        keyPolarity() {
            if (!this.isDisabled) {
                this.isNegative = !this.isNegative
                if (this.isNegative) {
                    this.value = '-' + this.value
                } else {
                    this.value = this.value.replace('-', '')
                }
            }

            if (this.value && this.value != '-') {
                this.value = parseFloat(this.value)
                this.value = String(this.value)
            }
            this.sendValue()
        },

        checkNegative: function() {
            if (this.value) {
                this.isNegative = String(this.value).indexOf('-') != -1
                return this.isNegative
            } else {
                this.isNegative = false
                return this.isNegative
            }
        },

        sendError(e) {
            this.$emit('sendError', 'error :(')
        },

        saveAndClose() {
            this.$emit('sendValue', '-')
            this.$emit('saveAndClose')
        },
        // setValue() {
        //     this.$emit('sendValue', this.selNum + ',' + this.selDec)
        // },

        sendValue() {
            if (!isNaN(this.value)) {
                if (this.isNegative) {
                    // this.$emit('sendValue', '-' + this.value)
                    this.$emit('sendValue', this.value)
                } else {
                    this.$emit('sendValue', this.value)
                }
            }
            this.checkNegative()
        },

        changeNum(gIndex, iIndex) {
            this.selNum = this.pickerNum[gIndex].list[iIndex]
            this.setValue()
        },
        changeDec(gIndex, iIndex) {
            this.selDec = this.pickerDec[gIndex].list[iIndex]
            this.setValue()
        },

        getString(str) {
            return i18n.t(str)
        },
        getStringPlural(str, pos) {
            return i18n.tc(str, pos)
        },
        classNegative() {
            if (this.isNegative) return 'selected'
        }
    },
    mounted() {
        this.checkNegative()
        // setTimeout(() => {
        //     this.showPickerNum = true
        //     this.showPickerDec = true
        // }, 100)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.popup-container {
    .temperature-container {
        @include flex-direction(column);
        $key-size: rem(60px);
        $topkey-size: rem(60px);
        width: 100%;

        &.portrait {
            $key-size: rem(50px);
        }

        .number-box {
            @include display-flex();
            @include flex-wrap($w: wrap);
            @include align-items();
            @include justify-content();
            position: relative;
            margin: 15px 0;

            .hint {
                @include font-size(s);
                margin-bottom: 5px;
                width: 100%;
                text-align: center;
                font-family: $text-light;
                color: $inactive-s20;

                b {
                    color: $inactive-s20;
                }
            }

            .negative {
                margin-left: 0;
                // position: absolute;
                // left: 0;
                // bottom: 0;
                // height: 40px;
                // background-color: $inactive-t50;

                &.selected {
                    background-color: $main;
                    color: #fff;
                }
            }

            .pin {
                @include border-radius(4px);
                @include font-size(xl);
                border: 2px solid #eee;
                height: 40px;
                width: calc((#{$key-size} * 3) + (#{rem(12px)} * 3));
                background-color: #f9f9f9;
                color: #666;
                text-align: center;
                padding: 24px 0;
            }

            .units {
                @include display-flex();
                @include align-items();
                @include justify-content();
                @include border-radius(4px);
                @include font-size(l);
                font-family: $text;
                color: $main-s20;
                width: $key-size;
                height: $key-size;
                margin: rem(6px);
            }
        }

        .num-pad {
            @include display-flex();
            @include flex-wrap($w: wrap);
            @include align-items();
            @include justify-content();
            width: calc((#{$key-size} * 3) + (#{rem(12px)} * 3));
            margin: 0 auto;

            .negative {
                // position: absolute;
                // @include border-radius(100px);
                left: 0;
                bottom: 0;
                height: $key-size;
                width: $key-size;
                background-color: $inactive-t80;

                &.selected {
                    background-color: $main;
                    color: #fff;
                }
            }

            .pin {
                @include border-radius(4px);
                @include font-size(xl);
                border: 2px solid #eee;
                height: $key-size;
                width: calc((#{$key-size} * 2) + (#{rem(12px)} * 2) - #{rem(3px)});
                background-color: #f9f9f9;
                color: #666;
                text-align: center;
                padding: 24px 0;
            }

            .units {
            }
        }

        .key {
            @include interaction();
            @include display-flex();
            @include align-items();
            @include justify-content();
            @include border-radius(4px);
            @include font-size(l);
            font-family: $text-bold;
            color: $main-s20;
            width: $key-size;
            height: $key-size;
            margin: rem(6px);
            background-color: $inactive-t80;

            // @include bgHover($main-t90);
            &:active {
                cursor: pointer;
                animation: numpadClick 0.2s;
                @include bgHover($main-t80);
            }

            @keyframes numpadClick {
                0%,
                100% {
                    transform: scale(1, 1);
                }
                25% {
                    transform: scale(1.1, 1.1);
                }
                50% {
                    transform: scale(1, 1);
                }
                75% {
                    transform: scale(1.05, 1.05);
                }
            }

            &.keyback {
                @include background($image: img('undo_primary.svg'), $size: 25px);
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 472.615 472.615' style='enable-background:new 0 0 472.615 472.615;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cg%3E%3Cpath d='M139.569,96.738L0,236.307l139.569,139.57h333.046v-139.57V96.738H139.569z M362.673,305.637l-13.923,13.922 l-69.329-69.332l-69.33,69.332l-13.922-13.922l69.329-69.332l-69.329-69.332l13.922-13.923l69.33,69.332l69.329-69.332 l13.923,13.923l-69.33,69.332L362.673,305.637z' fill='%23475b96'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
            }
            &.disabled {
                @include opacity(0);
            }

            // &.negative,
            // &.positive {
            //     @include border-radius(100%);
            //     width: rem(40px);
            //     height: rem(40px);
            //     display: inline-block;
            //     position: absolute;
            //     padding: 6px;
            //     background-color: $main-t90;
            //     background-clip: content-box;
            //     transition-duration: 0.2s;

            //     &.selected {
            //         background-size: 16px;
            //         padding: 3px;
            //         background-color: transparent;
            //     }
            //     &.selected ~ .pin {
            //         padding-left: rem(40px);
            //     }
            // }
            // &.negative {
            //     @include background($size: 12px, $image: img('less_primary_dark.svg'));
            // }
            // &.positive {
            //     @include background($size: 12px, $image: img('more_primary_dark.svg'));
            // }

            // &.operator {
            //     @include background($size: 15px, $color: $neutro);
            //     width: $topkey-size;
            //     height: $topkey-size;
            //     display: inline-block;
            //     margin: 0 6px;

            //     @include bgHover($neutro);
            // }
            // &.less {
            //     background-image: img('less_primary.svg');
            // }
            // &.more {
            //     background-image: img('more_primary.svg');
            // }
        }

        .no-value {
            @include interaction();
            @include border-radius(3px);
            @include font-size(m);
            @include display-flex();
            @include align-items();
            @include justify-content();
            font-family: $text;
            color: $main-dark;
            height: rem(40px);
            // display: block;
            width: rem(165px);
            margin: rem(6px) auto;
            text-align: left;
            margin-top: 20px;
        }

        @include small {
            $m-key-size: rem(65px);
            $m-topkey-size: rem(65px);

            .num-pad {
                width: calc((#{$m-key-size} * 3) + (#{rem(12px)} * 3));
            }
            .key {
                // width: $m-key-size;
                // height: $m-key-size;

                &.operator {
                    width: $m-topkey-size;
                    height: $m-topkey-size;
                }
            }
        }
        &.desktop {
            .key {
                @include bgHover($main-t90);
            }
        }
    }
    @media all and (orientation: portrait) {
        .numpad-content {
            width: 100%;
            height: auto;

            .num-pad {
                width: calc(270px + 7.5px * 6);
                height: auto;
                overflow-x: hidden;

                .key {
                    width: 90px;
                    height: 90px;
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.popup-container.portrait {
    .pop-body {
        .temperature-container {
            @include display-flex();
            @include align-items();
            @include justify-content();
            @include flex-direction(column);
            // @include flex-wrap(wrap);
            max-width: calc(100%);
            overflow: auto;
            height: 100%;
            position: relative;
            width: 100%;

            .number-box {
                margin: 0;

                .hint {
                    margin-bottom: -5px;
                }

                .negative {
                }

                .pin {
                    height: 62px;
                }

                .units {
                }
            }

            // .num-pad {
            //     @include flex-wrap(wrap);
            //     @include justify-content(space-between);
            //     width: 100%;
            //     height: calc(100% - calc((100vh / 4) - 20px) - 14px);
            //     position: relative;
            //     max-width: 300px;
            //     max-height: 375px;
            //     min-height: 225px;

            //     .key {
            //         @include font-size(sm);
            //         flex-shrink: 0;
            //         flex: 0 0 auto;
            //         height: 20%;
            //         width: calc((100% / 3) - 10px);
            //         padding: 0;
            //         line-height: 20%;
            //         // height: calc((100% / 4) - 10px);
            //         margin: 0;
            //     }
            // }
        }
    }
}
</style>
