import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'

const Login = () => import('@/views/Login')
const EmptyPage = () => import('@/views/EmptyPage')
const User = () => import('@/views/User')
const Offline = () => import(/* webpackPrefetch: true */ '@/views/Offline')

const Home = () => import(/* webpackPrefetch: true */ '@/views/Home')
const Label = () => import(/* webpackChunkName: "label" */ /* webpackPrefetch: true */ '@/views/Label')
const DigitalLabel = () => import(/* webpackChunkName: "label" */ /* webpackPrefetch: true */ '@/views/DigitalLabel')
const LabelResume = () => import(/* webpackChunkName: "label" */ /* webpackPrefetch: true */ '@/views/LabelResume')
const Library = () => import('@/views/Library')
const Tasks = () => import(/* webpackChunkName: "appcc" */ '@/views/Tasks')
const Register = () => import(/* webpackChunkName: "appcc" */ '@/views/Register')
const Audit = () => import(/* webpackChunkName: "appcc" */ '@/views/Audit')
const Issues = () => import(/* webpackChunkName: "appcc" */ '@/views/Issues')
const Config = () => import('@/views/Config')
const Release = () => import('@/views/Release')
const Ticket = () => import('@/views/Ticket')

// const Timer = () => import('@/views/Timer')
// import Logbook from "@/views/Logbook";

Vue.use(Router)

export default new Router({
    mode: process.env.VUE_APP_URL_TYPE == 'HASH' ? 'hash' : 'history',
    routes: [
        {
            path: '/blank',
            name: 'Blank',
            component: EmptyPage,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/reset/:page',
            name: 'Reset',
            component: EmptyPage,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/offline',
            name: 'Offline',
            component: Offline,
            meta: {
                layout: 'offline'
            }
        },
        {
            path: '/login',
            name: 'Login',
            alias: '/logout',
            component: Login,
            meta: {
                layout: 'login'
            },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/',
            name: 'HomePage',
            redirect: store.getters.getHome ? store.getters.getHome : '/home',
            component: Home,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            beforeEnter: checkBeforeEnter
        },
        // {
        //   path: "/logbook",
        //   name: "Logbook",
        //   component: Logbook,
        //   beforeEnter: checkBeforeEnter
        // },
        // {
        //   path: "/logbook/add/",
        //   name: "LogbookAdd",
        //   component: Logbook,
        //   beforeEnter: checkBeforeEnter
        // },
        // {
        //   path: "/logbook/:id",
        //   name: "LogbookDetail",
        //   component: Logbook,
        //   beforeEnter: checkBeforeEnter
        // },
        // {
        //   path: "/logbook/edit/:id",
        //   name: "LogbookEdit",
        //   component: Logbook,
        //   beforeEnter: checkBeforeEnter
        // },

        {
            path: '/label',
            name: 'Label',
            component: Label,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/label/queue',
            name: 'LabelQueue',
            component: Label,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/elabel',
            name: 'DigitalLabel',
            component: DigitalLabel,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/label/resume',
            name: 'LabelResume',
            component: LabelResume,
            beforeEnter: checkBeforeEnter
        },
        /*{
            path: '/timer',
            name: 'Timer',
            component: Timer,
            beforeEnter: checkBeforeEnter
        },*/
        {
            path: '/library',
            name: 'Library',
            component: Library,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/library/:id',
            name: 'LibraryDetail',
            component: Library,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/checklist',
            name: 'Checklist',
            component: Tasks,
            props: { propDate: '' },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/checklist/:id',
            name: 'ChecklistDetail',
            component: Tasks,
            //   props: { checkType: 1 },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/user',
            name: 'user',
            component: User,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            props: { checkType: 3 },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/register/:id',
            name: 'RegisterDetail',
            component: Register,
            props: { checkType: 3 },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/register/:id/:edit',
            name: 'RegisterEdit',
            component: Register,
            props: { checkType: 3 },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/audit',
            name: 'Audit',
            component: Audit,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/audit/:id',
            name: 'AuditDetail',
            component: Audit,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/audit/:id/:edit',
            name: 'AuditEdit',
            component: Audit,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/issues',
            name: 'Issues',
            component: Issues,
            // props: { checkType: 4 },
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/issues/:id',
            name: 'IssuesDetail',
            component: Issues,
            // props: { checkType: 4 },
            beforeEnter: checkBeforeEnter
        },

        {
            path: '/release',
            name: 'Release',
            component: Release,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/config',
            name: 'Config',
            component: Config,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/config/:page',
            name: 'ConfigPage',
            component: Config,
            beforeEnter: checkBeforeEnter,
            props: true
        },
        {
            path: '/tickets',
            name: 'Tickets',
            component: Ticket,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/tickets/new',
            name: 'TicketsForm',
            component: Ticket,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/tickets/:id',
            name: 'TicketsDetail',
            component: Ticket,
            beforeEnter: checkBeforeEnter
        },
        {
            path: '/dbpreview/:token/:location/:id',
            name: 'DashboardPreview',
            beforeEnter: dashboardAccess,
            meta: {
                layout: 'login'
            }
        },
        {
            path: '*',
            name: 'not_found',
            redirect: 'home',
            component: Home,
            beforeEnter: checkBeforeEnter
        }
    ]
})

function checkBeforeEnter(to, from, next) {
    //   console.log('checkBeforeEnter', to, from, next)
    // if (!fnCheckConnection() && from.name == null && process.env.VUE_APP_IS_APP !== 'TRUE') {
    //     next('/offline')
    // }
    Vue.prototype.$bar.reset()
    Vue.prototype.$popup.close()
    // if( to.name == 'Home' ){
    //   Vue.prototype.$bar.hide();
    //   store.commit('setAlexVisible', false);
    //   store.commit('setMenuToolsExpanded', true);
    // } else {
    //   Vue.prototype.$bar.show();
    //   store.commit('setAlexVisible', true);
    //   store.commit('setMenuToolsExpanded', false);
    // }

    if (process.env.VUE_APP_IS_APP === 'TRUE') {
        FirebasePlugin.logEvent('page_view', { content_type: 'page_view', item_id: to.name, item_name: to.path })
        FirebasePlugin.setScreenName(to.name)
    }

    if (to.name != 'Login') {
        Vue.prototype.$bar.show()
        store.commit('setAlexVisible', true)
        store.commit('setMenuToolsExpanded', false)
        store.commit('setOverlayVisible', false)
        store.commit('setMenuUserVisible', false)
        Vue.prototype.$overlay.hide()

        requireAuth(to, from, next)
    } else {
        next()
    }
}

/*
    This will cehck to see if the user is authenticated or not.
*/
function requireAuth(to, from, next) {
    /*
        Determines where we should send the user.
      */
    // console.log("checkBeforeEnter");
    // console.log(store.getters["login/getHasLogin"]);

    function proceed() {
        /*
              If the user has been loaded determine where we should
              send the user.
            */

        if (store.getters['login/getHasLogin']) {
            /*
                  If the user is not empty, that means there's a user
                  authenticated we allow them to continue. Otherwise, we
                  send the user back to the home page.
                */
            next()
        } else if (!fnCheckConnection()) {
            next()
        } else {
            next('Login')
        }
    }

    log('1234', store.getters['login/isLoad'])
    if (store.getters['login/isLoad']) {
        /*
        Confirms the user has been loaded
        */

        log('1', store.getters['login/getHasLogin'])
        if (!store.getters['login/getHasLogin']) {
            /*
                If not, load the user
              */
            log('STEP 2')
            store.dispatch('login/initLocation').then(function() {
                log('THEN')
                if (!store.getters['login/getHasLogin']) {
                    log('!getHasLogin - Go LOGIN')
                    next('Login')
                } else {
                    // CHECK IF HAS OR NOT ROLE LEVEL TO ACCESS
                    if (!store.getters['hasAccessByRole'](to)) {
                        log('ROLE ACCESS LEVEL DENIED')
                        next('home')
                    } else {
                        store.dispatch('checkAppVersions').then(function() {
                            proceed()
                            // store.dispatch( 'loadPrinterInfo' ).then( function(){
                            //   proceed();
                            // });
                        })
                    }
                }
            })
        } else {
            /*
            User call completed, so we proceed
            */
            store.dispatch('checkAppVersions').then(function() {
                proceed()
                // store.dispatch( 'loadPrinterInfo' ).then( function(){
                //   proceed();
                // });
            })
        }
    } else {
        store.dispatch('login/doLoadLocalForage').then(function() {
            requireAuth(to, from, next)
        })
    }
}

function dashboardAccess(to, from, next) {
    log('dashboard access ...')
    store
        .dispatch('login/loadDashboardAccess', to.params)
        .then(function() {
            log('finish load dashboard information')
            next('/')
        })
        .catch(error => {
            logError(error)
        })
}
