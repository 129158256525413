<template>
    <div id="splashscreeen">
        <transition name="fade">
            <div name="modal" id="overlay" v-if="show">
                <div v-if="locationData.avatar">
                    <div class="avatar" :style="{ backgroundImage: 'url(' + locationData.avatar + ')' }"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import store from '@/store'

export default {
    computed: {
        locationData() {
            return store.getters['loginUser/getLocalLocation']
        }
    },
    data() {
        return {
            show: false
        }
    },
    created() {}
}
</script>

<style lang="scss">
#splashscreeen {
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease-in-out, transform 0.3s ease;
    }
    .fade-enter-active {
        transition-delay: 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-to,
    .fade-leave {
        opacity: 1;
    }

    #overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background-color: #f4f2ed;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-overlay-default + 2;
        @include background($image: img('andy_kitchen.png'), $size: 250px, $position: center center);

        .avatar {
            @include opacity(0.8);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            width: 200px;
            height: $menu-top-tools-height;
            line-height: initial;
            display: inline-block;
            cursor: auto;
            margin-bottom: 350px;
        }
    }
}
</style>
