import Vue from 'vue'
import Vuex from 'vuex'

// Carga de modulos
import login from '@/store/modules/login'
import loginUser from '@/store/modules/loginUser'
import employee from '@/store/modules/employee'
import checklist from '@/store/modules/checklist'
import tasks from '@/store/modules/tasks'
import register from '@/store/modules/register'
import audit from '@/store/modules/audit'
import issues from '@/store/modules/issues'
import library from '@/store/modules/library'
import logbook from '@/store/modules/logbook'
import notes from '@/store/modules/notes'
import label from '@/store/modules/label'
import printer from '@/store/modules/printer'
import account from '@/store/modules/account'
import ticket from '@/store/modules/ticket'
import pusher from '@/store/modules/pusher'

import { Api } from '@/core/api.js'

import i18n from '@/i18n'

import localforage from 'localforage'

localforage.config({
    name: 'ALEX',
    version: 1.0
})
var db_updates = localforage.createInstance({
    name: 'alexdb',
    storeName: 'updates'
})

const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })

Vue.use(Vuex)
const api = new Api()

// const debug = process.env.NODE_ENV !== 'production'
const debug = false

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        languages: ['es', 'en', 'pt', 'de'],
        language: localStorage.language ? localStorage.language : 'es',
        locale: 'es-es',
        app_version: process.env.VUE_APP_VERSION,
        server_version_current: false,
        server_version_min: false,
        server_version_recommended: false,
        is_app: process.env.VUE_APP_IS_APP == 'TRUE',
        force_update: false,
        update_available: false,
        conn_state: true,
        custom_home: false,
        geoLocation: false,

        title: 'ALEX',
        reason_postpone: {},
        reason_justify: {},
        issue_states: {},
        printerStore: false,
        printerModels: false,
        pusherData: {},
        isMaintenanceMode: false,

        uuid: localStorage.device_uuid ? localStorage.device_uuid : false,
        coords: localStorage.device_coords ? JSON.parse(localStorage.device_coords) : false,

        tools: {
            // 0: {
            //   name: i18n.t('tools.home_title'),
            //   id: "navHome", href: "/home",
            //   order: 0,
            // },
            1: {
                id: 'navChecklist',
                name: 'tools.checklist_title',
                action: '/checklist',
                roles: [2, 3, 4, 5, 6, 7, 8],
                priority: 1,
                code: 1
            },
            2: {
                id: 'navIssue',
                name: 'tools.issue_title',
                action: '/issues',
                roles: [2, 3, 4, 5, 6, 7, 8],
                priority: 3,
                code: 2
            },
            3: {
                id: 'navLabel',
                name: 'tools.label_title',
                action: '/label',
                roles: [1, 2, 3, 4, 5, 6, 7, 8],
                priority: 4,
                code: 3
            },
            11: {
                id: 'navDigitalLabel',
                name: 'tools.elabel_title',
                action: '/elabel',
                roles: [1, 2, 3, 4, 5, 6, 7, 8],
                priority: 5,
                code: 11
            },
            4: {
                id: 'navRegister',
                name: 'tools.register_title',
                action: '/register',
                roles: [2, 3, 4, 5, 6, 7, 8],
                priority: 2,
                code: 4
            },
            7: {
                id: 'navAudit',
                name: 'tools.audit_title',
                action: '/audit',
                roles: [2, 3, 4, 5, 6, 7, 8],
                priority: 7,
                code: 7
            },
            5: {
                id: 'navLibrary',
                name: 'tools.library_title',
                action: '/library',
                roles: [1, 2, 3, 4, 5, 6, 7, 8],
                priority: 6,
                code: 5
            },
            6: {
                id: 'navTraining',
                name: 'tools.training_title',
                action: '/training',
                roles: [2, 3, 4, 5, 6, 7, 8],
                priority: 8,
                code: 6
            }
            //   9: {
            //     id: 'navTickets',
            //     name: 'tools.tickets',
            //     action: '/tickets',
            //     roles: [1,2, 3, 4, 5, 6, 7, 8],
            //     priority: 8,
            //     code: 9
            //   }
        },

        external_tools: typeof localStorage.menu_items !== 'undefined' ? JSON.parse(localStorage.menu_items) : {},

        overlay_is_visible: false,
        overlay_is_loading: false,

        alex_is_visible: true,

        menu_top_is_visible: true,
        menu_tools_is_visible: true,
        menu_tools_is_expanded: false,
        menu_user_is_visible: false,
        submenu_tools_is_expanded: false,

        refresh: {
            employee: true,
            library: true,
            templates: true,
            products: true
        },
        timeoutProducts: null,
        iTimeoutProducts: 3600000,
        datetime: moment(),

        icon: null,
        alex_info_url: null,
        freeLabelling: typeof localStorage.freeLabelling !== 'undefined' ? localStorage.freeLabelling : 0,
        relatedLabels: typeof localStorage.relatedLabels !== 'undefined' ? localStorage.relatedLabels : 0,
        dateLimitation: typeof localStorage.dateLimitation !== 'undefined' ? localStorage.dateLimitation : 0,
        barcodeActive: false,
        currentCamera: 0,
        showSplashScreen: 1,
        mq: null,
        serverTimeDifference: 0,
        timeZoneName: null,
        sound: true,
        last_employee_action: false,
        chat_active: false,
        android_autotime_enable: true,
        android_airplane_enable: false
    }
}

const state = getDefaultState()

// load information from localForage
;(async () => {
    const icon = await db_login.getItem('app_icon')
    state.icon = icon ? icon : 'img/alexpose_02.svg'

    const alex_info_url = await db_login.getItem('alex_info_url')
    state.alex_info_url = alex_info_url ? alex_info_url : false

    const config = await db_login.getItem('config')
    state.custom_home = config && config.home ? config.home : false

    const current_camera = await db_login.getItem('current_camera')
    state.currentCamera = current_camera ? current_camera : 0

    const printer = await db_login.getItem('printer')
    state.printerStore = printer ? printer : false
    state.printerModels = printer ? (typeof printer.templates !== 'undefined' ? printer.templates : false) : false

    const timeZoneName = await db_login.getItem('timeZoneName')
    state.timeZoneName = typeof timeZoneName !== 'undefined' && timeZoneName ? timeZoneName : null

    const sound = await db_login.getItem('sound')
    state.sound = typeof sound !== 'undefined' && sound ? sound : null

    const serverTimeDifference = await db_login.getItem('serverTimeDifference')
    state.serverTimeDifference = typeof serverTimeDifference !== 'undefined' && serverTimeDifference ? serverTimeDifference : 0

    const lastEmployeeAction = await db_login.getItem('last_employee_action')
    state.last_employee_action = typeof lastEmployeeAction !== 'undefined' ? lastEmployeeAction : false
})()

export default new Vuex.Store({
    state: state,
    modules: {
        login,
        loginUser,
        employee,
        checklist,
        library,
        logbook,
        notes,
        label,
        tasks,
        register,
        audit,
        issues,
        printer,
        account,
        ticket,
        pusher
    },
    strict: debug,
    getters: {
        getHome: (state, getters, rootState) => {
            return state.custom_home
        },
        getMaintenance: (state, getters, rootState) => {
            return state.isMaintenanceMode
        },
        getPusherData: (state, getters, rootState) => {
            return state.pusherData
        },
        getGeoLocation: (state, getters, rootState) => {
            return state.geoLocation
        },
        getMenuToolsExpanded: (state, getters, rootState) => {
            return state.menu_tools_is_expanded
        },
        getSubMenuToolsExpanded: (state, getters, rootState) => {
            return state.submenu_tools_is_expanded
        },
        getMenuUserVisible: (state, getters, rootState) => {
            return state.menu_user_is_visible
        },
        getCustomHome: (state, getters, rootState) => {
            return state.custom_home
        },
        getVersion: (state, getters, rootState) => {
            return state.app_version
        },
        getIsApp: (state, getters, rootState) => {
            return state.is_app
        },
        getReasonPostpone: (state, getters, rootState) => {
            return state.reason_postpone
        },
        getSingleReasonPostpone: (state, getters, rootState) => id => {
            return state.reason_postpone[id]
        },
        getReasonJustify: (state, getters, rootState) => {
            return state.reason_justify
        },
        getSingleReasonJustify: (state, getters, rootState) => id => {
            return state.reason_justify[id]
        },
        getIssueStates: (state, getters, rootState) => {
            return Object.keys(state.issue_states).length ? state.issue_states : false
        },
        getSingleIssueStates: (state, getters, rootState) => id => {
            return state.issue_states[id]
        },
        getForceUpdate: (state, getters, rootState) => {
            return state.force_update
        },
        getUpdateAvailable: (state, getters, rootState) => {
            return state.update_available
        },
        getLanguages: (state, getters, rootState) => {
            return state.languages
        },
        getLanguage: (state, getters, rootState) => {
            return state.language
        },
        getLocale: (state, getters, rootState) => {
            return state.locale
        },
        getConnState: (state, getters, rootState) => {
            return state.conn_state
        },

        getDateSelector: (state, getters, rootState) => {
            // OPTIONS MOVED FROM DATA TO HERE TO BE REACTIVE
            return {
                options: [
                    {
                        text: i18n.t('assets.date.today'),
                        value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), 'today']
                    },
                    {
                        text: i18n.t('assets.date.yesterday'),
                        value: [
                            moment()
                                .subtract(1, 'days')
                                .format('YYYY-MM-DD'),
                            moment()
                                .subtract(1, 'days')
                                .format('YYYY-MM-DD'),
                            'yesterday'
                        ]
                    },
                    {
                        text: i18n.t('assets.date.last_week'),
                        value: [
                            moment()
                                .subtract(7, 'days')
                                .format('YYYY-MM-DD'),
                            moment().format('YYYY-MM-DD'),
                            'week'
                        ]
                    },
                    {
                        text: i18n.t('assets.date.last_month'),
                        value: [
                            moment()
                                .subtract(30, 'days')
                                .format('YYYY-MM-DD'),
                            moment().format('YYYY-MM-DD'),
                            'month'
                        ]
                    },
                    {
                        text: i18n.t('assets.date.range'),
                        value: [
                            moment()
                                .subtract(1, 'days')
                                .format('YYYY-MM-DD'),
                            moment().format('YYYY-MM-DD'),
                            'range'
                        ],
                        action: true
                    }
                ]
            }
            // return state.date_selector
        },

        getTools: (state, getters, rootState) => {
            var external = state.external_tools
            // var external = localStorage.menu_items ? JSON.parse(localStorage.menu_items) : {};

            return { ...state.tools, ...external }
        },

        getToolsByRoleLevel: (state, getters, rootState) => level => {
            var result = {}

            log(state.tools)

            var tools = { ...state.tools }

            for (var section_index in Object.values(tools)) {
                var section = { ...Object.values(tools)[section_index] }
                var sectionroles = section.roles

                if (section == undefined || sectionroles.includes(level)) {
                    if (result[Object.keys(tools)[section_index]] == undefined) {
                        result[Object.keys(tools)[section_index]] = {}
                    }
                    result[Object.keys(tools)[section_index]] = { ...section }
                }
            }

            var external = localStorage.menu_items ? JSON.parse(localStorage.menu_items) : {}

            return { ...result, ...external }
        },

        getAlexInfoUrl: (state, getters, rootState) => {
            return state.alex_info_url ? state.alex_info_url : false
        },

        getToolRoute: (state, getters, rootState) => {
            return state.tools
        },

        formatedDate: (state, getters, rootState) => {
            return state.datetime.format('DD MMMM YYYY')
        },
        formatedTime: (state, getters, rootState) => {
            return state.datetime.format('ddd HH:mm')
        },
        getMoment: (state, getters, rootState) => {
            return state.datetime
        },

        currentPrinter: (state, getters, rootState) => {
            return state.printerStore
        },
        printerModels: (state, getters, rootState) => {
            return state.printerModels
        },

        hasAccess: (state, getters, rootState) => (id, level) => {
            level = typeof level !== 'undefined' ? level : 3

            // LEyes "no escritas" sobre los permisos/roles ಠ_ಠ
            // 0 - Diria que sin permisos / Employee
            // 1 - Jefe de turno / Employee
            // 2 - JEfe de turno / Gerente
            // 3 - SUPERVISOR (si o si)

            var access = false
            if (state.loginUser.hasLoginUser) {
                var emp_id = false
                if (typeof state.loginUser.local.employee_id !== 'undefined') {
                    emp_id = state.loginUser.local.employee_id
                }

                var role_id = state.loginUser.local.user.rol

                if (state.employee.roles == undefined) {
                    var roles = state.employee.filters.roles
                } else var roles = state.employee.roles

                var role = roles[role_id]
                var levelRole = parseInt(role.level)

                if (emp_id) {
                    if (levelRole >= level) {
                        access = true
                    }
                } else {
                    if (levelRole >= level) {
                        access = true
                    }
                }
            }
            return access
        },

        hasAccessByRole: (state, getters, rootState) => to => {
            var available_tools = state.login.config.tools
            var destination_path = to.path

            if (state.loginUser.hasLoginUser) {
                var role_id = state.loginUser.local.user.rol

                if (state.employee.roles == undefined) {
                    var roles = state.employee.filters.roles
                } else var roles = state.employee.roles

                var role = roles[role_id]
                var level = parseInt(role.level)

                var tools = { ...state.tools }

                for (var section_index in tools) {
                    var section = { ...tools[section_index] }
                    var sectionroles = section.roles
                    var sectionaction = section.action

                    if (destination_path.includes(sectionaction)) {
                        if (sectionroles.includes(level) && available_tools.includes(parseInt(section_index))) {
                            return true
                        } else return false
                    }
                }
                // IF IS A TOOL THAT IS NOT IN TOOL'S ARRAY
                if (destination_path.includes('config') || destination_path.includes('user') || destination_path.includes('tickets')) {
                    return true
                } else return false
            }
        },

        getAppIcon: (state, getters, rootstate) => {
            return state.icon
        },

        getFreeLabelling: (state, getters, rootstate) => {
            return parseInt(state.freeLabelling)
        },

        getRelatedLabels: (state, getters, rootstate) => {
            return parseInt(state.relatedLabels)
        },

        getDateLimitation: (state, getters, rootstate) => {
            return parseInt(state.dateLimitation)
        },

        getUuid: (state, getters, rootstate) => {
            return state.uuid
        },

        getRefresh: (state, getters, rootstate) => {
            return state.refresh
        },

        getBarcodeActive: (state, getters, rootState) => {
            return state.barcodeActive
        },

        getCurrentCamera: (state, getters, rootstate) => {
            return state.currentCamera
        },
        getShowSplashScreen: (state, getters, rootstate) => {
            return state.showSplashScreen
        },
        getMediaQuery: (state, getters, rootState) => {
            return state.mq
        },
        getServerTimeDifference: (state, getters, rootState) => {
            return state.serverTimeDifference
        },
        getTimeZoneName: (state, getters, rootState) => {
            return state.timeZoneName
        },
        getSound: (state, getters, rootState) => {
            return state.sound
        },
        getLastEmployeeAction: (state, getters, rootState) => {
            return state.last_employee_action
        },
        getServerVersionCurrent: (state, getters, rootState) => {
            return state.server_version_current
        },
        getServerVersionMin: (state, getters, rootState) => {
            return state.server_version_min
        },
        getServerVersionRecommended: (state, getters, rootState) => {
            return state.server_version_recommended
        },
        getIsChatActive: (state, getters, rootState) => {
            return state.chat_active
        },
        getAutoTimeEnable: (state, getters, rootState) => {
            return state.android_autotime_enable
        },
        getAirPlaneModeEnable: (state, getters, rootState) => {
            return state.android_airplane_enable
        }
    },
    actions: {
        async getCountSyncItems(context) {
            var count = 0
            var sync_modules = ['loginUser', 'label']

            var aCounts = await Promise.all(
                sync_modules.map(async module => {
                    let c = await context.dispatch(module + '/getCountSync')
                    return c
                })
            )

            aCounts.forEach(c => {
                count += c
            })

            return count
        },

        syncronize({ dispatch, commit }) {
            return dispatch('label/sendFailedPrintErrors').then(() => {
                return dispatch('label/sendFailedLabelsPrint').then(() => {
                    return dispatch('loginUser/sendFailedLogins').then(() => {
                        return true
                    })
                })
            })
        },

        playSound(context, sound) {
            if ((context.state.sound == 'undefined' || context.state.sound != false) && sound) {
                sound = '/sounds/' + sound
                var audio = new Audio(sound)
                audio.play()
            }
        },

        resetStore({ dispatch, commit }) {
            // No tiene mucho sentido resetear el login, no? :(
            // dispatch('login/resetState');
            dispatch('loginUser/resetState')
            dispatch('employee/resetState')
            dispatch('checklist/resetState')
            dispatch('library/resetState')
            dispatch('logbook/resetState')
            dispatch('notes/resetState')
            dispatch('label/resetState')
            dispatch('tasks/resetState')
            dispatch('register/resetState')
            dispatch('audit/resetState')
            dispatch('issues/resetState')
            dispatch('printer/resetState')

            commit('resetState')
        },

        checkUpdates(context, params) {
            if (fnCheckConnection()) {
                return api.get('updates', {}).then(function(response) {
                    var data = {}

                    db_updates
                        .iterate(function(value, key, iterationNumber) {
                            if (key != 'library') {
                                data[key] = value && response.data && typeof response.data[key] !== 'undefined' ? response.data[key] : value
                            } else {
                                data['library'] = typeof context.getters['getRefresh'].library !== 'undefined' ? context.getters['getRefresh'].library : true
                            }
                        })
                        .then(function() {
                            _.forEach(response.data, function(value, key) {
                                db_updates.setItem(key, value)
                            })

                            log('store => checkUpdates', data)

                            context.commit('setUpdates', data)
                        })
                })
            }
        },

        checkAppVersions(context, component) {
            log('-- checkAppVersions --')

            let appVersionIsBiggerOrEqual = compareVersions(context.getters['getVersion'], context.getters['getServerVersionMin'])

            // force to update app
            if (!appVersionIsBiggerOrEqual) {
                context.commit('setForceUpdate', context.getters['getServerVersionMin'])
            }

            appVersionIsBiggerOrEqual = compareVersions(context.getters['getVersion'], context.getters['getServerVersionRecommended'])

            // show popup to update app
            if (!appVersionIsBiggerOrEqual) {
                context.commit('setUpdateAvailable', context.getters['getServerVersionRecommended'])
            }
            return true
        },

        loadAppIcon(context, icon) {
            if (typeof icon !== 'undefined') {
                if (process.env.VUE_APP_IS_APP === 'TRUE' && fnCheckConnection()) {
                    var aux_icon = icon.path.split('/')
                    aux_icon = aux_icon[aux_icon.length - 1]
                    window.resolveLocalFileSystemURL(
                        cordova.file.applicationStorageDirectory + 'images/' + aux_icon,
                        function(fileEntry) {
                            log('loadAppIcon', fileEntry)
                            fileEntry.file(
                                function(entry) {
                                    log(entry)
                                    if (entry.lastModified < icon.lastUpdate) {
                                        return context.dispatch('downloadAppIcon', { uri: icon.path, finalPath: cordova.file.applicationStorageDirectory + 'images/' + aux_icon })
                                    } else {
                                        return context.commit('setAppIcon', cordova.file.applicationStorageDirectory + 'images/' + aux_icon)
                                    }
                                },
                                function(e) {
                                    log(e)
                                    return context.dispatch('downloadAppIcon', { uri: icon.path, finalPath: cordova.file.applicationStorageDirectory + 'images/' + aux_icon })
                                }
                            )
                        },
                        function(e) {
                            log(e)
                            return context.dispatch('downloadAppIcon', { uri: icon.path, finalPath: cordova.file.applicationStorageDirectory + 'images/' + aux_icon })
                        }
                    )
                } else {
                    return context.commit('setAppIcon', icon.path + '?' + icon.lastUpdate)
                }
            }
        },

        downloadAppIcon(context, icon) {
            var fileTransfer = new FileTransfer()
            fileTransfer.download(
                icon.uri,
                icon.finalPath,
                function(entry) {
                    log('Successful download...')
                    log('download complete: ' + entry.toURL())
                    return context.commit('setAppIcon', icon.finalPath)
                },
                function(error) {
                    log('download error source ' + error.source)
                    log('download error target ' + error.target)
                    log('upload error code' + error.code)
                },
                null, // or, pass false
                {}
            )
        },

        loadExternalTools(context, tools) {
            return context.commit('setExternalTools', tools)
        },

        testCORS(context, params) {
            return api.get('test/cors', {})
        }
    },
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },

        setTitle(state, value) {
            state.title = value
            // Vue.set(state, 'title', {...state.title});
        },
        setPusherData(state, value) {
            state.pusherData = value
            Vue.set(state, 'pusherData', { ...value })
        },

        setMaintenance(state, value) {
            state.isMaintenanceMode = value
            Vue.set(state, 'isMaintenanceMode', value)
        },

        setLanguages(state, value) {
            state.languages = value
            // Vue.set(state, 'language', {...state.language});
        },
        setGeoLocation(state, value) {
            var coords = state.geoLocation
            coords = value
            Vue.set(state, 'geoLocation', coords)
            localStorage.setItem('device_coords', JSON.stringify(coords))
        },
        setLanguage(state, value) {
            state.language = value
            // Vue.set(state, 'language', {...state.language});
        },
        changeLanguage(state, value) {
            state.language = value
            //   i18n.locale = value;
            i18n.loadLanguageAsync(value)
            localStorage.setItem('language', value)
        },
        setLocale(state, value) {
            state.locale = value
            // Vue.set(state, 'language', {...state.language});
        },
        setConnState(state, value) {
            state.conn_state = value
            // Vue.set(state, 'conn_state', value);
        },

        setUpdates(state, value) {
            Vue.set(state, 'refresh', { ...value })
        },

        setOverlayVisible(state, value) {
            state.overlay_is_visible = value
            // Vue.set(state, 'overlay_is_visible', {...state.overlay_is_visible});
        },

        setOverlayLoading(state, value) {
            state.overlay_is_visible = value
            state.overlay_is_loading = value
            // Vue.set(state, 'overlay_is_visible', {...state.overlay_is_visible});
        },

        setAlexVisible(state, value) {
            state.alex_is_visible = value
            // Vue.set(state, 'alex_is_visible', {...state.alex_is_visible});
        },

        setReasonPostpone(state, value) {
            state.reason_postpone = value
            Vue.set(state, 'reason_postpone', value)
        },
        setReasonJustify(state, value) {
            state.reason_justify = value
            Vue.set(state, 'reason_justify', value)
        },
        setIssueStates(state, value) {
            state.issue_states = value
            Vue.set(state, 'issue_states', value)
        },

        setMenuTopVisible(state, value) {
            state.menu_top_is_visible = value
            // Vue.set(state, 'menu_top_is_visible', {...state.menu_top_is_visible});
        },
        setMenuToolsVisible(state, value) {
            state.menu_tools_is_visible = value
            // Vue.set(state, 'menu_tools_is_visible', {...state.menu_tools_is_visible});
        },
        setMenuUserVisible(state, value) {
            state.menu_user_is_visible = value
        },
        setMenuToolsExpanded(state, value) {
            state.menu_tools_is_expanded = value
            // Vue.set(state, 'menu_tools_is_expanded', {...state.menu_tools_is_expanded});
        },
        setSubMenuToolsExpanded(state, value) {
            state.submenu_tools_is_expanded = value
        },

        setMoment: (state, getters, rootState) => {
            state.datetime = moment()

            const tools = state.login.config.tools
            const hasChecklistsTools = tools.includes(1) || tools.includes(2) || tools.includes(4) || tools.includes(7)

            if (hasChecklistsTools && Object.keys(checklist.state.checklist).length > 0) {
                checklist.actions.reloadChecklist()
            }
        },

        setForceUpdate(state, value) {
            state.force_update = value
        },

        setUpdateAvailable(state, value) {
            state.update_available = value
        },
        setCustomHome(state, value) {
            state.custom_home = value
        },

        setAppIcon(state, icon) {
            state.icon = icon
            db_login.setItem('app_icon', icon)
        },

        setExternalTools(state, tools) {
            state.external_tools = tools
            localStorage.setItem('menu_items', JSON.stringify(tools))
        },

        setRefreshProducts(state, value) {
            var refresh = Object.assign({}, state.refresh)
            refresh.products = value
            state.refresh = Object.assign({}, refresh)

            clearTimeout(state.iRefreshProducts)
            if (!value) {
                state.iRefreshProducts = setTimeout(function() {
                    var refresh = state.refresh
                    refresh.products = true
                    state.refresh = refresh
                }, state.iTimeoutProducts)
            }
        },

        setRefreshLibrary(state, value) {
            var refresh = Object.assign({}, state.refresh)
            refresh.library = value
            state.refresh = Object.assign({}, refresh)

            clearTimeout(state.iRefreshLibrary)
            if (!value) {
                state.iRefreshLibrary = setTimeout(function() {
                    var refresh = state.refresh
                    refresh.library = true
                    state.refresh = refresh
                }, state.iTimeoutProducts)
            }
        },

        setFreeLabelling(state, value) {
            state.freeLabelling = value
            localStorage.setItem('freeLabelling', value)
        },

        setRelatedLabels(state, value) {
            state.relatedLabels = value
            localStorage.setItem('relatedLabels', value)
        },

        setDateLimitation(state, value) {
            state.dateLimitation = value
            localStorage.setItem('dateLimitation', value)
        },

        setUuid(state, value) {
            state.uuid = value
            localStorage.setItem('device_uuid', value)
        },

        setBarcodeActive(state, data) {
            state.barcodeActive = data
        },

        setCurrentCamera(state, camera) {
            state.currentCamera = camera
            db_login.setItem('current_camera', camera)
        },
        setShowSplashScreen(state, data) {
            state.showSplashScreen = data
        },
        setMediaQuery(state, data) {
            state.mq = data
        },
        setServerTimeDifference(state, data) {
            state.serverTimeDifference = data
            Vue.set(state, 'serverTimeDifference', data)
            db_login.setItem('serverTimeDifference', data)
        },
        setTimeZoneName(state, timeZoneName) {
            state.timeZoneName = timeZoneName
            Vue.set(state, 'timeZoneName', timeZoneName)
            db_login.setItem('timeZoneName', timeZoneName)
        },
        setSound(state, sound) {
            state.sound = sound
            Vue.set(state, 'sound', sound)
            db_login.setItem('sound', sound)
        },
        setLastEmployeeAction(state, value) {
            state.last_employee_action = value
            db_login.setItem('last_employee_action', value)
        },
        setVersionCurrent(state, value) {
            state.server_version_current = value
            Vue.set(state, 'server_version_current', value)
            db_login.setItem('server_version_current', value)
        },
        setVersionMin(state, value) {
            state.server_version_min = value
            Vue.set(state, 'server_version_min', value)
            db_login.setItem('server_version_min', value)
        },
        setVersionRecommended(state, value) {
            state.server_version_recommended = value
            Vue.set(state, 'server_version_recommended', value)
            db_login.setItem('server_version_recommended', value)
        },
        setChatActive(state, value) {
            state.chat_active = value
        },
        setAutoTimeEnable(state, value) {
            state.android_autotime_enable = value == 1 ? true : false
        },
        setAirPlaneModeEnable(state, value) {
            state.android_airplane_enable = value == 1 ? true : false
        }
    }
})
