import Template from './bar.vue'
import i18n from '@/i18n'

let globalOptions = {
    show: true,
    actions: {
        back: { label: 'menu_aux.action.back', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'left_ffffff.svg' },
        cancel: { label: 'menu_aux.action.cancel', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'close_ffffff.svg' },
        delete: { label: 'menu_aux.action.delete', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'delete_ffffff.svg' },
        edit: { label: 'menu_aux.action.edit', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'edit_ffffff.svg' },
        print: { label: 'menu_aux.action.print', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'printer_ffffff.svg' },
        'print-info': { label: '', callback: function() {}, show: false, customClass: false, opened: true, expand: false, icon: '' },
        create: { label: 'menu_aux.action.create', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'more_ffffff.svg' },
        continue: { label: 'menu_aux.action.continue', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'right_light.svg' },
        save: { label: 'menu_aux.action.save', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'save_ffffff.svg' },
        confirm: { label: 'menu_aux.action.confirm_changes', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'save_ffffff.svg' },
        search: { label: 'menu_aux.action.search', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'search_light.svg' },
        helpcenter: { label: '', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'info_s80.svg' },
        chat: { label: '', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'chat.svg' },
        'create-ticket': { label: '', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'more_ffffff.svg' },
        'send-ticket': { label: '', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'tick_ffffff.svg' },
        'send-ticket-ok': { label: '', callback: function() {}, show: false, customClass: false, opened: false, expand: true, icon: 'tick_ffffff.svg' }
    },
    nav: {
        classes: '',
        items: []
    }
}

let instance

const Init = function(actions, nav, navClass) {
    let Tpl = this.extend(Template)

    if (!instance) instance = new Tpl()

    if (typeof actions !== 'undefined') {
        for (var k in actions) {
            if (actions[k].callback) globalOptions.actions[k].callback = actions[k].callback
            if (actions[k].label) {
                globalOptions.actions[k].label = actions[k].label
            } else {
                globalOptions.actions[k].label = i18n.t(globalOptions.actions[k].label)
            }
            globalOptions.actions[k].show = actions[k].hasOwnProperty('show') ? actions[k].show : true
            globalOptions.actions[k].expand = actions[k].hasOwnProperty('expand') ? actions[k].expand : true
            globalOptions.actions[k].opened = actions[k].hasOwnProperty('opened') ? actions[k].opened : false
            globalOptions.actions[k].customClass = actions[k].hasOwnProperty('customClass') ? actions[k].customClass : false
        }
    }

    if (typeof navClass !== 'undefined') globalOptions.nav.classes = navClass
    if (typeof nav !== 'undefined') globalOptions.nav.items = nav

    /*config = {
    ...globalOptions,
    ...config
  }
  console.log("CONFIG");
  console.log(config);*/

    for (let key in globalOptions) {
        if (globalOptions.hasOwnProperty(key)) {
            instance.$data[key] = globalOptions[key]
        }
    }
}

const Reset = function() {
    let Tpl = this.extend(Template)

    if (!instance) instance = new Tpl()

    for (var k in globalOptions.actions) {
        globalOptions.actions[k].show = false
    }

    globalOptions.nav.classes = ''
    globalOptions.nav.items = []

    for (let key in globalOptions) {
        if (globalOptions.hasOwnProperty(key)) {
            instance.$data[key] = globalOptions[key]
        }
    }
}

const Show = function(actions = {}, nav, navClass) {
    Init.call(this, actions, nav, navClass)

    instance.$data.show = true
    document.body.style.overflow = 'hidden'
    document.body.appendChild(instance.$mount().$el)
}

const AddAction = function(action = '', params = {}) {
    var config = {}
    config[action] = params

    Init.call(this, config)
}

const HideAction = function(action = '') {
    var config = {}
    config[action] = { show: false }

    Init.call(this, config)
}

const AddNav = function(nav = [], navClass) {
    Init.call(this, undefined, nav, navClass)
}

const Hide = function() {
    instance.$data.show = false
}

export default {
    install(Vue) {
        Vue.prototype.$bar = Init.bind(Vue)
        Vue.prototype.$bar.show = Show.bind(Vue)
        Vue.prototype.$bar.addAction = AddAction.bind(Vue)
        Vue.prototype.$bar.hideAction = HideAction.bind(Vue)
        Vue.prototype.$bar.addNav = AddNav.bind(Vue)
        Vue.prototype.$bar.hide = Hide.bind(Vue)
        Vue.prototype.$bar.reset = Reset.bind(Vue)
    }
}
