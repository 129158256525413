<template>
    <div class="numpad-container" :data-msg="disabledMessage" :class="$mq">
        <div class="max-exceeded" v-if="max" v-html="$t('label.max_exceeded', { s: max })" :class="{ hide: !maxExceeded }"></div>
        <div v-if="hasNegative" v-on:click="keyPolarity(false)" class="key positive" :class="classPositive" :style="{ top: posTop, left: positivePosLeft }"></div>
        <div v-if="hasNegative" v-on:click="keyPolarity(true)" class="key negative" :class="classNegative" :style="{ top: posTop, left: negativePosLeft }"></div>
        <div class="num-padtop" :class="[{ 'has-quantity': isQuantity }, { 'error-numpad': error }]">
            <div v-on:click="keyPressOperator('-')" v-if="isQuantity" class="key operator less"></div>
            <input v-bind:type="pinType" v-if="pinType != 'password'" class="pin" disabled v-model="compValue" ref="refInputVal" />

            <div class="password-pin" v-if="pinType == 'password'">
                <div class="number-key" :class="[{ 'with-value': compValue.charAt(0) }, { 'error-pin': error }]">
                    <div class="circle" v-if="compValue.charAt(0)"></div>
                </div>
                <div class="number-key" :class="[{ 'with-value': compValue.charAt(1) }, { 'error-pin': error }]">
                    <div class="circle" v-if="compValue.charAt(1)"></div>
                </div>
                <div class="number-key" :class="[{ 'with-value': compValue.charAt(2) }, { 'error-pin': error }]">
                    <div class="circle" v-if="compValue.charAt(2)"></div>
                </div>
                <div class="number-key" :class="[{ 'with-value': compValue.charAt(3) }, { 'error-pin': error }]">
                    <div class="circle" v-if="compValue.charAt(3)"></div>
                </div>
            </div>
            <div v-on:click="keyPressOperator('+')" v-if="isQuantity" class="key operator more"></div>
        </div>
        <div class="num-pad">
            <div v-on:click="keyPress(7)" class="key numberkey">7</div>
            <div v-on:click="keyPress(8)" class="key numberkey">8</div>
            <div v-on:click="keyPress(9)" class="key numberkey">9</div>
            <div v-on:click="keyPress(4)" class="key numberkey">4</div>
            <div v-on:click="keyPress(5)" class="key numberkey">5</div>
            <div v-on:click="keyPress(6)" class="key numberkey">6</div>
            <div v-on:click="keyPress(1)" class="key numberkey">1</div>
            <div v-on:click="keyPress(2)" class="key numberkey">2</div>
            <div v-on:click="keyPress(3)" class="key numberkey">3</div>
            <div v-on:click="keyBack" class="key keyback"></div>
            <div v-on:click="keyPress(0)" class="key numberkey">0</div>
            <div v-on:click="keyPressDecimal('.')" v-if="isDecimal" class="key decimal">.</div>
            <div v-on:click="sendError" v-if="!isDecimal" class="key disabled"></div>
        </div>
        <span v-if="pinType == 'numeric'" class="no-value" @click="saveAndClose">{{ getString('popup.default.no_value') }}</span>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        error: { type: Boolean, default: false },
        pinType: { type: String, default: 'text' },
        decimal: { type: Boolean, default: false },
        incremental: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        hasNegative: { type: Boolean, default: false },
        max: { type: Number, default: null },
        // min: { type: Number, default: null },
        givenValue: { type: String, default: '' },
        startNumber: { type: Number, default: -1 },
        quantity: { type: Boolean, default: false }
    },
    data() {
        return {
            value: this.givenValue,
            isNegative: this.checkNegative(),
            isDecimal: this.decimal,
            isIncremental: this.incremental,
            maxExceeded: false,
            modified: false
        }
    },
    computed: {
        isDisabled() {
            return this.disabled
        },
        isQuantity() {
            return this.quantity
        },
        disabledMessage() {
            return i18n.t('popup.login.select_user')
        },

        compValue() {
            this.value = this.givenValue.replace('-', '')

            if (this.startNumber != -1 && this.value == '') {
                this.value = String(this.startNumber)
            }
            if (this.max != null) {
                if (this.max < this.value) this.maxExceeded = true
                else this.maxExceeded = false
            }
            // if (this.min != null && !this.isNegative) {
            //   if (this.min > this.value) {
            //     // this.value = String(this.min);
            //     this.value = "";
            //   }
            // // if (this.min < 0 && this.min != null && !this.isNegative) {
            // //   if (this.min >= this.value) {
            // //     this.value = String(this.min);
            // //   }
            //
            // } else if (this.min != null && this.isNegative) {
            //   if (this.min > String('-' + this.value)) this.value = String(this.min).replace('-', '')
            // }
            return String(this.value)
        },

        isPositive() {
            return !this.isNegative
        },

        posTop() {
            return '20px'
        },
        positivePosLeft() {
            if (this.$parent.type == 'numeric') {
                if (this.classPositive == 'selected') {
                    return '37px'
                } else {
                    return '-3px'
                }
            } else {
                if (this.classPositive == 'selected') {
                    return '0px'
                } else {
                    return '-40px'
                }
            }
        },
        negativePosLeft() {
            if (this.$parent.type == 'numeric') {
                if (this.classNegative == 'selected') {
                    return '37px'
                } else {
                    return '-3px'
                }
            } else {
                if (this.classNegative == 'selected') {
                    return '0px'
                } else {
                    return '-40px'
                }
            }
        },
        classPositive() {
            if (this.isPositive) return 'selected'
        },
        classNegative() {
            if (this.isNegative) return 'selected'
        }
    },
    methods: {
        keyPress(val) {
            // Validación para no poner más de 4 números en password
            if (this.pinType == 'password' && this.value.length == 4) {
            } else {
                if (!this.isDisabled) {
                    if ((this.value == '0' && this.pinType != 'password') || (this.value == this.startNumber && this.startNumber != -1 && !this.modified) || (this.givenValue == '' && this.startNumber != -1)) {
                        this.value = String(val)
                    } else {
                        this.value = this.value + val
                    }

                    if (!this.modified) this.modified = true
                    this.sendValue()
                }
            }
            if (this.value.length == 4) {
                this.$emit('autoEnter')
            }
        },
        keyPressOperator(val) {
            if (!this.isDisabled) {
                switch (val) {
                    case '+':
                        this.value = String(Number(this.value) + 1)
                        break
                    case '-':
                        if (this.value > 0) {
                            this.value = String(Number(this.value) - 1)
                        }
                        break
                }
                this.sendValue()
            }
        },
        keyPressDecimal(val) {
            if (!this.isDisabled) {
                if (this.value.indexOf(val) == -1) {
                    this.value = this.value + val
                }
                this.sendValue()
            }
        },
        keyBack() {
            if (!this.isDisabled) {
                this.value = String(this.value).slice(0, -1)
                this.sendValue()
            }
        },
        keyPolarity(bool) {
            if (!this.isDisabled) {
                this.isNegative = bool
                this.sendValue()
            }
        },

        saveAndClose() {
            this.$emit('sendValue', '-')
            this.$emit('saveAndClose')
        },

        sendValue() {
            if (this.max != null) {
                if (this.max < this.value) this.maxExceeded = true
                else this.maxExceeded = false
            }
            if (!isNaN(this.value)) {
                if (this.isNegative) {
                    this.$emit('sendValue', '-' + this.value)
                } else {
                    if (this.startNumber != -1) {
                        this.value = Number(this.value) < this.startNumber ? String(0) : this.value
                    }
                    this.$emit('sendValue', this.value)
                }
            } else {
                // console.log('>:(')
            }
        },
        sendError(e) {
            this.$emit('sendError', 'hola')
        },

        checkNegative: function() {
            return this.givenValue.indexOf('-') != -1
        },

        getString(str) {
            return i18n.t(str)
        },
        getStringPlural(str, pos) {
            return i18n.tc(str, pos)
        }
    },
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.numpad-container {
    $key-size: rem(60px);
    $topkey-size: rem(60px);

    position: relative;

    .max-exceeded {
        @include border-radius(3px);
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include font-size(s);
        line-height: 1.2;
        text-align: center;
        color: #fff;
        background-color: $error;
        width: calc((#{$key-size} * 3) + (#{rem(12px)} * 3));
        height: rem(40px);
        margin: 0 auto;
        padding: 10px;
        overflow: hidden;
        transition: 0.2s;

        &.hide {
            height: 0px;
            padding: 0;
        }
    }

    .num-padtop {
        @include display-flex();
        @include justify-content();
        @include align-items();
        width: calc((#{$key-size} * 3) + (#{rem(12px)} * 3));
        height: $topkey-size;
        margin: 15px auto;

        .pin {
            @include border-radius(4px);
            @include font-size(xl);
            line-height: $topkey-size;
            text-align: center;
            font-family: $text;
            color: $main-text;
            height: $topkey-size;
            width: 100%;
            border: none;
            padding: 0;
            display: inline-block;

            &:hover {
                background-color: #fff;
                cursor: not-allowed;
            }
            &.error-numpad {
                margin-top: 5px;
            }
        }

        &.has-quantity {
            .pin {
                width: calc(100% - #{$topkey-size * 2} - #{rem(12px) * 2});
            }
        }
        .password-pin {
            @include display-flex();

            .number-key {
                @include border-radius(100%);
                border: 2px solid $main-t80;
                margin-right: 9px;
                background-color: $neutro-t20;
                width: 60px;
                height: 60px;

                .circle {
                    border-radius: 50%;
                    background-color: $main;
                    width: 15px;
                    height: 15px;
                    opacity: 1;
                    margin: 0 auto;
                    animation: scaleIn 0.1s cubic-bezier(0.36, 0.11, 0.89, 0.32);
                }

                @keyframes scaleIn {
                    from {
                        transform: scale(1.5, 1.5);
                        opacity: 0.5;
                    }
                    to {
                        transform: scale(0.5, 0.5);
                        opacity: 0;
                    }
                }
                &.with-value {
                    @include display-flex();
                    @include align-items(center);
                    animation: 0.4s ease-in-out forwards;
                    border: 2px solid $main;
                }

                &.error-pin {
                    background-color: $error-t70;
                    border: 2px solid $error-t50;
                    animation: shake 0.5s;

                    @keyframes shake {
                        0% {
                            transform: translate(1px, 1px) rotate(0deg);
                        }
                        10% {
                            transform: translate(-1px, -2px) rotate(-1deg);
                        }
                        20% {
                            transform: translate(-3px, 0px) rotate(1deg);
                        }
                        30% {
                            transform: translate(3px, 2px) rotate(0deg);
                        }
                        40% {
                            transform: translate(1px, -1px) rotate(1deg);
                        }
                        50% {
                            transform: translate(-1px, 2px) rotate(-1deg);
                        }
                        60% {
                            transform: translate(-3px, 1px) rotate(0deg);
                        }
                        70% {
                            transform: translate(3px, 1px) rotate(-1deg);
                        }
                        80% {
                            transform: translate(-1px, -1px) rotate(1deg);
                        }
                        90% {
                            transform: translate(1px, 2px) rotate(0deg);
                        }
                        100% {
                            transform: translate(1px, -2px) rotate(-1deg);
                        }
                    }
                }
            }
        }
    }

    .num-pad {
        @include display-flex();
        @include flex-wrap($w: wrap);
        @include align-items();
        @include justify-content();
        width: calc((#{$key-size} * 3) + (#{rem(12px)} * 3));
        margin: 0 auto;
    }

    .key {
        @include interaction();
        @include display-flex();
        @include align-items();
        @include justify-content();
        @include border-radius(4px);
        @include font-size(l);
        font-family: $text-bold;
        color: $main-s20;
        width: $key-size;
        height: $key-size;
        margin: rem(6px);
        background-color: $inactive-t80;

        // @include bgHover($main-t90);
        &:active {
            cursor: pointer;
            animation: numpadClick 0.2s;
            @include bgHover($main-t80);
        }

        @keyframes numpadClick {
            0%,
            100% {
                transform: scale(1, 1);
            }
            25% {
                transform: scale(1.1, 1.1);
            }
            50% {
                transform: scale(1, 1);
            }
            75% {
                transform: scale(1.05, 1.05);
            }
        }

        &.keyback {
            @include background($image: img('undo_primary.svg'), $size: 25px);
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 472.615 472.615' style='enable-background:new 0 0 472.615 472.615;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cg%3E%3Cpath d='M139.569,96.738L0,236.307l139.569,139.57h333.046v-139.57V96.738H139.569z M362.673,305.637l-13.923,13.922 l-69.329-69.332l-69.33,69.332l-13.922-13.922l69.329-69.332l-69.329-69.332l13.922-13.923l69.33,69.332l69.329-69.332 l13.923,13.923l-69.33,69.332L362.673,305.637z' fill='%23475b96'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
        }
        &.disabled {
            @include opacity(0);
        }

        &.negative,
        &.positive {
            @include border-radius(100%);
            width: rem(40px);
            height: rem(40px);
            display: inline-block;
            position: absolute;
            padding: 6px;
            background-color: $main-t90;
            background-clip: content-box;
            transition-duration: 0.2s;

            &.selected {
                background-size: 16px;
                padding: 3px;
                background-color: transparent;
            }
            &.selected ~ .pin {
                padding-left: rem(40px);
            }
        }
        &.negative {
            @include background($size: 12px, $image: img('less_primary_dark.svg'));
        }
        &.positive {
            @include background($size: 12px, $image: img('more_primary_dark.svg'));
        }

        &.operator {
            @include background($size: 15px, $color: $neutro);
            width: $topkey-size;
            height: $topkey-size;
            display: inline-block;
            margin: 0 6px;

            @include bgHover($neutro);
        }
        &.less {
            background-image: img('less_primary.svg');
        }
        &.more {
            background-image: img('more_primary.svg');
        }
    }

    .no-value {
        @include interaction();
        @include border-radius(3px);
        @include font-size(m);
        @include display-flex();
        @include align-items();
        @include justify-content();
        font-family: $text;
        color: $main-dark;
        height: rem(40px);
        // display: block;
        width: rem(165px);
        margin: rem(6px) auto;
        text-align: left;
    }

    @include small {
        $m-key-size: rem(65px);
        $m-topkey-size: rem(65px);

        .max-exceeded {
            width: calc((#{$m-key-size} * 3) + (#{rem(12px)} * 3));
        }
        .num-padtop {
            width: calc((#{$m-key-size} * 3) + (#{rem(12px)} * 3));
            height: $m-topkey-size;
            margin: 7px auto;
            .pin {
                line-height: $m-topkey-size;
                height: $m-topkey-size;
            }
            &.has-quantity {
                .pin {
                    width: calc(100% - #{$m-topkey-size * 2} - #{rem(12px) * 2});
                }
            }
            .password-pin {
                @include display-flex();

                .number-key {
                    width: 55px;
                    height: 55px;
                    .circle {
                        border-radius: 50%;
                        background-color: $main;
                        width: 12px;
                        height: 12px;
                        opacity: 1;
                        margin: 0 auto;
                        animation: scaleIn 0.1s cubic-bezier(0.36, 0.11, 0.89, 0.32);
                    }

                    @keyframes scaleIn {
                        from {
                            transform: scale(1.5, 1.5);
                            opacity: 0.5;
                        }
                        to {
                            transform: scale(0.5, 0.5);
                            opacity: 0;
                        }
                    }
                    &.error-pin {
                        background-color: $error-t70;
                    }
                    &.with-value {
                        @include display-flex();
                        @include align-items(center);
                        animation: 0.4s ease-in-out forwards;
                        border: 2px solid $main;
                    }
                }
            }
            &.error-numpad {
                margin-top: 5px;
            }
        }
        .num-pad {
            width: calc((#{$m-key-size} * 3) + (#{rem(12px)} * 3));
        }
        .key {
            // width: $m-key-size;
            // height: $m-key-size;

            &.operator {
                width: $m-topkey-size;
                height: $m-topkey-size;
            }
        }
    }
    &.desktop {
        .key {
            @include bgHover($main-t90);
        }
    }
}
@media all and (orientation: portrait) {
    .numpad-content {
        width: 100%;
        height: auto;
        .num-padtop {
            width: auto;
            height: auto;

            .password-pin {
                @include justify-content();
                width: auto;
                height: auto;

                .number-key {
                    @include align-self(center);
                    width: 80px;
                    height: 80px;

                    .circle {
                        border-radius: 50%;
                        background-color: $main;
                        width: 20px;
                        height: 20px;
                        opacity: 1;
                        margin: 0 auto;
                        animation: scaleIn 0.1s cubic-bezier(0.36, 0.11, 0.89, 0.32);
                    }

                    @keyframes scaleIn {
                        from {
                            transform: scale(1.5, 1.5);
                            opacity: 0.5;
                        }
                        to {
                            transform: scale(0.5, 0.5);
                            opacity: 0;
                        }
                    }

                    &.error-pin {
                        animation: shake 0.5s;

                        @keyframes shake {
                            0% {
                                transform: translate(1px, 1px) rotate(0deg);
                            }
                            10% {
                                transform: translate(-1px, -2px) rotate(-1deg);
                            }
                            20% {
                                transform: translate(-3px, 0px) rotate(1deg);
                            }
                            30% {
                                transform: translate(3px, 2px) rotate(0deg);
                            }
                            40% {
                                transform: translate(1px, -1px) rotate(1deg);
                            }
                            50% {
                                transform: translate(-1px, 2px) rotate(-1deg);
                            }
                            60% {
                                transform: translate(-3px, 1px) rotate(0deg);
                            }
                            70% {
                                transform: translate(3px, 1px) rotate(-1deg);
                            }
                            80% {
                                transform: translate(-1px, -1px) rotate(1deg);
                            }
                            90% {
                                transform: translate(1px, 2px) rotate(0deg);
                            }
                            100% {
                                transform: translate(1px, -2px) rotate(-1deg);
                            }
                        }
                    }
                    &.with-value {
                        @include display-flex();
                        @include align-items(center);
                        animation: 0.4s ease-in-out forwards;
                        border: 2px solid $main;
                    }
                    // &.has-value {
                    //     background-size: 16px;
                    // }
                }
            }
        }

        .num-pad {
            width: calc(270px + 7.5px * 6);
            height: auto;
            overflow-x: hidden;

            .key {
                width: 90px;
                height: 90px;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.numpad-container.portrait {
    @include display-flex();
    @include align-items();
    @include justify-content();
    @include flex-direction(column);
    // @include flex-wrap(wrap);
    max-width: calc(100%);
    overflow: auto;
    height: 100%;
    position: relative;
    width: 100%;

    .num-padtop {
        @include align-items(flex-start);
        max-width: 100%;
        width: 100%;
        // height: calc((90vw / 4) - 20px);
        max-height: 100px;
        max-height: unset;
        height: clamp(calc((90vh / 4) - 40px), 20%, 200px);

        .password-pin {
            @include display-inline-flex();
            @include align-items();
            @include justify-content();
            width: 100%;
            height: calc((100vw / 4) - 20px);
            max-height: 100px;

            .number-key {
                width: calc((90vw / 4) - 20px);
                height: calc((90vw / 4) - 20px);
                max-width: 75px;
                max-height: 75px;
                min-width: 55px;
                opacity: 1;

                .circle {
                    border-radius: 50%;
                    background-color: $main;
                    width: 18px;
                    height: 18px;
                    opacity: 1;
                    margin: 0 auto;
                    animation: scaleIn 0.2s cubic-bezier(0.36, 0.11, 0.89, 0.32);
                }

                @keyframes scaleIn {
                    from {
                        transform: scale(1.5, 1.5);
                        opacity: 0.5;
                    }
                    to {
                        transform: scale(0.5, 0.5);
                        opacity: 0;
                    }
                }

                &.with-value {
                    animation: 0.4s ease-in-out forwards;
                    border: 2px solid $main;
                }
            }
        }
    }

    .num-pad {
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        width: 100%;
        height: calc(100% - calc((100vh / 4) - 20px) - 14px);
        position: relative;
        max-width: 300px;
        max-height: 375px;
        min-height: 225px;

        .key {
            @include font-size(sm);
            flex-shrink: 0;
            flex: 0 0 auto;
            height: 20%;
            width: calc((100% / 3) - 10px);
            padding: 0;
            line-height: 20%;
            // height: calc((100% / 4) - 10px);
            margin: 0;
        }
    }
}
</style>
