<template>
    <div class="resource-container" :class="[$mq]">
        <div class="header">
            <div class="meta" v-if="isOnline">
                <div class="name">{{ resource.name }}</div>
                <div class="updated">
                    {{ translation('library.last_update') }} ‎<span class="date"> {{ (resource.last_update * 1000) | moment('DD/MM/YYYY') }}</span>
                </div>
            </div>
            <div class="close" @click="close"></div>
        </div>
        <div v-if="!isOnline" class="content small">
            <div class="label">
                {{ translation('library.no_connection') }}
            </div>
        </div>
        <div v-else class="content" :class="{ small: resource.type == 3 && !resource.file.includes('.pdf') }">
            <div v-if="resource.type == 1" class="image">
                <template v-if="files.length == 1">
                    <!-- <img class="picture" :src="files"> -->
                    <vue-picture-swipe class="picture" :items="files" :options="options"></vue-picture-swipe>
                </template>
                <template v-else>
                    <div class="gallery">
                        <!-- <template v-for="image in files"> -->
                        <vue-picture-swipe class="picture-swipe" :items="files" :options="options"></vue-picture-swipe>
                        <!-- </template> -->
                        <!-- <img v-for="image in files" class="picture" :src="image"> -->
                    </div>
                </template>
            </div>

            <div v-else-if="resource.type == 2" class="video">
                <iframe v-if="resource.file.includes('youtube')" class="player" rel="0" :src="files + '?rel=0&modestbranding=1&autohide=1&showinfo=0'" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen iv_load_policy="3"></iframe>
                <iframe v-else-if="resource.file.includes('vimeo')" class="player" :src="files + '?title=0&byline=0&portrait=0&sidedock=0'" frameborder="0" allowfullscreen></iframe>
                <video class="player" :src="resource.file" v-else controls>
                    <!-- <source :src="'http://techslides.com/demos/sample-videos/small.mp4'"> -->
                </video>
            </div>

            <template v-else-if="resource.file.includes('.pdf') && resource.type == 3">
                <div class="preview-pdf">
                    <div class="pdf-pages hide-scrollbar">
                        <pdf :src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i" :scale.sync="scale" style="width:100%;margin:20px auto;">
                            <template slot="loading">{{ translation('tooltip.load') }}</template>
                        </pdf>
                    </div>
                </div>
            </template>
            <template v-else-if="resource.type == 3 && !resource.file.includes('.pdf')">
                <div class="file-container">
                    <div class="download-box">
                        <!-- <div class="icon"></div>
                        <div class="text-download">{{ resource.file.split('/')[resource.file.split('/').length - 1].slice(14) }}</div>
                        <div class="link-download"> -->
                        <a :href="resource.file" download target="_blank">{{ translation('library.download') }}</a>
                        <!-- </div> -->
                    </div>
                </div>
            </template>
            <p v-else class="empty">{{ empty_text }}</p>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import i18n from '@/i18n'
import pdf from 'pdfvuer'
import VuePictureSwipe from 'vue-picture-swipe'
Vue.component('vue-picture-swipe', VuePictureSwipe)

export default {
    props: {
        resource: { type: [Boolean, Object], default: false }
    },
    components: { pdf },

    data() {
        return {
            empty_text: i18n.t('pdfjs.empty'),
            page: 1,
            numPages: 0,
            pdfdata: undefined,
            errors: [],
            scale: 'page-width',
            options: {}
        }
    },
    computed: {
        files() {
            if (this.resource) {
                // IMAGE
                if (this.resource.type == 1) {
                    var images = this.resource.file.split('|')
                    var result = []

                    for (var index in images) {
                        result.push({ src: images[index], thumbnail: images[index], w: 0, h: 0 })
                    }
                    return result
                }
                // VIDEO
                else if (this.resource.type == 2) {
                    if (this.resource.file.includes('youtube')) {
                        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
                        const match = this.resource.file.match(regExp)
                        var videoID = match && match[2].length === 11 ? match[2] : null

                        return 'https://www.youtube-nocookie.com/embed/' + videoID
                    } else if (this.resource.file.includes('vimeo')) {
                        var regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/
                        var match = this.resource.file.match(regExp)

                        return 'https://player.vimeo.com/video/' + match[3]
                    }
                    return ''
                }
                // DOCUMENT OR OTHERS
                else {
                    return this.resource.file
                }
            }
            return false
        },

        isOnline() {
            return fnCheckConnection()
        }
    },
    methods: {
        getPdf() {
            var self = this
            self.pdfdata = pdf.createLoadingTask(self.resource.file)
            self.pdfdata.then(pdf => {
                self.numPages = pdf.numPages
                window.onscroll = function() {
                    changePage()
                    stickyNav()
                }

                // Get the offset position of the navbar
                /*var sticky = $('#buttons')[0].offsetTop

                // Add the sticky class to the self.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
                function stickyNav() {
                  if (window.pageYOffset >= sticky) {
                    $('#buttons')[0].classList.remove("hidden")
                  } else {
                    $('#buttons')[0].classList.add("hidden")
                  }
                }*/

                function changePage() {
                    var i = 1,
                        count = Number(pdf.numPages)
                    do {
                        if (window.pageYOffset >= self.findPos(document.getElementById(i)) && window.pageYOffset <= self.findPos(document.getElementById(i + 1))) {
                            self.page = i
                        }
                        i++
                    } while (i < count)
                    if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
                        self.page = i
                    }
                }
            })
        },

        translation(translation) {
            return i18n.t(translation)
        },

        close() {
            this.$emit('close')
        }
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    created() {},
    mounted() {
        if (this.resource && this.resource.type == 3) {
            this.getPdf()
        }
    },

    updated() {
        if (['portrait'].includes(this.$mq)) {
            this.scale = 0.4
        } else this.scale = 1
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.resource-container {
    @include display-flex();
    @include flex-direction(column);
    width: 100%;
    height: fit-content;
    max-width: 100vw;
    overflow-x: hidden;

    .header {
        @include display-flex();
        @include flex-direction(row);
        @include justify-content(flex-end);
        width: 100%;
        height: 50px;

        .meta {
            @include display-flex();
            @include flex-direction(column);
            width: calc(100% - 45px);
            padding: 0 10px 0 0;

            .name {
                @include font-size(sm);
                font-family: $text-bold;
            }
            .updated,
            .date {
                @include font-size(s);
                font-family: $text;
                color: $neutro-s60;
                .date {
                    font-family: $text-bold;
                }
            }
        }

        .close {
            @include display-flex();
            @include background($image: img('close_dark.svg'), $color: $default-bg-color, $size: 20px);
            width: 45px;
            height: 45px;
            cursor: pointer;
        }
    }

    .content {
        @include display-flex();
        @include align-items(center);
        @include justify-content(center);
        min-height: 50vh;
        // height: calc(100vh - 50px - 100px);
        min-width: 50vw;
        overflow: auto;
        overflow-x: hidden;
        // @include background($image: img('loader_main.svg'), $size: 50px);

        &.small {
            height: calc(150px);
            background-image: unset;

            .label {
                // margin-top: 50px;
                @include font-size(sm);
                text-align: center;
                width: 90%;
                margin: 0 auto;
                color: $neutro-s80;
                font-family: $text;
            }
        }

        & > div {
            background-color: #fff;
        }

        .video {
            top: calc((100vh - (56.25vw / 2)) / 2 - 35px);
            left: calc((100vw / 2) / 2);
            width: calc(100vw / 2);
            height: calc(56.25vw / 2);
            margin: 0 auto;
            overflow: hidden;

            .player {
                border-radius: 6px 6px 0 0;
                width: 100%;
                height: 100%;
                width: -webkit-fill-available;
                margin: 0 auto;
                border: 0;
                z-index: 9999999999;

                &:focus {
                    border: 0;
                    outline: none;
                }
            }
        }

        .image {
            .picture,
            .picture-swipe {
                img {
                    width: 100%;
                }

                figure {
                    display: block !important;
                }
            }
        }
        .file-container {
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            height: 100%;
            .download-box {
                @include display-flex();
                @include align-items(center);
                @include justify-content(center);
                height: 100%;

                a {
                    @include border-radius(4px);
                    @include font-size(m);
                    @include background($image: img('save_ffffff.svg'), $position: left 15px center, $size: 25px);
                    padding: 15px 25px;
                    @include display-flex();
                    font-family: $text-bold;
                    padding-left: 50px;
                    width: fit-content;
                    background-color: $done;
                    color: #fff;
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.resource-container.portrait {
    height: 100%;
    min-height: 100%;
    .content {
        height: auto;
        min-height: calc(100vh - 50px - 100px);
        min-width: 100%;

        &.small {
            height: calc(150px);
            min-height: unset;

            .label {
                // margin-top: 50px;
            }
        }

        .video {
            top: calc((100vh - (56.25vw)) / 2 - 35px);
            width: calc(100vw - 30px);
            height: calc(56.25vw);
            margin: 0 auto;
        }
    }
}
</style>
