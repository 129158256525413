// Import
import i18n from '@/i18n'
import Vue from 'vue'
import store from '@/store'
import { Api } from '@/core/api.js'

import { FORMAT } from '@/constants'

import localforage from 'localforage'
import { closeSingleQuote } from '@tiptap/extension-typography'
const api = new Api()

var db_templates = localforage.createInstance({
    name: 'alexdb',
    storeName: 'templates'
})

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        stats: {
            alerts: {},
            activity: {}
        },
        items: {},
        categories: {},
        current: false,
        templates: {},
        headers: {},
        number_items: 0,
        number_page: 1,
        type: 3,
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: false,
            has_state: false,
            has_timing: false,
            has_mandatory: true,
            is_autosave: false,
            can_create: true,
            item_search: false,
            has_calendar: false,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: false,
                state: false,
                inPast: true,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK, FORMAT.RESOURCE],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                can_pospone: false,
                rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: false,
                has_periodicity: false,
                has_score: false
            }
        }
    }
}

// initial state
const state = getDefaultState()

const loadLocalForageInformation = async () => {
    var registers_page = await db_templates.getItem('registers_page')
    if (registers_page) state.number_page = registers_page

    var registers_items = await db_templates.getItem('registers_items')
    if (registers_items) state.number_items = registers_items
}

loadLocalForageInformation()

// getters
const getters = {
    // Commons
    getAll: (state, getters, rootState) => {
        return state.items
    },
    getById: (state, getters, rootState) => id => {
        return id ? state.items[id] : false
    },
    getConfig: (state, getters, rootState) => {
        return { ...state.config }
    },
    getTemplates: (state, getters, rootState) => {
        return state.templates
    },

    getTemplateById: (state, getters, rootState) => id => {
        return state.templates[id]
    },

    getSubtasks: (state, getters, rootState) => (check, task) => {
        let tasks = check ? state.items[check].items : {}
        let subtask = []

        Object.keys(tasks).map(function(item_id, i) {
            var item = tasks[item_id]
            if (item.parent_id == task) {
                subtask.push(item.item_id)
            }
        })

        return subtask
    },

    getAllStats: (state, getters, rootState) => {
        return state.stats
    },
    getStats: (state, getters, rootState) => wich => {
        return state.stats[wich]
    },
    getNumberItems: (state, getters) => {
        return state.number_items
    },
    getNumberPage: (state, getters) => {
        return state.number_page
    },

    // Registers
    getItems: (state, getters, rootState) => {
        return state.items
    },
    getHeaders: (state, getters, rootState) => {
        return state.headers
    },
    getCategories: (state, getters, rootState) => id => {
        if (id) {
            return state.categories[id]
        }
        return state.categories
    },
    getTemplatesSearched: (state, getters, rootState) => search => {
        var searchValues = search.split(' ')
        var results = []

        for (var templatesIndex in state.templates) {
            var template = state.templates[templatesIndex]
            var templateName =
                template.name != null
                    ? template.name
                          .trim()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                    : ''

            template.searchscore = 0

            for (var searchIndex in searchValues) {
                var searchWord = searchValues[searchIndex]
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()

                template.searchscore = templateName.indexOf(searchWord) > -1 ? (template.searchscore += 1 + searchWord.length * 0.1) : template.searchscore
            }

            if (template.searchscore > 0) {
                console.log('entra')
                results.push(template)
            }
        }

        results.sort((a, b) => (parseInt(a.searchscore) < parseInt(b.searchscore) ? 1 : -1))

        return results
    }
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    resetState({ commit }) {
        commit('resetState')
    },

    // Common
    addIssue(context, params) {
        context.dispatch('calcScore', params.checklist_ref)

        let check = context.getters.getById(params.checklist_ref)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        var oData = { template: params.tpl_id }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        oData.resume = JSON.stringify(resume)

        return api.post('checklist/add', oData).then(function(response) {
            var items = { ...state.items }
            var reference = {
                checklist: Object.values(response.data)[0].id,
                type: Object.values(response.data)[0].type,
                status: Object.values(response.data)[0].status
            }
            var item = {
                ...items[params.checklist_ref].items[params.item_ref]
            }
            item.reference = reference
            items[params.checklist_ref].items[params.item_ref] = item
            Vue.set(state, 'items', items)
            return response.data
        })
    },
    comment(context, params) {
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        var sended = {
            checklist: params.check,
            task: params.answer,
            message: params.message,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume)
        }

        if (typeof params.category !== 'undefined') sended.category = params.category

        return api.post('checklist/task/comment', sended).then(function(response) {
            var returned = {
                check: params.check,
                answer: params.answer,
                message: response.data.message
            }
            if (typeof response.data.image !== 'undefined') {
                returned.image = response.data.image
            }

            return context.commit('setComment', returned)
        })
    },

    complete(context, params) {
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/complete', {
                checklist: params.check,
                employee: params.emp,
                complete_date: params.date,
                values: params.values,
                resume: JSON.stringify(resume)
                // type: context.state.type,
            })
            .then(function(response) {
                var data = Object.values(response.data)[0]
                // params.values = JSON.parse(params.values);
                // Object.keys(params.values).map(function (id, i) {
                Object.keys(data.items).map(function(id, i) {
                    var val = data.items[id]

                    if (state.config.item.itemsImage.includes(val.type)) {
                        val.value = val.value ? val.value.split(',') : val.value
                    }

                    context.commit('setAnswer', {
                        check: data.id,
                        answer: val.item_id,
                        emp: val.employee.id,
                        date: data.complete_date,
                        value: val.value
                    })
                })
                return response
            })
    },

    loadById(context, params) {
        return api.get('checklist/id/' + params.id, {}).then(function(response) {
            context.dispatch('format', { data: response.data })
            context.dispatch('calcScore', params.id)
        })
    },

    loadCategories(context) {
        return api.get('checklist/3/categories').then(function(response) {
            var categories = response.data
            if (Object.values(response.data).length > 0) {
                categories['all'] = {
                    id: 'all',
                    name: i18n.t('registers.all_registers'),
                    highlight: true,
                    order: -1,
                    color: '#374673',
                    type: 3
                }
            }

            context.commit('setCategories', categories)
        })
    },

    loadTemplates(context, params) {
        var templates = []
        var count = 0

        if (fnCheckConnection()) {
            return api.get('checklist/templates/' + state.type, {}).then(function(response) {
                context.commit('setTemplates', { data: response.data })

                _.forEach(_.values(response.data), function(value, key) {
                    db_templates.setItem(value.id, value)
                })

                context.rootState.refresh.templates = false
            })
        } else {
            db_templates
                .iterate(function(value, key, iterationNumber) {
                    if (value.type == state.type) {
                        templates.push(value)
                        count++
                    }
                })
                .then(function() {
                    if (count > 0) {
                        context.commit('setTemplates', { data: templates })
                    }
                })
        }
    },

    reload(context, params) {
        context.dispatch('format', { data: checklist })
        context.dispatch('calcScore')
    },

    // Common but may change in other tools
    answer(context, params) {
        if (fnCheckConnection()) {
            let value = params.value
            let subtask = context.getters.getSubtasks(params.check, params.answer)

            // In case of image or signature type, JSON-parse the value
            if (state.config.item.itemsImage.includes(params.answer_type)) {
                value = JSON.stringify(value)
            }

            // answer
            context.commit('setAnswer', {
                check: params.check,
                answer: params.answer,
                emp: params.emp,
                date: params.date,
                value: params.value
            })

            // calculate score
            context.dispatch('calcScore', params.check)

            if (subtask.length > 0) {
                Object.keys(subtask).map(function(item_id, i) {
                    var item = subtask[item_id]
                    context.commit('setReset', { check: params.check, answer: item })
                })
            }

            let check = context.getters.getById(params.check)
            let total = check.total
            let answered = check.completed
            let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]
                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            return api
                .post('checklist/task/answer', {
                    checklist: params.check,
                    task: params.answer,
                    employee: params.emp,
                    complete_date: params.date,
                    value: value,
                    total: total,
                    answered: answered,
                    score: score,
                    resume: JSON.stringify(resume)
                })
                .then(function(response) {
                    if (!response.status) {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                        context.dispatch('calcScore', params.check)
                    }
                    return response
                })
        } else {
            context.commit('setReset', { check: params.check, answer: params.answer })
            return { status: false }
        }
    },

    apply(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date == 0 ? params.date : false,
            apply: params.apply
        })

        context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function(item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', {
                    check: params.check,
                    answer: item
                })
            })
        }
    },

    applyrecurrent(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        // apply
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.apply == 0 ? params.date : false,
            apply: params.apply
        })

        // calculate score
        context.dispatch('calcScore', params.check)

        // reset child items
        if (subtask.length > 0) {
            Object.keys(subtask).map(function(item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        // calculate score

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/applyrecurrent', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                apply: params.apply,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function(response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer })
                }
            })
    },

    reset(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        context.commit('setReset', {
            check: params.check,
            answer: params.answer
        })
        context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function(item_id, i) {
                var item = subtask[item_id]
                log('subtask ')
                log(item)
                context.commit('setReset', {
                    check: params.check,
                    answer: item
                })
            })
        }
    },
    add(context, params) {
        var type = context.state.type
        var oData = { template: params.tpl_id }
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        return api.post('checklist/add', oData).then(function(response) {
            context.dispatch('format', { data: response.data })
            context.dispatch('calcScore', Object.keys(response.data)[0])
            context.commit('setNumberPage', 1)
            return response.data[Object.keys(response.data)[0]]
        })
    },

    delete(context, params) {
        var oData = {
            checklist: params.check,
            employee: params.emp,
            status: -1
        }

        if (params.ref_item) {
            oData['ref_item'] = params.ref_item
        }

        return api.post('checklist/update', oData).then(function(response) {
            return response
        })
    },

    loadStats(context, params) {
        context.commit('resetStats', {})
        var currentDate = moment(params.date)

        // SET ALERTS --------------------------------------------------------------

        // SET ACTIVITY ------------------------------------------------------------

        // -- Created Registers
        context
            .dispatch('loadFromDate', {
                date: currentDate.format('YYYY-MM-DD')
            })
            .then(function(response) {
                let result = response.data ? Object.keys(response.data).length : 0
                if (response.data && result > 0) {
                    context.commit('setStats', {
                        where: 'activity',
                        key_name: 'created',
                        values: {
                            name: i18n.tc('home.activity.registers_generated', result),
                            value: result
                        }
                    })
                }
            })
    },

    format({ state, context, commit, dispatch, rootState }, params = {}) {
        // var append = typeof params.append != "undefined" ? params.append : false;
        var aItems = {}
        // if (append) {
        //   var aItems = state.items;
        // }
        if (typeof params.data !== 'undefined') {
            Object.keys(params.data).map(function(item_id, i) {
                var list = params.data[item_id]

                // Timezone set
                list.business_date = list.business_date ? (moment.isMoment(list.business_date) ? list.business_date : moment(list.business_date * 1000)) : false
                list.created_date = list.created_date ? (moment.isMoment(list.created_date) ? list.created_date : moment(list.created_date * 1000)) : false
                list.complete_date = list.complete_date ? (moment.isMoment(list.complete_date) ? list.complete_date : moment(list.complete_date * 1000)) : false

                list.def = state.config

                // Loop for all items
                Object.keys(list.items).map(function(item_id, i) {
                    var item = list.items[item_id]

                    // Timezone set
                    item.complete_date = item.complete_date ? (moment.isMoment(item.complete_date) ? item.complete_date : moment(item.complete_date * 1000)) : false
                    if (item.review) {
                        item.review['complete_date'] = item.review.complete_date ? (moment.isMoment(item.review.complete_date) ? item.review.complete_date : moment(item.review.complete_date * 1000)) : false
                    }

                    // If type image - Split value to array
                    if (state.config.item.itemsImage.includes(item.type)) {
                        item.value = item.value ? item.value.split(',') : item.value
                    }

                    item.complete_date = item.complete_date
                    item.available = false

                    list.items[item_id] = { ...item }
                    Vue.set(list.items, item_id, { ...item })
                })

                aItems[list.id] = list
            })
        }

        commit('setFormat', { items: aItems })
    },
    calcScore({ state, context, commit, dispatch, rootState }, id) {
        var aItems = state.items

        Object.keys(aItems).map(function(item_id, i) {
            var list = aItems[item_id]
            if (typeof id == 'undefined' || item_id == id) {
                var past_score = list.score ? list.score : 0

                list.def = state.config
                list.uncompleted = 0
                list.completed = 0
                list.unanswered = 0
                list.total = 0
                list.score = 0
                list.justify_id = list.justify_id ? list.justify_id : list.justify_msg ? '0' : false

                // Loop for all items
                Object.keys(list.items).map(function(item_id, i) {
                    var item = list.items[item_id]
                    item.is_visible = true
                    item.alerts = false

                    // Subtasks
                    if (typeof item.conditions !== 'undefined' && item.conditions != null && item.conditions) {
                        var conditions = item.conditions
                        var current_show = null
                        var current_order = -1
                        var cond_operator = typeof conditions.operator !== 'undefined' ? conditions.operator : 'AND'
                        for (var key in conditions.values) {
                            var show = false
                            let condition = conditions.values[key]
                            log(key, condition, list.items)
                            // console.log(list.items)
                            let value = list.items[key] ? list.items[key].value : false
                            item.is_visible = false

                            var operator = condition && condition.operator ? condition.operator : '='
                            var val = condition ? condition.value : false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(list.items[key].type)) && list.items[key] && list.items[key].is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    // Get task type from parent
                                    switch (list.items[key].type) {
                                        //   switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val), value, operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                        case FORMAT.TEMPERATURE:
                                            let aux = value.toString().replace(',', '.')
                                            show = compare(parseInt(aux), val, operator)
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            log(current_show, show, cond_operator)
                            if (current_show == null) current_show = show
                            else {
                                switch (cond_operator) {
                                    case 'AND':
                                        current_show = current_show && show
                                        break
                                    case 'OR':
                                        current_show = current_show || show
                                        break
                                }
                            }
                            if (show) {
                                current_order = list.items[key].order
                            }
                        }

                        item.is_visible = current_show
                        item.order = (parseInt(current_order) + 0.5).toString()
                    }

                    // Alerts TODO
                    if (item.alerts_triggers) {
                        let alerts = item.alerts_triggers
                        let value = item.value

                        let oAlert = {}
                        let message = ''
                        let preview = ''
                        let issues = []
                        let classes = []

                        for (var i in alerts) {
                            let alert = alerts[i]
                            let operator = alert.operator ? alert.operator : '='
                            let val = alert.value

                            let show = false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(item.type)) && item.is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val), value, operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                        case FORMAT.TEMPERATURE:
                                            let aux = value.toString().replace(',', '.')
                                            show = compare(parseInt(aux), val, operator)
                                            break
                                        default:
                                            break
                                    }
                                }
                            }

                            if (show && alert.msg) {
                                if (message == '') message = alert.msg
                                else message = message + '<br/>' + alert.msg

                                if (preview == '') preview = alert.msg
                                else preview = preview + ', ' + alert.msg
                            }

                            if (show && alert.tpl) {
                                issues.push(alert.tpl)
                            }
                            if (show && alert.class) {
                                if (alert.class == 'alert') {
                                    alert.class = 'warning'
                                }
                                classes.push(alert.class)
                            }
                        }

                        oAlert = {
                            preview: preview,
                            message: message,
                            issues: issues,
                            class: classes
                        }

                        if (oAlert.message.length > 0 || oAlert.issues.length > 0) {
                            item.alerts = oAlert
                            Vue.set(item, 'alerts', oAlert)
                        }
                    }

                    if (((!item.parent_id && list.def.item.itemsExcluded.indexOf(item.type) == -1) || (item.parent_id && item.is_visible)) && !item.planned_to_date) {
                        switch (item.type) {
                            case FORMAT.YESNO: // SI/NO
                            case FORMAT.YESNONC: // SI/NO/NSNC
                            case FORMAT.CHECK: // CHECKS
                            case FORMAT.TEXT: // TEXTO
                            case FORMAT.PHOTO: // FOTO
                            case FORMAT.NUMBER: // NUMBER
                            case FORMAT.DATE: // DATE
                            case FORMAT.TEXTAREA: // TEXTAREA
                            case FORMAT.MULTIPLE: // MULTIPLE SELECT
                            case FORMAT.TIME: // TIME
                            case FORMAT.DATETIME: // DATETIME
                            case FORMAT.SIGNATURE: // SIGNATURE
                            case FORMAT.TEMPERATURE: // TEMPERATURE
                            case FORMAT.EMAIL: // EMAIL
                            case FORMAT.DOCUMENT: // DOCUMENT
                                if (item.complete_date == false && item.apply) list.unanswered++
                                else {
                                    // Required to be reviewed
                                    if (item.require_review) {
                                        list.completed += item.review ? 1 : 0.5
                                    } else list.completed += 1 // Not required to be reviewed
                                }

                                break

                            default:
                                break
                        }
                        if (![FORMAT.LINK, FORMAT.RESOURCE].includes(item.type)) {
                            list.total++
                        }
                    }
                })

                list.score = Math.round((parseFloat(list.completed) * 100) / parseInt(list.total))

                //ORDER
                Object.keys(aItems).map(function(item_id, i) {
                    let list = JSON.parse(JSON.stringify(aItems[item_id]))
                    var items = Object.keys(list.items)
                        .sort((a, b) => {
                            return parseFloat(list.items[a].order) - parseFloat(list.items[b].order)
                        })
                        .reduce((prev, curr, i) => {
                            prev[i] = list.items[curr]
                            return prev
                        }, {})

                    let aux2 = {}
                    for (var k in items) {
                        aux2[items[k].item_id] = items[k]
                    }
                    // // // console.log(aux,aux2)

                    list.items = aux2
                    aItems[item_id] = list
                })

                aItems[list.id] = { ...list }
            }
        })

        commit('setScore', { items: aItems })
    },

    // Registers
    loadItems(context, params) {
        var cType = 3
        if (!params.noRefresh) {
            return api.post('checklist/resume/' + params.template, { number_pages: context.state.number_page, number_items: 10 }).then(function(response) {
                context.dispatch('format', { data: response.data.values })
                context.dispatch('calcScore')
                context.commit('setResume', { data: response.data.schema })
                context.commit('setNumberItems', response.data.number_items)
            })
        } else {
            context.dispatch('calcScore')
        }
    },
    loadItemsPagination(context, params) {
        var cType = 3

        return api.post('checklist/resume/' + params.id, params).then(function(response) {
            context.dispatch('format', { data: response.data.values })
            context.dispatch('calcScore')
            context.commit('setResume', { data: response.data.schema })
            context.commit('setNumberItems', response.data.number_items)
            context.commit('setNumberPage', params.number_pages)
        })
    },

    loadFromDate(context, params) {
        var cType = 3

        params.date = params.date ? params.date : moment().format('YYYY-MM-DD')
        return api.get('checklist/' + cType + '/' + params.date, {}).then(function(response) {
            return response
        })
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    // Commons
    setTemplates(state, payload) {
        var templates = {}

        if (payload.data != undefined) {
            Object.keys(payload.data).map(function(tpl_id, i) {
                var tpl = { ...payload.data[tpl_id] }

                tpl.last_update = tpl.last_update ? (moment.isMoment(tpl.last_update) ? tpl.last_update : moment(tpl.last_update * 1000)) : false

                templates[tpl.id] = tpl
            })
        }

        Vue.set(state, 'templates', { ...templates })
    },
    setAnswerProp(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var prop = payload.prop
        var value = payload.value

        state.items[check].items[answer][prop] = value

        Vue.set(state, 'items', { ...state.items })
    },
    setComment(state, payload) {
        // console.log("payload");
        // console.log(payload);
        let check = payload.check
        let answer = payload.answer
        let aux = state.items[check].items[answer]

        aux.message = payload.message
        aux.image = payload.image ? payload.image : false

        Vue.set(state.items[check].items, answer, { ...aux })
        // console.log(state.items);
        Vue.set(state, 'items', { ...state.items })
    },

    setCategories(state, payload) {
        var categories = state.categories
        categories = payload
        Vue.set(state, 'categories', { ...categories })
    },
    setAnswer(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date

        state.items[check].items[answer].value = value

        if (date) {
            var item = state.items[check].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) state.items[check].items[answer].employee = emp

        Vue.set(state, 'items', { ...state.items })
    },
    setApply(state, payload) {
        const check = payload.check
        const answer = payload.answer
        const emp = payload.emp
        const apply = payload.apply
        const date = payload.date

        state.items[check].items[answer].apply = apply

        if (date) {
            state.items[check].items[answer].complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        } else {
            state.items[check].items[answer].complete_date = false
        }

        if (emp) {
            state.items[check].items[answer].employee = emp
        }

        Vue.set(state, 'items', { ...state.items })
    },
    setReset(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].complete_date = false
        state.items[check].items[answer].employee = false
        state.items[check].items[answer].review = false
        state.items[check].items[answer].value = null

        Vue.set(state, 'items', { ...state.items })
    },
    setValidation(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date

        state.items[check].items[answer].review = {}
        if (date) {
            var item = state.items[check].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getEmployee'](emp)
            state.items[check].items[answer].review.employee = employee
        }
        state.items[check].items[answer].review.value = 1

        Vue.set(state, 'items', { ...state.items })
    },
    setUnvalidation(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].review = false

        Vue.set(state, 'items', { ...state.items })
    },

    resetStats(state, payload) {
        state.stats.alerts = {}
        state.stats.activity = {}
    },
    setStats(state, payload) {
        state.stats[payload.where] = {}
        state.stats[payload.where][payload.key_name] = payload.values
    },

    // Common but may change in other tools
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    setScore(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },

    // Registers
    setResume(state, payload) {
        state.headers = payload.data
        Vue.set(state, 'headers', { ...state.headers })
    },
    setNumberItems(state, payload) {
        state.number_items = payload
        Vue.set(state, 'number_items', state.number_items)
        db_templates.setItem('registers_items', payload)
    },
    setNumberPage(state, payload) {
        state.number_page = payload
        Vue.set(state, 'number_page', state.number_page)
        db_templates.setItem('registers_page', payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
