import Vue from 'vue'
import i18n from '@/i18n'
import { Api } from '@/core/api.js'
import localforage from 'localforage'
import router from '@/router'

const api = new Api()

const db_legal_conditions = localforage.createInstance({ name: 'alexdb', storeName: 'legal_conditions' })
const dbEmployeeLoginLogs = localforage.createInstance({ name: 'alexdb', storeName: 'employee_login_logs' })
const db_login = localforage.createInstance({ name: 'alexdb', storeName: 'login' })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        hasLoginUser: false,
        local: {
            token: null,
            location: {},
            user: {},
            account: {},
            employee_id: null
        }
    }
}

// initial state
const state = getDefaultState()

// load information from localForage
;(async () => {
    state.local.token = await db_login.getItem('token')
    state.local.location = await db_login.getItem('location')
    state.local.account = await db_login.getItem('account')
    state.local.user = await db_login.getItem('user')

    const employee_id = await db_login.getItem('employee_id')
    state.hasLoginUser = !!employee_id
    state.local.employee_id = employee_id
})()

// getters
const getters = {
    getLocalToken: (state, getters, rootState) => {
        return state.local.token
    },
    getLocalLocation: (state, getters, rootState) => {
        return state.local.location
    },
    getLocalAccount: (state, getters, rootState) => {
        return state.local.account
    },
    getLocalEmployee: (state, getters, rootState) => {
        return typeof state.local.employee_id !== 'undefined' ? state.local.employee_id : false
    },
    getUserInformation: (state, getters, rootState) => {
        return state.local.user
    }
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    getCountSync(context) {
        return new Promise((resolve, reject) => {
            var count = 0
            dbEmployeeLoginLogs
                .iterate(function(value, key, iterationNumber) {
                    count++
                })
                .then(function() {
                    resolve(count)
                })
        })
    },

    resetState({ commit }) {
        commit('resetState')
    },

    checkLogin(context, params) {
        return api.post('employee/login', { employee: params.employee, password: params.password }).then(function(response) {
            response.emp_id = params.employee
            // context.commit('login', response)
        })
    },

    doLogin(context, params) {
        params.mq = typeof params.mq !== 'undefined' ? params.mq : 0

        if (fnCheckConnection()) {
            return api
                .post('employee/login', { employee: params.employee, password: params.password, mq: params.mq })
                .then(function(response) {
                    if (response.status) {
                        response.emp_id = params.employee
                        response.token = typeof response.data.token !== 'undefined' ? response.data.token : response.data
                        response.user = context.rootState.employee.employees[params.employee]
                        context.commit('login', response)

                        const rol = context.rootGetters['employee/getRol'](context.state.local.user.rol)
                        if (rol.level == 8) {
                            debug = 1
                            localStorage.setItem('debug', 1)
                        }

                        // force to update templates and library
                        let refresh = context.rootGetters['getRefresh']
                        refresh.templates = true
                        refresh.library = true
                        context.commit('setUpdates', refresh, { root: true })

                        const tools = context.rootGetters['login/getConfig'].tools

                        if (tools.includes(1)) {
                            context.dispatch('checklist/loadTemplates', {}, { root: true })
                        }

                        if (tools.includes(2)) {
                            context.dispatch('issues/loadTemplates', {}, { root: true })
                        }

                        if (tools.includes(4)) {
                            context.dispatch('register/loadTemplates', {}, { root: true })
                        }

                        if (tools.includes(7)) {
                            context.dispatch('audit/loadTemplates', {}, { root: true })
                        }

                        if (tools.includes(1) || tools.includes(2) || tools.includes(4) || tools.includes(7)) {
                            context.dispatch('tasks/loadAll', { cType: 1, date: moment().unix() }, { root: true })
                        }

                        if (tools.includes(3)) {
                            context.dispatch('label/sendFailedPrintErrors', {}, { root: true })
                            context.dispatch('label/loadLabels', {}, { root: true })
                        }

                        context.dispatch('sendFailedLogins')

                        context.dispatch('login/loadTimeZonesAndLanguages', {}, { root: true })

                        context.commit('setMoment', {}, { root: true })

                        context.dispatch('login/calculateTrialDaysLeft', {}, { root: true })

                        // context.commit('employee/setConditionsVersion', { employee_id: params.employee, conditions_version: response.data.legal_version }, { root: true })
                        if (process.env.VUE_APP_IS_APP === 'TRUE') {
                            // console.log( "SETTING Employee INFO Analytics & Crashlytics" );
                            FirebasePlugin.setUserProperty('employee', response.emp_id)
                        }
                    } else {
                        context.commit('login', false)
                    }
                })
                .then(function() {
                    if (context.state.hasLoginUser) {
                        context.dispatch('showReleases')
                        return true
                    } else {
                        return false
                    }
                })
                .catch(err => {
                    logError(err)
                    return context.dispatch('loginOffline', params)
                })
        } else {
            return context.dispatch('loginOffline', params)
        }
    },

    loginOffline(context, params) {
        // Offline
        var employees = context.rootState.employee.employees
        var logged = false
        Object.keys(employees).forEach(function(key) {
            var e = employees[key]

            if (e.id == params.employee && e.password == params.password) {
                logged = e
            }
        })

        if (logged) {
            var employee = {
                id: context.state.local.location.id,
                account_id: context.state.local.account ? context.state.local.account : false,
                name: context.state.local.location.name,
                employee: {
                    id: params.employee,
                    name: logged.name,
                    avatar: logged.avatar
                },
                exp: moment()
                    .add(86400, 'minutes')
                    .unix()
            }

            var jwt = require('jwt-simple')
            var token = jwt.encode(employee, process.env.VUE_APP_JWT_KEY)
            var response = {
                status: true,
                token: token,
                emp_id: params.employee,
                user: context.rootState.employee.employees[params.employee]
            }

            const iCurrentDate = moment().format('YYYY-MM-DD HH:mm:ss')

            // save into local forage
            dbEmployeeLoginLogs.setItem(iCurrentDate, {
                employee_id: employee.employee.id,
                start_date: iCurrentDate,
                end_date: null,
                mq: params.mq
            })

            context.commit('login', response)

            if (process.env.VUE_APP_IS_APP === 'TRUE') {
                // console.log( "SETTING Employee INFO Analytics & Crashlytics" );
                FirebasePlugin.setUserProperty('employee', response.emp_id)
            }

            context.dispatch('login/calculateTrialDaysLeft', {}, { root: true })

            return true
        }

        return false
    },

    doLogout(context, params) {
        const self = this
        context.commit('setRefreshProducts', true, { root: true })
        context.commit('setRefreshLibrary', true, { root: true })
        if (fnCheckConnection()) {
            return api
                .post('employee/logout', { employee: params.employee })
                .then(function(response) {
                    context.commit('logout', response)
                    context.commit('printer/setIsValidatebPac', false, { root: true })
                })
                .then(function() {
                    // reset task date for login user
                    context.commit('tasks/setDate', false, { root: true })
                    if (!context.state.hasLoginUser) {
                        if (process.env.VUE_APP_IS_APP === 'TRUE') {
                            // console.log( "SETTING Employee logout INFO Analytics & Crashlytics" );
                            FirebasePlugin.setUserProperty('employee', null)
                        }
                        return true
                    } else {
                        return false
                    }
                })
                .catch(function(err) {
                    const loc = context.rootGetters['login/getLocation']
                    const account = context.rootGetters['login/getAccount']
                    var location = {
                        id: loc.id,
                        account_id: account ? account.id : false,
                        name: loc.name,
                        employee: false,
                        exp: moment()
                            .add(86400, 'minutes')
                            .unix()
                    }
                    log('TOKEN', location)
                    var jwt = require('jwt-simple')
                    var token = jwt.encode(location, process.env.VUE_APP_JWT_KEY)
                    return context.commit('logout', { data: token })
                })
        } else {
            dbEmployeeLoginLogs.iterate(function(value, key, iterationNumber) {
                if (value.employee_id == params.employee && !value.end_date) {
                    value.end_date = moment().format('YYYY-MM-DD HH:mm:ss')
                    dbEmployeeLoginLogs.setItem(key, value)
                }
            })
            const loc = context.rootGetters['login/getLocation']
            const account = context.rootGetters['login/getAccount']
            const location = {
                id: loc.id,
                account_id: account ? account.id : false,
                name: loc.name,
                employee: false,
                exp: moment()
                    .add(86400, 'minutes')
                    .unix()
            }
            const jwt = require('jwt-simple')
            const token = jwt.encode(location, process.env.VUE_APP_JWT_KEY)
            return context.commit('logout', { data: token })
        }
    },

    /**
     * This method is for updateing condition version (login)
     * @param {String} employee_id
     */
    async showPopupConditions({ dispatch, commit, getters, rootGetters }, params) {
        let language = rootGetters['getLanguage']
        let conditionsVersion = await rootGetters['login/getConditionsVersion']
        let popupBody = ''
        let acceptedInOfflineMode = false

        // iterate local database
        db_legal_conditions
            .iterate((legal_version, employee_id, iterationNumber) => {
                // check if user has accepted current legal conditions when he was offline
                if (legal_version == conditionsVersion) {
                    // if is user logged don't show popup
                    if (employee_id == params.employee_id) {
                        acceptedInOfflineMode = true
                    }

                    // if device has connection, update information
                    if (fnCheckConnection()) {
                        // update into server
                        api.post(`employee/${employee_id}/setlegalversion`, { legal_version: conditionsVersion }).then(response => {
                            commit('employee/setConditionsVersion', { employee_id: employee_id, conditions_version: conditionsVersion }, { root: true })
                        })

                        // remove element from db
                        db_legal_conditions.removeItem(employee_id).then(function() {
                            // sync offline legal conditions for other users
                            dispatch('syncLegalConditions')
                        })
                    }

                    // incorrect version, remove it
                } else {
                    db_legal_conditions.removeItem(employee_id).then(function() {
                        // sync offline legal conditions for other users
                        dispatch('syncLegalConditions')
                    })
                }
            })
            .then(function() {
                // check if user has accepted current legal conditions when he was offline
                if (!acceptedInOfflineMode) {
                    return api.get(`employee/conditions/${language}`).then(function(response) {
                        if (response.data) {
                            popupBody = response.data

                            Vue.prototype.$popup.confirm({
                                title: i18n.t('conditions.title'),
                                textSave: i18n.t('conditions.yes'),
                                textCancel: i18n.t('conditions.no'),
                                message: popupBody,
                                customClass: 'popup-small',
                                closable: false,
                                blocking: true,
                                callSave: function() {
                                    // update user legal conditions
                                    commit('employee/setConditionsVersion', { employee_id: params.employee_id, conditions_version: conditionsVersion }, { root: true })

                                    if (fnCheckConnection()) {
                                        return api.post(`employee/${params.employee_id}/setlegalversion`, { legal_version: conditionsVersion })
                                    } else {
                                        // save into local forge
                                        db_legal_conditions.setItem(params.employee_id, conditionsVersion)
                                    }
                                },

                                callCancel: function() {
                                    // close first popup
                                    Vue.prototype.$popup.close()

                                    // if user click cancel, show error popup
                                    Vue.prototype.$popup.alert({
                                        title: i18n.t('conditions.error.title'),
                                        message: i18n.t('conditions.error.message'),
                                        textSave: i18n.t('conditions.error.confirm'),
                                        closable: false,
                                        blocking: true,
                                        callSave: function() {
                                            dispatch('doLogout', { employee: params.employee_id })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
    },

    /**
     * View conditions from user page
     */
    viewConditions({ dispatch, commit, getters, rootGetters }, params) {
        let language = rootGetters['getLanguage']
        let popupBody = ''
        return api.get(`employee/conditions/${language}`).then(function(response) {
            if (response.data) {
                popupBody = response.data
                Vue.prototype.$popup.alert({
                    title: i18n.t('conditions.title2'),
                    message: popupBody,
                    textSave: i18n.t('conditions.yes2'),
                    customClass: 'popup-small',
                    closable: true,
                    blocking: false
                })
            }
        })
    },

    async syncLegalConditions({ dispatch, commit, getters, rootGetters }, params) {
        let conditionsVersion = await rootGetters['login/getConditionsVersion']

        // iterate local database
        db_legal_conditions.iterate((legal_version, employee_id, iterationNumber) => {
            // check if user has accepted current legal conditions when he was offline
            if (legal_version == conditionsVersion) {
                // if device has connection, update information
                if (fnCheckConnection()) {
                    // update into server
                    api.post(`employee/${employee_id}/setlegalversion`, { legal_version: conditionsVersion })

                    // remove element from db
                    db_legal_conditions.removeItem(employee_id)
                }

                // incorrect version, remove it
            } else {
                db_legal_conditions.removeItem(employee_id)
            }
        })
    },

    sendFailedLogins(context) {
        if (fnCheckConnection()) {
            const aLogins = []

            return dbEmployeeLoginLogs
                .iterate(function(value, key, iterationNumber) {
                    aLogins.push(value)
                })
                .then(function() {
                    if (aLogins.length == 0) {
                        return true
                    }

                    return api.post('employee/login/multiple', { data: JSON.stringify(aLogins) }).then(function(response) {
                        return dbEmployeeLoginLogs.clear().then(function() {
                            return true
                        })
                    })
                })
        }
    },

    showReleases(context) {
        const news = context.rootGetters['login/getReleases']()
        if (Object.values(news).length > 0) {
            const releasesViewed = localStorage.getItem('releases_viewed') ? JSON.parse(localStorage.getItem('releases_viewed')) : []
            const newReleases = []

            for (const release of news) {
                if (!releasesViewed.includes(release.id)) {
                    newReleases.push(release)
                }
            }

            if (newReleases.length > 0) {
                const release = newReleases[0]

                Vue.prototype.$popup.release({
                    title: release.title,
                    data: release.text,
                    image: release.image,
                    callSave: function() {
                        router.push({ name: 'Release', params: { selected: release.id } })
                        Vue.prototype.$popup.close()
                    },
                    callCancel: function() {
                        Vue.prototype.$popup.close()
                    }
                })

                releasesViewed.push(release.id)
                localStorage.setItem('releases_viewed', JSON.stringify(releasesViewed))
            }
        }
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    login(state, response) {
        log('entra logni', response)
        if (response.status) {
            state.hasLoginUser = true

            var local = state.local
            local.token = response.token
            local.employee_id = response.emp_id
            local.user = response.user
            state.local = local
            Vue.set(state, 'local', { ...local })

            db_login.setItem('token', response.token)
            db_login.setItem('employee_id', response.emp_id)
            db_login.setItem('user', response.user)
        } else {
            state.hasLoginUser = false
        }
    },

    logout(state, response) {
        state.local.token = response.data
        state.local.employee_id = false
        state.local.user = false
        db_login.removeItem('employee_id')
        db_login.removeItem('user')
        state.hasLoginUser = false
    },

    setLocation(state, response) {
        var local = { ...state.local }
        local.location = response

        state.local = local
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
